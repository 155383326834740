import React from 'react'
import {monthFromName, monthName, MonthYearArray} from '@peachy/utility-kit-pure'
import ListSelector from '../../../elements/ListSelector/ListSelector'
import Expander from '../../../elements/Expander/Expander'

export default function MonthSelect(props) {
    const {
        isShown = false,
        currentMonthYear,
        canNudgeMonth,
        onDismiss,
        onSelect,
        className,
        listClassName,

    } = props

    const [currentMonth, currentYear] = currentMonthYear
    const currentMonthName = monthName(currentMonth)
    const selectableMonths = []
    for (let i = 1; i <= 12; i++) {
        canNudgeMonth([i, currentYear]) && selectableMonths.push(monthName(i))
    }

    console.log('Month Selector!')
    console.log(props)

    return (
        <Expander className={className} expanded={isShown} tag={'aside'}>
            {selectableMonths.length &&
            <ListSelector className={listClassName} list={selectableMonths} onDismiss={onDismiss}
                          selection={currentMonthName} onSelect={(month) => {
                onSelect([monthFromName(month), currentYear])
                onDismiss()
            }
            }/>
            }

        </Expander>
    )
}

export type MonthSelectProps = {
    isShown: boolean
    currentMonthYear: MonthYearArray,
    canNudgeMonth: any,
    onDismiss: any,
    onSelect: any,
}
