import React from 'react'
import styles from './FaqPage.module.css'
import {Link} from 'react-router-dom'
import library from '../../global-style/library.module.css'

export default function StillCurious() {

    return (
        <section className={styles.stillCurious}>
            <h2>Still curious?</h2>
            <p>Get in touch with a member of our team.</p>
            <Link to={'/contact-us'} className={library.button}>Ask a question</Link>
        </section>
    )
}
