import styles from './PersonalPlans.module.css'
import Benefit from './Benefit/Benefit'


export default function PersonalPlans() {
    return (
        <section className={styles.PersonalPlans}>
            <h2>Personal plans, written for you</h2>
            <p>You're unique, and so is your insurance. We provide all you need to craft a plan that meets your needs, whether it's cover higher or lower than the average.</p>

            <h3>Choose at least one core benefit</h3>
            <Benefit className={styles.ConsultationDiagnostics} name={'Consultations & Diagnostics'}>
                <p style={{display:'inline-block'}}>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </p>
            </Benefit>
            <Benefit className={styles.HospitalCare} name={'Hospital Care'}>
                <p style={{display:'inline-block'}}>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat..
                </p>
            </Benefit>


            <h3>Enjoy essential care available to everyone on Peachy</h3>
            <Benefit className={styles.RemoteCare} name={'Remote Care'}>
                <p style={{display:'inline-block'}}>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat..
                </p>
            </Benefit>
            <Benefit className={styles.MentalHealth} name={'Mental Health'}>
                <p style={{display:'inline-block'}}>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </p>
            </Benefit>


            <h3>Tack on any extras for wider cover</h3>
            <Benefit className={styles.Therapies} name={'Therapies'}>
                <p style={{display:'inline-block'}}>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat..
                </p>
            </Benefit>
            <Benefit className={styles.DentalOptical} name={'Dental & Optical'}>
                <p style={{display:'inline-block'}}>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </p>
            </Benefit>

        </section>
    )
}
