import {defineValidator, MessageProducer, ValidateIf} from '../validate-core'
import {dateFromIso, isDayInFuture, isIsoDate} from '@peachy/utility-kit-pure'

export default function IsPastIsoDateString<T>(
    message?: string | MessageProducer<T>,
    validateIf?: ValidateIf<T>
) {
    return defineValidator<T>({
        name: 'IsPastIsoDateString',
        message:
            message ??
            (({property}) =>
                `Property ${property} must be a valid ISO date string in the past`),
        validateIf,
        validate({value}) {




            return isIsoDate(value) && !isDayInFuture(dateFromIso(value))
        },
    })
}
