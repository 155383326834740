import React from 'react'
import {
    compareDays,
    dateFromIso,
    formatIsoDateTimeOnly,
    friendlyFormat,
    NOOP,
    plusMonths,
    plusYears,
    today
} from '@peachy/utility-kit-pure'
import {useCurrentQuote, useQuoteService} from '../../QuoteController'
import {LifeTypes, Quote, toClass} from '@peachy/legacy-domain'
import {StyledDatePicker} from '../../Elements/DatePicker/StyledDatePicker'

import styles from './StartDate.module.css'
import TextBox from '../../Elements/TextBox/TextBox'
import StartDateModal from './StartDateModal'

const startDateWindowMonths = 1

function useValidStartDateRange() {
    const quote = toClass(useCurrentQuote().value, Quote)

    const minStartDate = today()

    const maxDates = [plusMonths(startDateWindowMonths, minStartDate)]

    quote.request.lives.forEach(life => {
        const birthdate = dateFromIso(life.birthDate)
        if (life.is(LifeTypes.DEPENDANT)) {
            maxDates.push(plusYears(26, birthdate))
        } else {
            maxDates.push(plusYears(56, birthdate))
        }
    })

    const maxStartDate = maxDates.sort((a, b) => compareDays(a, b))[0]

    return [minStartDate, maxStartDate]
}

export default function StartDate() {

    const quote = useCurrentQuote()
    const quoteService = useQuoteService()

    const [minDate, maxDate] = useValidStartDateRange()


    const startDate = dateFromIso(quote.request.startDate.value)

    const displayDate = friendlyFormat(startDate)

    const onSelect = (date: Date) => {
        console.log('Selected date ' + date)
        quote.request.startDate.set(formatIsoDateTimeOnly(date))
        quoteService.requestQuote()
    }

    return (
        <fieldset className={styles.StartDate}>
            <TextBox disabled={true} setValue={NOOP} value={displayDate}/>
            <StyledDatePicker header={'Choose your start date'} selectedDate={startDate} onSelect={onSelect} minDate={minDate} maxDate={maxDate}/>
            <StartDateModal/>
        </fieldset>
    )
}
