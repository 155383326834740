import React from 'react'
import styles from './HappyPeople.module.css'
import {classList} from '@peachy/utility-kit-pure'
import OneTwoColumnGrid from '../../../elements/layout/OneTwoColumnGrid/OneTwoColumnGrid'
import Photo from '../../shared/Photo/Photo'

export type HappyPeopleProps = {
    className?: string
}

export default function HappyPeople({className}: HappyPeopleProps) {

    const rootClasses = classList(styles.HappyPeople, className)

    return (
        <OneTwoColumnGrid className={rootClasses}>
            <article>
                <h3>Happy people</h3>
                <p>We're uncompromisingly inclusive, open to all voices, and prioritise the health and wellbeing of our people above all else.</p>
                <h3>Creative crusades</h3>
                <p>We're relentlessly creative, always seeking for the alternative solution, leveraging intuition, and tackling challenges with gusto.</p>
                <h3>Bold honesty</h3>
                <p>We've got a hearty appetite for sharing the truth, being utterly transparent, and upholding authenticity in everything we do.</p>
                <h3>Analytical approach</h3>
                <p>Every risk is calculated, every success anticipated, and all features we put forth are meticulously researched before any decisions are made.</p>
            </article>
            <Photo className={styles.photo} caption={'Oooga!'}/>
        </OneTwoColumnGrid>
    )
}
