import React from 'react'
import Faq from '../../../shared/FaqPanel/Faq'

export default function YourRights() {

    return (

        <Faq question={'Your rights'}>
            <p>
                Under Data Protection Laws, you have the following rights:
            </p>
            <h4>Access</h4>
            <p>
                The right to request access to your personal information.
                This will enable you to request details and receive a copy of the personal information we hold about you, and check that we are processing it lawfully.
            </p>
            <h4>Correction</h4>
            <p>
                The right to request correction or removal of inaccurate personal information.
            </p>
            <h4>Erasure</h4>
            <p>
                The right to request that we delete your personal information.
                This enables you to request us to delete or remove your personal information where there is no good reason for us to continue storing or processing it.
            </p>
            <h4>Restriction of processing</h4>
            <p>
                The right to request that we stop processing your personal information and only store it.
                You can do this if you want us to establish the accuracy of your personal information or our reason for processing it.
            </p>
            <h4>Data Portability</h4>
            <p>
                The right to request that we transfer your personal information to yourself, someone else or another company.
            </p>
            <h4>Object to legitimate interest</h4>
            <p>
                The right to object to the processing and profiling of your personal information.
            </p>
            <h4>Object to marketing</h4>
            <p>
                The right to object to direct marketing at any point.
                You can do this via our app, by speaking to a member of our team or by requesting to be added to a marketing suppression list.
            </p>
            <h4>Withdraw consent</h4>
            <p>
                The right to withdraw consent given to handle personal information.
                If you withdraw consent, this does not affect the lawfulness of how we stored or processed your personal information before consent withdrawal,
                and we will let you know if we are no longer able to provide you with your chosen product or service.
            </p>
            <h4>Automated decisions</h4>
            <p>
                The right to request that an automated decision is reviewed by a human.
            </p>
        </Faq>

    )
}
