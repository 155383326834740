import React from 'react'
import styles from './Policies.module.css'
import {Route, Routes, useLocation} from 'react-router'
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy'
import TermsOfUse from './TermsOfUse/TermsOfUse'
import CookiePolicy from './Cookies/CookiePolicy'
import PageHeading from '../shared/PageHeading/PageHeading'
import {NavLink} from 'react-router-dom'

export default function Policies() {

    return (
        <main className={styles.Policies}>
            <PageHeading>
                <h1>The legal stuff</h1>
                <p>Love a good document? Check out our jargon-free policies below</p>
            </PageHeading>
            <PolicySwitcher/>
            <Routes>
                <Route path={'/'} element={<PolicySwitcher/>}/>
                <Route path={'/privacy'} element={<PrivacyPolicy/>}/>
                <Route path={'/terms-of-use'} element={<TermsOfUse/>}/>
                <Route path={'/cookies'} element={<CookiePolicy/>}/>
            </Routes>
        </main>
    )
}



function PolicySwitcher() {


    const location = useLocation()


    const activeOrNot = ({isActive}) => isActive ? styles.current : undefined

    return (
        <nav className={styles.switcher}>
            <NavLink to={'terms-of-use'} className={activeOrNot}>Terms of use</NavLink>
            <NavLink to={'privacy'} className={activeOrNot}>Privacy Policy</NavLink>
            <NavLink to={'cookies'} className={activeOrNot}>Cookie Policy</NavLink>
        </nav>
    )
}
