import React from 'react'
import styles from './Photo.module.css'
import {classList} from '@peachy/utility-kit-pure'

export type PhotoProps = {
    caption?: string;
    className?: string
}

export default function Photo({caption = 'image', className}: PhotoProps) {

    const rootClasses = classList(styles.Photo, className)

    return (
        <figure className={rootClasses}>
            <figcaption>{caption}</figcaption>
        </figure>
    )
}
