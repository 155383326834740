import React from 'react'
import styles from './TheEnd.module.css'
import quoteStyles from '../../QuoteJourney.module.css'
import {RouteStageProps} from '../../StageRouter/StageRouter'
import {classList} from '@peachy/utility-kit-pure'
import {useScope} from '../../../elements/Scope'
import ReactDOM from 'react-dom'
import PeachyLogo from '../../../Logo/PeachyLogo'
import library from '../../../global-style/library.module.css'
import {useIamService, useIamStage} from '../../IamController'
import {useCurrentQuote} from '../../QuoteController'
import {QuoteRequest, toClass} from '@peachy/legacy-domain'
import {QuoteStageName} from '@peachy/quote-client'
import {ROOT_DOMAIN} from '../../../App/App'


export type TheEndProps = RouteStageProps<QuoteStageName> & {
}

export default function TheEnd({}: TheEndProps) {

    const rootClasses = classList(quoteStyles.QuoteJourney, styles.TheEnd)

    const quote = useCurrentQuote()
    const quoteRequest = toClass(quote.request.value, QuoteRequest)


    const isFamilyPolicy = quote.request.lives.length > 1

    const iamService = useIamService()
    const iamStage = useIamStage().stage.value

    const resendCode = () => {
        iamService.requestEmailConfirmationCode(quoteRequest.getPrimaryLife().email)
    }

    const backButtonClasses = classList(library.button, library.buttonFrame, styles.homeButton)

    return (<>
        <QuoteHeaderNav onX={`https://www.${ROOT_DOMAIN}`} />
        <main className={rootClasses}>
            <article>
                <p>It's official!</p>
                <h1>Welcome to peachy</h1>
                <p className={styles.instructions}>
                    We’ve sent you an email with your plan documents and instructions on what to do next.
                    Once you’ve downloaded our app you can manage everything to do with your Peachy plan.
                </p>
                {isFamilyPolicy && <p>Your family members have also been sent an email too so give them a heads up</p>}
                <p className={styles.remail}>
                    <span>Didn't get an email?<button onClick={resendCode}>Send it again →</button></span>
                    <span>(Remember to check your junk folder!)</span>

                </p>

            </article>

            <a className={backButtonClasses} href={`https://www.${ROOT_DOMAIN}`}>Back to home</a>
        </main>
    </>)
}

//
// function QuoteHeaderNav({onX}) {
//
//     const quoteNavPortal = useScope<React.MutableRefObject<HTMLElement>>()
//
//     return quoteNavPortal.current ? ReactDOM.createPortal(<>
//         <PeachyLogo/>
//         {onX &&
//
//         <a className={classList(quoteStyles.exitButton, styles.exitButton)} href={onX}>
//             <i className="fal fa-times"/>
//         </a>
//         // <button className={classList(quoteStyles.exitButton, styles.exitButton)} onClick={onX}>
//         //     <i className="fal fa-times"/>
//         // </button>
//         }
//     </>, quoteNavPortal.current) : null
// }

function QuoteHeaderNav({onX}) {

    const quoteNavPortal = useScope<HTMLElement>()

    return quoteNavPortal ? ReactDOM.createPortal(<>
        <PeachyLogo/>
        {onX &&

        <a className={styles.exitButton} href={onX}>
            <i className="fal fa-times"/>
        </a>

            // <button className={styles.exitButton} onClick={onX}>
            //     <i className="fal fa-times"/>
            // </button>
        }
    </>, quoteNavPortal) : null
}
