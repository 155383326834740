import React from 'react'
import styles from './ResultMap.module.css'
import GoogleMap from '../../../../Map/GoogleMap'
import MapOverlay from '../../../../Map/MapOverlay'
import {Vector} from '../../../../Map/MapKit'
import Markers from './Markers/Markers'
import useEnsureGoogleMaps from '../../../../App/useEnsureGoogleMaps'
import {useCareSearchQuery, useCareSearchResults} from '../../../../service-stub/CareSearchController'
import {PEACHY_HQ} from '../../../ContactUs/OurOffice/OurOffice'


const offset: Vector = {
    x: 0.2,
    y: 0
}

export default function ResultMap() {

    const mapsApiState = useEnsureGoogleMaps()

    const [query, setQuery] = useCareSearchQuery()
    const searchResults = useCareSearchResults()

    return mapsApiState !== 'loaded' ? null : (
        <GoogleMap zoom={13} range={query.rangeInMeters} offset={offset} center={query.locus ?? PEACHY_HQ} className={styles.ResultMap}>
            <Markers searchResults={searchResults}/>
            {query.locus
                ? <Range radiusInMeters={query.rangeInMeters} center={query.locus}/>
                : null
            }
        </GoogleMap>
    )
}



function Range({radiusInMeters, center}) {
    return (
        <MapOverlay location={center} size={2 * radiusInMeters} anchorPoint={{x: 0.5, y: 0.5}}>
            <svg className={styles.range} height={'100%'} width={'100%'}>
                <circle cx={'50%'} cy={'50%'} r={'50%'}/>
            </svg>
        </MapOverlay>
    )
}

