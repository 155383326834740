import styles from './PlentyOfPeach.module.css'
import useScrollAwareRef from '../../../hooks/useScrollAwareRef'


export default function PlentyOfPeach() {

    const ref = useScrollAwareRef<HTMLUListElement>('deg')

    return (
        <section className={styles.PlentyOfPeach}>
            <h2>Plenty of peach to go around</h2>
            <p>Each teammate will get full access to the Peachy app to get care and make fast claims on the go.</p>
            <ul ref={ref} className={styles.Points}>
                <li>
                    <h4>Breezy Claims</h4>
                    <p>No complex questionnaires. Make a claim as fast as you can order a cab—in just a few taps.</p>
                </li>
                <li>
                    <h4>Power to Choose</h4>
                    <p>Go with the health expert you're most comfortable with, and we'll pay for all or most of it.</p>
                </li>
                <li>
                    <h4>Get seen remotely</h4>
                    <p>Book a remote visit with your GP for answers and prescriptions, 24/7.</p>
                </li>
            </ul>
        </section>
    )
}

