import styles from './GetInsuredInMinutes.module.css'
import useScrollAwareRef from '../../../hooks/useScrollAwareRef'

export default function GetInsuredInMinutes() {

    const stepsRef = useScrollAwareRef<HTMLOListElement>('deg')

    return (
        <section className={styles.GetInsured}>
            <article>
                <h2>Get insured in minutes</h2>
                <p>
                    Peachy is quick to set up and fast to ditch if you
                    change your mind. We don't lock anyone in, you have
                    the power to chose when you're covered.
                </p>
            </article>
            <ol ref={stepsRef} className={styles.Steps}>
                <li>Get your very own Peachy account</li>
                <li>Craft the perfect personal plan</li>
                <li>Manage everything through our app</li>
            </ol>
        </section>
    )
}

