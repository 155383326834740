import React, {useLayoutEffect, useState} from 'react'
import TextBox from '../../Elements/TextBox/TextBox'
import CaptureForm, {CaptureFieldsProps, CaptureFormProps} from '../CaptureForm'
import Toggle from '../../../elements/Toggle/Toggle'
import styles from './CaptureEmail.module.css'
import {Life} from '@peachy/legacy-domain'
import {ROOT_DOMAIN} from '../../../App/App'

export default function CaptureEmail(props: CaptureFieldsProps<Life>) {
    const {instance, visitedFields} = props

    const [showOptIns, setShowOptIns] = useState(false)


    const captureEmailProps: CaptureFormProps<Life> = {
        ...props,
        buttonId: 'capture-email',
        headers: <>
            <h4>Super!</h4>
            <h4>Let's get your quote</h4>
        </>,
        captureFields:['email', 'marketingAccepted', 'policiesAccepted']
    }


    useLayoutEffect(() => {
        setShowOptIns(true)

    }, [])



    return (
        <CaptureForm  {...captureEmailProps}>
            <fieldset>
                <TextBox  autoFocus email placeholder={'Email address'} value={instance.email.value} setValue={instance.email.set} onBlur={() => visitedFields.toggleOn('email')}/>
            </fieldset>
            <fieldset className={styles.optIns}>
                <label>
                    <span>Include me in updates & offers by email</span>
                    <Toggle className={styles.toggle} state={instance.marketingAccepted.value} setState={instance.marketingAccepted.set}/>
                </label>
                <label>
                    <span>I agree to Peachy's <a target={'_blank'} href={`https://www.${ROOT_DOMAIN}/privacy-policy`}>Privacy Policy</a> & <a target={'_blank'} href={`https://www.${ROOT_DOMAIN}/legal`}>Terms of Use</a></span>
                    <Toggle className={styles.toggle}  state={instance.policiesAccepted.value} setState={instance.policiesAccepted.set}/>
                </label>
            </fieldset>
        </CaptureForm>
    )
}
