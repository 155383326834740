import React from 'react'
import styles from './BenefitCard.module.css'
import BenefitCard from './BenefitCard'
import {HospitalCareBenefit} from '@peachy/legacy-domain'


const benefitTemplate = HospitalCareBenefit()

export default function HospitalCard() {

    return (
        <BenefitCard header={'Hospital Care'} className={styles.hospital} benefitTemplate={benefitTemplate}>
            <p>Get treatment fast once you know what’s wrong.</p>
            <p>Cover up to your chosen benefit limit for:</p>
            <ul>
                <li>Treatment as an in-patient, day-patient or out-patient in a hospital including operations</li>
                <li>Consultations &amp; diagnostic tests for up to 6 months after discharge if related to your treatment</li>
                <li>Cancer treatment, follow-up consultations &amp; diagnostic tests – even if the cancer comes back</li>
            </ul>
        </BenefitCard>
    )
}
