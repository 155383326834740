import {MrSubscription} from '../domain/models/memorepo/MrSubscription'
import {MrAccount} from '../domain/models/memorepo/MrAccount'

export type SubscriptionDto = MrSubscription

export type AccountDto = Omit<MrAccount, 'subscriptions'>

export class AccountSubscriptionDto {
    public account: AccountDto
    public subscription: SubscriptionDto
}
