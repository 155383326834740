import React from 'react'
import FaqPanel from '../shared/FaqPanel/FaqPanel'
import Faq from '../shared/FaqPanel/Faq'

export default function VirtualGpFaqs() {
    return (
        <FaqPanel heading={'Virtual GP'}>
            <Faq question={'How can I book a Virtual GP appointment?'}>
                <p>
                    Once you join Peachy, you'll have access to our Virtual GP service, which includes a 24/7 private GP
                    Helpline, video consultations, private prescriptions, health information and onward specialist
                    referral. You can book a virtual GP from inside the Peachy app.
                </p>
            </Faq>
            <Faq question={'Can I choose to speak to a male or female doctor?'}>
                <p>
                    Yes, where possible we will always try to accommodate your preference to speak to a male or female
                    doctor
                </p>
            </Faq>
            <Faq question={'What sort of things can I ask about?'}>
                <p>
                    Anything you would normally ask a GP about, for example, travel vaccinations, sensitive or
                    confidential concerns, explanations of diagnosis or treatment, aches and pains or any other
                    health-related query.
                </p>
            </Faq>
            <Faq question={'Will I still need to see my GP?'}>
                <p>
                    Most patients receive the advice, reassurance and, where appropriate, diagnosis required. The
                    doctors can assist with most health concerns, but if symptoms require a physical examination, or
                    need a repeat prescription, you may be referred to your own GP.
                </p>
            </Faq>
            <Faq question={'Is there a limit to the number of consultations?'}>
                <p>
                    No, there is no limit to phone or video consultations.
                </p>
            </Faq>
            <Faq question={'Can the GP\'s issue a prescription?'}>
                <p>
                    Yes, GPs can arrange and electronically authorise private prescriptions where the GP believes it is
                    clinically appropriate. The online pharmacy will then contact you to arrange delivery of the
                    medication to a nominated UK address. The pharmacy will take payment for medication and delivery via
                    credit or debit card. This charge is not covered by your plan.
                </p>
            </Faq>
            <Faq question={'Can the GP\'s issue repeat prescription?'}>
                <p>
                    GPs are unable to issue long-term, repeat medication – in line with clinical best practice
                    guidelines for remote prescribing . Should a patient require repeat prescription medication, it is
                    in their best interests to see their regular GP who can provide the ongoing monitoring, medical
                    record and follow up care such prescriptions require. GPs can offer advice on medication being taken
                    and may be able to offer short course medication depending on the symptoms presented.
                </p>
            </Faq>
            <Faq question={'Can the GP\'s issue fit notes?'}>
                <p>
                    Yes. Should the GP feel it is required, they can issue you with a private fit note (statements of
                    fitness for work). You should check that your employer will accept a private fit note. Private fit
                    notes are not acceptable for statutory sick pay.
                </p>
            </Faq>
            <Faq question={'Can the GP\'s issue referrals to specialists?'}>
                <p>
                    Should the GP feel that you would benefit from a specialist assessment or further treatment they can
                    provide an open private referral letter. This referral is for private only and will not be accepted
                    for services in the NHS. Referral letters will be emailed to you.
                    You will still need to check cover in the Peachy app before you get the treatment that you've been
                    referred for.
                </p>
            </Faq>
            <Faq question={'Can my children have consultations and prescriptions?'}>
                <p>
                    Should the GP feel that you would benefit from a specialist assessment or further treatment they can
                    provide an open private referral letter. This referral is for private only and will not be accepted
                    for services in the NHS. Referral letters will be emailed to you.
                    You will still need to check cover in the Peachy app before you get the treatment that you've been
                    referred for.
                </p>
            </Faq>
        </FaqPanel>
    )
}
