import {Properties} from '@peachy/utility-kit-pure'
import {AnEvent} from '../../../event-kit'
import {CancellationEventData, SubscriptionEventData} from '../../interfaces'
import {AccountType} from '@peachy/repo-domain'
import {CancellationType} from '../../../../domain/models/CancellationType'

export class CompanyCancellationEmailSendRequest extends AnEvent implements SubscriptionEventData, CancellationEventData {
    constructor(from: Properties<CompanyCancellationEmailSendRequest>) {
        super()
        Object.assign(this, from)
    }

    accountId: string
    accountType: AccountType
    cancellationReason: string
    cancellationType: CancellationType
    subscriptionId: string
}
