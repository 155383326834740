import WholeCrew from './WholeCrew/WholeCrew'
import PlentyOfPeach from './PlentyOfPeach/PlentyOfPeach'
import PlansGrow from './PlansGrow/PlansGrow'
import TransparentPricing from './TransparentPricing/TransparentPricing'

import styles from './TeamHealth.module.css'
import PageHeading from '../shared/PageHeading/PageHeading'
import JustGotPeachy from './JustGotPeachy/JustGotPeachy'

export default function TeamHealth() {

    return (
        <main className={styles.TeamHealth}>

            <PageHeading className={styles.betterHealth}>
                <h1>Better health insurance for your business</h1>
                <p>Peachy is perfect for small teams, providing easy-access healthcare for employees across the UK.</p>
            </PageHeading>

            <WholeCrew/>
            <PlentyOfPeach/>
            <PlansGrow/>
            <TransparentPricing/>
            <JustGotPeachy/>
        </main>
    )

}
