import {GeoLocation} from '@peachy/core-domain-pure'

export type AddressMatch = {
    id: string
    address: string,
}


export class FetchAddressRequest {
    addressId: string
}


export class LookupAddressRequest {
    searchTerm: string
    residentialOnly?: boolean
    location?: GeoLocation
    requestId?: string
}

export class LookupAddressResponse {
    matches: AddressMatch[]
    requestId?: string
}
