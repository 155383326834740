import {AnEvent} from '../event-kit'
import {KeyMapped, Properties} from '@peachy/utility-kit-pure'
import {Policy} from '../../domain/models/Policy'
import {Plan} from '../../domain/models/Plan'
import {Life} from '../../domain/models/Life'
import {AccountType} from '../../domain/models/Account'

export class PolicyReposRequest extends AnEvent {

    public policy: Policy
    public plans: KeyMapped<Plan>
    public appCustomers: Life[]
    public accountType: AccountType
    public subscriptionStartDate: number

    constructor(from: Properties<PolicyReposRequest>) {
        super()
        Object.assign(this, from)
    }
}
