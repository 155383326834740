import {Props} from '@peachy/utility-kit-pure'
import {SanctionsCheckEvent} from './SanctionsCheckEvent'

export class SanctionsCheckPassed extends SanctionsCheckEvent {

    constructor(from: Props<SanctionsCheckPassed>) {
        super(from)
        this.status = 'PASSED'
        this.type = this.constructor.name
    }
}