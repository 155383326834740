import React from 'react'
import styles from './AppliedFilters.module.css'
import {classList} from '@peachy/utility-kit-pure'
import Expander from '../../../../../elements/Expander/Expander'
import {CareSearchQuery} from '../../../../../service-stub/care-search-domain'
import {useCareSearchQuery} from '../../../../../service-stub/CareSearchController'

export type AppliedFiltersProps = {
    className?: string,
}

export default function AppliedFilters({className}: AppliedFiltersProps) {

    const rootClasses = classList(styles.AppliedFilters, className)

    const [query] = useCareSearchQuery()

    const expanded = hasFilters(query)

    return (
        <Expander className={rootClasses} expanded={expanded} tag={'ul'}>
            {query.genderPreferences.includes('MALE') && <li className={styles.Male}>Male</li>}
            {query.genderPreferences.includes('FEMALE') && <li className={styles.Female}>Female</li>}
            {query.appointmentTypes.includes('VIRTUAL') && <li className={styles.VirtualAppointment}>Virtual</li>}
            {query.appointmentTypes.includes('IN_PERSON') && <li className={styles.F2FAppointment}>Face-to-face</li>}
            {query.costRanges.includes('LOW') && <li className={styles.labelled}>£</li>}
            {query.costRanges.includes('MEDIUM') && <li className={classList(styles.labelled, styles.wide)}>££</li>}
            {query.costRanges.includes('HIGH') && <li className={classList(styles.labelled, styles.wide)}>£££</li>}
            {query.paediatric && <li className={styles.Pediatric}>Paediatric</li>}
            {/*<li className={classList(styles.labelled, styles.wide)}>{filterState.value.distance} km</li>*/}
        </Expander>
    )
}


function hasFilters(query: CareSearchQuery) {
    return !!(query.appointmentTypes?.length ||
        query.genderPreferences?.length ||
        query.paediatric ||
        query.costRanges?.length)
}
function la() {



    // {specialities.length ?
    //     <aside className={styles.specialities}>
    //         <h5>Clinical specialities</h5>
    //         <ul>
    //             {specialities.map((s, i) =>
    //                 <Speciality key={i} name={s} small={mode !== 'full'}/>
    //             )}
    //         </ul>
    //     </aside> : null
    // }
}
