import styles from './Contact.module.css'
import {ContactInfo} from '../ProfilePanel'
import {Email, Phone} from '../Location/ProfileLocation'

export default function Contact({name, emails, phoneNumbers}: ContactInfo) {

    const emailItems = emails.map(e => <Email email={e}/>)
    const phoneItems = phoneNumbers.map(p => <Phone phone={p}/>)

    return (
        <div className={styles.contact}>
            {name &&
            <p className={styles.contactNameNumber}>
                <span className={styles.contactName}>{name}</span>
            </p>
            }
            <p className={styles.contactEmail}>
                {emailItems}
                {phoneItems}
            </p>
        </div>
    )
}

