import 'reflect-metadata'
import {Type} from 'class-transformer'
import {Life, LifeTypes} from './people'
import {BenefitType, Plan} from './plans'
import ValidateNested from './validate/validators/ValidateNested'
import IsUUID from './validate/validators/IsUUID'
import IsIsoDateString from './validate/validators/IsIsoDateString'
import IsNotEmpty from './validate/validators/IsNotEmpty'
import Required from './validate/validators/Required'
import Optional from './validate/validators/Optional'
import IsFutureIsoDateString from './validate/validators/IsFutureIsoDateString'
import ContainsOne from './validate/validators/ContainsOne'

export class Quote {
    @ValidateNested()
    @Type(() => QuoteRequest)
    request: QuoteRequest = new QuoteRequest()

    @ValidateNested()
    @Type(() => QuoteResponse)
    response: QuoteResponse = undefined
}

export class Promo {
    code: string
    blurb: string
    captureCardBlurb: string
    emailRestrictions: string[]
    percentDiscount?: number
    fixedDiscountInPence?: number
    reflectOnQuotedPrice: boolean
    terms?: string
}

export class QuoteRequest {
    @IsUUID()
    id: string = undefined

    @IsIsoDateString()
    timestamp: string = undefined

    version: number = 1

    @IsFutureIsoDateString()
    startDate: string = undefined

    promo?: Promo

    @ValidateNested()
    @ContainsOne<Life>(life => life.type === LifeTypes.PRIMARY)
    @Type(() => Life)
    lives: Life[] = []

    @ValidateNested()
    @IsNotEmpty()
    @Type(() => Plan)
    plans: Plan[] = []

    distributorId?: string

    findLife?(id: string): Life {
        return this.lives.find((l) => l.id === id)
    }

    findPlan?(id: string): Plan {
        return this.plans.find((p) => p.id === id)
    }

    findLifeIndex?(id: string): number {
        return this.lives.findIndex((l) => l.id === id)
    }

    findPlanIndex?(id: string): number {
        return this.plans.findIndex((p) => p.id === id)
    }

    hasLife(id: string): boolean {
        return this.lives.some(l => l.id === id)
    }

    getPrimaryLife() {
        return this.lives?.find((l) => l.is(LifeTypes.PRIMARY))
    }

    getNonPrimaryLives() {
        return this.lives?.filter(l => !l.is(LifeTypes.PRIMARY))
    }

    getSecondaryLife() {
        return this.lives?.find((l) => l.is(LifeTypes.SECONDARY))
    }

    getDependents() {
        return this.lives?.filter((l) => l.is(LifeTypes.DEPENDANT))
    }

    getDefaultPlan() {
        return this.plans?.[0]
    }

}

export class QuoteResponse {
    @IsUUID()
    quoteRequestId: string = undefined

    @IsUUID()
    quoteResponseId: string = undefined

    @IsIsoDateString()
    timestamp: string = undefined

    @Required()
    totalPolicyPrice: number = undefined

    @ValidateNested()
    @IsNotEmpty()
    @Type(() => LifeQuoteResponse)
    lives: LifeQuoteResponse[] = []
}

export class LifeQuoteResponse {
    @IsUUID()
    lifeId: string = undefined

    @IsUUID()
    planId: string = undefined

    @Required()
    totalPlanPrice: number = undefined

    @ValidateNested()
    @IsNotEmpty()
    @Type(() => BenefitPrice)
    benefitPrices: BenefitPrice[]

    benefitPriceBreakdown: BenefitPriceBreakdown
}

export class BenefitPrice {
    @IsNotEmpty()
    benefitName: string = undefined

    benefitPrice: number = undefined

    @Optional()
    @Type(() => BenefitPriceBreakdown)
    benefitPriceBreakdown?: BenefitPriceBreakdown = undefined
}

export class BenefitPriceBreakdown {
    baseRiskCost: ComponentBreakdown = undefined
    adjustedForCorrelation: ComponentBreakdown = undefined
    adjustedForContingency: ComponentBreakdown = undefined
    adjustedForLossRatio: ComponentBreakdown = undefined
    adjustedForIpt: ComponentBreakdown = undefined
    adjustedForPriceResolution: ComponentBreakdown = undefined
}


export type ComponentBreakdown = {
    [_ in BenefitType]?: number
}

