
export function useCookies() {
    return {
        set(name: string, value: string, options?: {ttlDays: number, path?: string, domain?: string}) {
            const expires = options.ttlDays ? new Date (Date.now() + (options.ttlDays*24*60*60*1000)) : undefined
            const expiresString = expires ? `expires=${expires};` : ''
            const domainString = options.domain ? `domain=${options.domain};` : ''
            document.cookie = `${name}=${value}; ${expiresString} ${domainString} path=${options.path ?? '/'};`
        },
        get(name: string) {
            const key = `${name}=`
            const decodedCookie = decodeURIComponent(document.cookie)
            const cookies = decodedCookie.split(';')

            return cookies.reduce((found, it) => {
                return found ??= (it.trimStart().startsWith(key)) ? it.trimStart().substring(key.length, it.length) : undefined
            }, undefined)
        }
    }
}

