import React, {ReactNode, useContext, useState} from 'react'
import {API} from '@aws-amplify/api'
import {makeApiGatewayClient} from '@peachy/core-domain-client'

import {SubscriptionApiGatewayDefinition} from '@peachy/subscription-pure'
import {Auth} from '@aws-amplify/auth'
import useAsyncEffect from 'use-async-effect'
import type {SubscriptionService} from '../service/individual-services/SubscriptionService'
import {createSubscriptionService} from '../service/individual-services/SubscriptionService'


const SubscriptionContext = React.createContext<SubscriptionService>(null)


type SubscriptionControllerProps = {
    children: ReactNode
}

export default function SubscriptionController({children}: SubscriptionControllerProps) {

    // todo clean up interfaces!
    const [subscriptionService, setSubscriptionService] = useState<SubscriptionService>()

    useAsyncEffect(async () => {
        const subscriptionService = await createSubscriptionService(await makeApiGatewayClient(SubscriptionApiGatewayDefinition, API, Auth))
        setSubscriptionService(subscriptionService)
    }, [])

    return <>
        {subscriptionService &&
            <SubscriptionContext.Provider value={subscriptionService}>
                {children}
            </SubscriptionContext.Provider>
        }
    </>

}
export function useSubscriptionService() {
    return useContext(SubscriptionContext)
}
