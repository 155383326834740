import Required from './validate/validators/Required'
import {Type} from 'class-transformer'
import {Address} from './location/Address'

export class CardDetails {
    @Required()
    name: string = undefined

    gatewayCustomer: {
        email: string
        fullName: string
    }
    gatewayPaymentId: string

    hasSeparateBillingAddress: boolean = false

    promoCode?: string

    @Type(() => Address)
    @Required<CardDetails>('Please enter a valid UK address', card => card.instance.hasSeparateBillingAddress)
    billingAddress: Address = undefined
}
