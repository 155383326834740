import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React from 'react'
import {classList} from '@peachy/utility-kit-pure'

export default function SocialLinks({className}) {
    const classes = classList( className)

    return (
        <nav className={classes}>
            <a href={'https://www.facebook.com/thepeachyhq'}><FontAwesomeIcon icon={['fab', 'facebook-f']}/></a>
            <a><FontAwesomeIcon icon={['fab', 'twitter']}/></a>
            <a href={'https://www.linkedin.com/company/thepeachyhq'}><FontAwesomeIcon icon={['fab', 'linkedin']}/></a>
            <a><FontAwesomeIcon icon={['fab', 'instagram']}/></a>
        </nav>
    )

}
