import React from 'react'
import {Link} from 'react-router-dom'
import FaqPanel from '../shared/FaqPanel/FaqPanel'
import Faq from '../shared/FaqPanel/Faq'

export default function GettingCareFaqs() {
    return (
        <FaqPanel heading={'Getting care'}>
            <Faq question={'Do I need to check cover before getting care?'}>
                <p>
                    Yes. Checking your cover before going for treatment gives you and us peace of mind that your
                    treatment is covered. If you don't do this, you open the possibility of paying for treatment that
                    may not be covered and therefore cannot be claimed under your plan. Checking cover is simple! Go to
                    Check Cover in the Peachy app and chat to our smart concierge. Answer a few questions and we'll let
                    you know if your covered or not.
                </p>
            </Faq>
            <Faq question={'Do I need a referral to get care?'}>
                <p>
                    Yes, but you can self-refer for virtual GP appointments (unlimited) and physiotherapy, chiropractic,
                    osteopathy and acupuncture (up to four sessions per year). We won't routinely ask to see the
                    referral however, we may ask for it when you make a claim.
                </p>
            </Faq>
            <Faq question={'Can I see any specialist or healthcare professional?'}>
                <p>
                    Yes, as long as they are fully qualified and hold a current license to practice with the relevant
                    professional body.
                </p>
                <p>
                    We made getting care easier. You can search over 15,000 private specialists and
                    healthcare professionals right inside the Peachy app to get care near you.
                </p>

                <ul>
                    <li>
                        Specialists / Consultants – registered with the <Link to={'#'}>General Medical Council</Link> on the Specialist Register
                        and certified as a specialist by the appropriate college or regulatory specialist body.
                    </li>
                    <li>
                        Physiotherapists, Podiatrists, Dietitians – registered with the <Link to={'#'}>Health & Care Professions Council</Link>
                    </li>
                    <li>
                        Osteopaths – registered with the <Link to={'#'}>General Osteopathic Council</Link>
                    </li>
                    <li>
                        Chiropractors – registered with the <Link to={'#'}>General Chiropractic Council</Link>
                    </li>
                    <li>
                        Acupuncturists – registered Member of the <Link to={'#'}>British Acupuncture Council</Link>
                    </li>
                    <li>
                        Dentists – full registration with the <Link to={'#'}>General Dental Council</Link>
                    </li>
                    <li>
                        Opticians – full registration with the <Link to={'#'}>General Optical Council</Link>
                    </li>
                </ul>
            </Faq>
            <Faq question={'What hospitals can I use?'}>
                <p>
                    We believe access to the best hospital for diagnosis or treatment of your condition should be a
                    clinical choice, not a commercial decision driven by a health insurer. With Peachy, you can access
                    any private hospital across the UK. It's your health, so you should be able to choose the best
                    hospital for you.
                </p>
            </Faq>
            <Faq question={'How do I file a claim?'}>
                <p>
                    It's breezy! Just open the Peachy app and hit the Claim button. There you'll need to record a video
                    selfie explaining what happened and snap a picture of your receipt. If you need hospital care just
                    let us know, we can pay you as soon as you've been treated, so you can take care of the bill.
                </p>
            </Faq>
            <Faq question={'Why does Peachy ask me to record a video during the claims process?'}>
                <p>
                    There's no better way for us to understand what your claiming for than by having you tell us in your
                    own words. It only takes a minute to shoot the video.
                </p>
            </Faq>
            <Faq question={'How will I get paid for a claim?'}>
                <p>
                    After you file a claim on the Peachy app, you will be asked to enter your bank account information.
                    Once your claim is approved, we'll issue payment, directly into your account.
                </p>
            </Faq>
            <Faq question={'How fast will claims be paid?'}>
                <p>
                    We aim to pay the majority of simple claims almost instantly. There will be instances in which we'll
                    need to fully review the claim before we can approve it. For example, if some of the information we
                    require is missing when you file a claim, our team will need extra time to manually assess the
                    incident and will most likely get in touch with you for further review.
                </p>
            </Faq>
            <Faq question={'Do I have to pay an excess?'}>
                <p>
                    No, we don't want to discourage you from making a claim when you need care, so we've done away with
                    excesses! The only time you need to pay towards your care is if you've gone over your annual limit.
                </p>
            </Faq>
            <Faq question={'Do you have a no claims discount?'}>
                <p>
                    No. We don't want to discourage our customers from using their plan. But don't worry, your premiums
                    reflect the claims made with a wider pool of customers and we don't put up the cost of your plan
                    just because you've made a claim.
                </p>
            </Faq>
            <Faq question={'Do my premiums increase if I make a claim?'}>
                <p>
                    No. Your premiums reflect the claims made within a wider pool of customers and we don't just put up
                    the cost of your plan just because you made a claim.
                </p>
            </Faq>

        </FaqPanel>
    )
}
