import {AnEvent} from '../../event-kit'
import {LifeEventData} from '../interfaces'
import {AccountType} from '../../../domain/models/Account'

export class LifeRepoUpserted extends AnEvent implements LifeEventData {

    accountId: string
    accountType: AccountType
    subscriptionId: string
    policyId: string
    lifeId: string
    cognitoId: string

    constructor(from: LifeRepoUpserted) {
        super()
        Object.assign(this, from)
    }
}
