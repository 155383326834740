import {useCurrentQuote} from '../QuoteController'
import {QuoteResponse, toClass} from '@peachy/legacy-domain'
import {currencyFromPence} from '@peachy/utility-kit-pure'
import React from 'react'

export function PriceBreakdown() {

    const quote = useCurrentQuote()
    const totalPrice = quote.response?.totalPolicyPrice?.value ?? 0

    const quoteResponse = toClass(quote.response.get(), QuoteResponse)

    const totalBasePremium = quoteResponse.lives.map(l =>
        Object.values(l.benefitPriceBreakdown.adjustedForLossRatio).reduce((s, a) => s + a)
    ).reduce((s, a) => s + a)

    const ipt = quoteResponse.lives.map(l =>
        Object.values(l.benefitPriceBreakdown.adjustedForIpt).reduce((s, a) => s + a)
    ).reduce((s, a) => s + a) - totalBasePremium

    const remoteCare = quoteResponse.lives.length * 300

    const commission = (totalBasePremium - remoteCare) / 5

    return (<>
        <h3>Your price explained</h3>
        <p>
            Peachy earns money from the premium you pay. Here is the price breakdown for the plan you've selected:
        </p>
        <table>
            <thead>
                <tr>
                    <th>Price breakdown</th>
                    <th>Per month</th>
                    <th></th>
                </tr>
            </thead>
            <tr>
                <td>Premium:</td>
                <td>{currencyFromPence(totalBasePremium)}</td>
                <td></td>
            </tr>
            <tr>
                <td>Insurance Premium Tax:</td>
                <td>{currencyFromPence(ipt)}</td>
                <td></td>
            </tr>
            <tr>
                <th>Total price:</th>
                <th>{currencyFromPence(totalPrice)}</th>
                <th></th>
            </tr>
        </table>
        <table>
            <thead>
                <tr>
                    <th>How Peachy makes money</th>
                    <th>Per month</th>
                    <td></td>
                </tr>
            </thead>
            <tr>
                <td>Peachy app &amp; connected services:</td>
                <td>{currencyFromPence(remoteCare)}</td>
                <td>(£3 per member)<br/> Connected services include Virtual GP</td>
            </tr>
            <tr>
                <td>Commission:</td>
                <td>{currencyFromPence(commission)}</td>
                <td>20% commission on premium after Peachy app & connected services fee</td>
            </tr>
            <tr>
                <th>Total commission:</th>
                <th>{currencyFromPence(commission + remoteCare)}</th>
                <td></td>
            </tr>
        </table>
        <p>
            <br/>
            Our commission covers everything we do - claims processing, customer service, marketing and all the rest.
        </p>
    </>)
}
