import {AnEvent} from '../event-kit'

export class SubscriptionCreated extends AnEvent {
    public accountId: string
    public subscriptionId: string
    public stripeCustomerId: string
    public stripeSubscriptionId: string

    constructor(from: SubscriptionCreated) {
        super()
        Object.assign(this, from)
    }
}
