import {createState as createHookState, State as HookState} from '@hookstate/core'

export type StageTransitionNetwork<StageNameUnion extends string, StageDataType> = {
    dispatch<F extends StageEventHandler<StageDataType>>(handler: F, ...params: Parameters<F>): boolean
    currentStage() : HookState<StageState<StageNameUnion, StageDataType>>
}


export function createNetwork<StageNameUnion extends string, StageDataType>(netDef: StageNetworkDefinition<StageNameUnion, StageDataType>, initialStage: StageEvents<StageDataType>, initialStageData?: StageDataType): StageTransitionNetwork<StageNameUnion, StageDataType> {

    const initialStageName = getPropertyName(netDef, initialStage)

    const currentStageState = createHookState<StageState<StageNameUnion, StageDataType>>(
        {
            stage: initialStageName,
            data: initialStageData
        }
    )

    return {
        dispatch<F extends StageEventHandler<StageDataType>>(handler: F, ...params: Parameters<F>) {
            const currentStageName = currentStageState.stage.value
            const currentStage = netDef[currentStageName]
            if (Object.values(currentStage).includes(handler)) {
                const handlerResult = handler(...params)
                if (handlerResult) {
                    if (isStageStateResponse(handlerResult)) {
                        const [nextStage, nextStageData] = handlerResult
                        currentStageState.set({
                            stage: getPropertyName(netDef, nextStage),
                            data: nextStageData
                        })

                    } else {
                        currentStageState.set({
                            stage: getPropertyName(netDef, handlerResult)
                        })
                    }

                    return true
                }
            }
            return false
        },
        currentStage() {
            return currentStageState
        },
    }
}

function getPropertyName<T extends object>(object: T, value: any): keyof T {
    // @ts-ignore
    return Object.keys(object).find(k => object[k] === value) as keyof T
}

function isStageStateResponse<StageDataType>(handlerResponse: StageEventHandlerResponse<StageDataType>): handlerResponse is StageStateResponse<StageDataType>  {
    return Array.isArray(handlerResponse)
}

export type StageNetworkDefinition<StageNameUnion extends string, StageDataType> = {
    [_ in StageNameUnion]: StageEvents<StageDataType>
}
export type StageEvents<StageDataType> = {
    [key: string]: StageEventHandler<StageDataType>
}

export type StageEventHandler<StageDataType> = (...args: any[]) => StageEventHandlerResponse<StageDataType>

export type StageEventHandlerResponse<StageDataType> = StageStateResponse<StageDataType> | StageEvents<StageDataType>

export type StageStateResponse<StageDataType> = [StageEvents<StageDataType>, StageDataType]



export type StageState<StageNameUnion, StageDataType> = {
    stage: StageNameUnion,
    data?: StageDataType
}


