import React from 'react'
import Faq from '../../../shared/FaqPanel/Faq'
import FaqLink from '../../../shared/FaqPanel/FaqLink'

export default function WhatTypes() {

    return (
        <Faq question={'What types of information we collect'}>
            <p>
                We collect the following types of information for all reasons outlined in
                <FaqLink link={'What we use your information for'}/>
            </p>
            <h4>Personal information</h4>
            <p>
                This will be provided by yourself or the plan holder if you are under 18 or a spouse.
                We will use this to contact you, identify you or manage our relationship with you. This includes:
            </p>
            <ul>
                <li>Contact details</li>
                <li>Date of birth</li>
            </ul>
            <h4>Financial information</h4>
            <p>
                This will provided by yourself, and includes;
            </p>
            <ul>
                <li>Payment details</li>
                <li>Transactions and payments made for your plan</li>
            </ul>
            <h4>Sensitive information</h4>
            <p>
                This will be provided by yourself or by a third party in relation to your previous healthcare.
                This includes:
            </p>
            <ul>
                <li>Health information</li>
                <li>Information about pre-existing/chronic conditions</li>
                <li>Your existing GP or doctor details</li>
            </ul>
            <h4>Information from third-parties</h4>
            <p>
                This will be provided by a third-party after account registration or requested by us at any point,
                in relation to any screening checks to prevent crime and to help us provide the best service.
                This includes:
            </p>
            <ul>
                <li>Information about criminal convictions and offenses</li>
                <li>Information relating to anti-fraud</li>
                <li>Information from payment service providers or credit-reference agencies</li>
            </ul>
        </Faq>
    )
}
