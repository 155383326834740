import React from 'react'
import Faq from '../../../shared/FaqPanel/Faq'

export default function Governance() {

    return (
        <Faq question={'Governing law'}>
            <p>
                Our Terms, their subject matter and their formation will be governed and construed in accordance with
                English law. English courts will have exclusive jurisdiction over any claim arising from any use of our
                website or materials on our website, although we retain the right to bring proceedings against you for
                breach of these terms in your country of residence or any other relevant country.
            </p>
        </Faq>
    )
}
