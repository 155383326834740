import {AnEvent} from '../../event-kit'
import {Props} from '@peachy/utility-kit-pure'
import {IdCheckStatus} from '../../../domain/models/memorepo/statuses'


export class IdCheckEvent extends AnEvent {
    constructor(from: Props<IdCheckEvent>) {
        super()
        Object.assign(this, from)
    }
    firstname: string
    lastname: string
    birthdate: string
    cognitoId: string
    status: IdCheckStatus
    s3Url: string
    vouchedJobId: string
    vouchedPortalUrl: string
    errors?: VouchedError[]
}

export type VouchedError = {
    type: string,
    message: string
    warning: boolean
    suggestion: string
}
