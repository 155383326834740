import {
    Address as LegacyAddress,
    CardDetails as LegacyPaymentDetails,
    Life as LegacyLife,
    Plan as LegacyPlan,
    PlanBenefit as LegacyBenefit,
    Quote as LegacyQuote
} from '@peachy/legacy-domain'
import {
    Address,
    BenefitType as CoreBenefitType,
    MrAccount,
    MrLife,
    MrPlan,
    MrPlanBenefit,
    MrPolicy,
    MrSubscription,
    MrSubscriptionQuoteRequest,
    PaymentDetails
} from '@peachy/core-domain-pure'
import {dateFromIso, KeyMapped, localTimezone, mapById, newUUID, UK_TIMEZONE} from '@peachy/utility-kit-pure'
import {toCoreBenefitType} from '@peachy/quote-pure'

const DENTAL_LIMIT = 20000
const OPTICAL_LIMIT = 10000
const MENTAL_IN_LIMIT = 2000000
const MENTAL_OUT_LIMIT = 150000
const THERAPY_LIMIT = 250000

export async function mapFromLegacyPolicy(quote: LegacyQuote, legacyPaymentDetails: LegacyPaymentDetails, intercomVisitorId?: string) {

    const account: MrAccount = {
        id: newUUID(),
        type: 'INDIVIDUAL',
        status: 'ACTIVE',
        name: quote.request.getPrimaryLife().fullName(),
        contactEmail: quote.request.getPrimaryLife().email,
        contactName: quote.request.getPrimaryLife().fullName(),
        subscriptions: {},
    }

    const startDate = dateFromIso(quote.request.startDate)

    const subscription: MrSubscription = {
        id: newUUID(),
        name: quote.request.getPrimaryLife().fullName(),
        contactEmail: quote.request.getPrimaryLife().email,
        contactName: quote.request.getPrimaryLife().fullName(),
        plans: {},
        policies: {},
        paymentDetails: mapPaymentDetails(legacyPaymentDetails),
        startDate: startDate.getTime(),
        status: 'ACTIVE',
        timezone: localTimezone() ?? UK_TIMEZONE,
        versionIdx: 1,
        versionDate: startDate.getTime(),
        distributorId: quote.request.distributorId
    }

    const subscriptionRequest: MrSubscriptionQuoteRequest = {
        account,
        subscription,
        plans: quote.request.plans.map(mapPlan),
        policies: [mapToPolicy(quote, startDate.getTime())],
        quoteRequestId: quote.request.id,
        quoteRequestTimestamp: Date.now(),
        intercomVisitorId

    }

    return subscriptionRequest
}




function mapToPolicy(legacyQuote: LegacyQuote, startDate: number): MrPolicy {
    return {
        id: legacyQuote.request.id,
        status: 'ACTIVE',
        startDate,
        lives: mapLives(legacyQuote.request.lives, startDate)
    }
}


function mapLives(legacyLives: LegacyLife[], startDate: number): KeyMapped<MrLife> {
    return mapById(legacyLives.map((ll) => {
        const birthDate = dateFromIso(ll.birthDate)
        const life: MrLife = {
            id: ll.id,
            gender: ll.gender,
            email: ll.email,
            firstname: ll.firstname,
            lastname: ll.lastname,
            dateOfBirth: birthDate.getTime(),
            postcode: ll.address?.postcode,
            startDate,
            status: 'ACTIVE',
            type: ll.type,
            address: mapAddress(ll.address),
            benefits: {},
            planId: ll.planId,
            marketingAccepted: ll.marketingAccepted,
            smsMarketingAccepted: ll.smsMarketingAccepted
        }
        return life
    }))
}

function mapAddress(legacyAddress: LegacyAddress): Address {
    if (!legacyAddress) return null
    return {
        building: legacyAddress.building,
        country: legacyAddress.country,
        county: legacyAddress.county,
        display: legacyAddress.display,
        location: legacyAddress.location,
        postcode: legacyAddress.postcode,
        region: legacyAddress.region,
        settlement: legacyAddress.settlement
    }
}



function mapPlan(legacyPlan: LegacyPlan): MrPlan {
    return {
        id: legacyPlan.id,
        name: 'Individual Plan',
        benefits: mapBenefits(legacyPlan.benefits),
    }
}

function mapBenefits(legacyBenefits: LegacyBenefit[]) {
    return mapById(
        legacyBenefits.flatMap(lb => toCoreBenefitType(lb.name).map(cb => {
            const planBenefit: MrPlanBenefit = {
                id: cb,
                limit: getFixedBenefitLimit(cb) ?? (lb.limit * 100)
            }
            return planBenefit
        }))
    )
}

function getFixedBenefitLimit(benefitType: CoreBenefitType) {
    switch (benefitType) {
        case 'DENTAL': return DENTAL_LIMIT
        case 'OPTICAL': return OPTICAL_LIMIT
        case 'MENTAL_HEALTH_IN': return MENTAL_IN_LIMIT
        case 'MENTAL_HEALTH_OUT': return MENTAL_OUT_LIMIT
        case 'THERAPIES': return THERAPY_LIMIT
    }
}

function mapPaymentDetails(legacyPaymentDetails: LegacyPaymentDetails): PaymentDetails {
    const paymentDetails: PaymentDetails = {
        name: legacyPaymentDetails.name,
        email: legacyPaymentDetails.gatewayCustomer.email,
        stripePaymentId: legacyPaymentDetails.gatewayPaymentId,
        hasSeparateBillingAddress: legacyPaymentDetails.hasSeparateBillingAddress,
        billingAddress: mapAddress(legacyPaymentDetails.billingAddress),
        promoCode: legacyPaymentDetails.promoCode
    }
    return paymentDetails
}
