import {defineValidator, MessageProducer} from '../validate-core'
import {isArray, isEmpty, isNullish, isString} from '@peachy/utility-kit-pure'

export default function IsInProperty<T>(
    otherProperty: keyof T,
    message?: string | MessageProducer<T>
) {
    return defineValidator<T>({
        name: 'IsInProperty',
        messageParams: {otherProperty},
        message:
            message ??
            (({property, bannedValue}) =>
                `Property ${property} must be in '${otherProperty}' array`),
        validate({instance, value}) {
            const otherPropertyValue = instance[otherProperty]

            if (isEmpty(otherPropertyValue)) {
                return isNullish(value)
            } else {
                if (isNullish(value)) return false

                if (isArray(otherPropertyValue)) {
                    return otherPropertyValue.includes(value)
                } else if (isString(otherPropertyValue)) {
                    return otherPropertyValue.indexOf(value) > -1
                }
            }
        },
    })
}
