import React, {ReactElement} from 'react'

export type SelectionProps = {
    selection:string[]
    setSelection(s:string[]): void
}

export type ChoiceProps = SelectionProps & {
    label?: string
    minChoices?:number
    exclusive?:string[]
    className?: string
    children: ReactElement[]
}




export default function Choice({label, minChoices = 0, exclusive = [], className, selection, setSelection, children}: ChoiceProps) {

    const makeChoice = (optionValue: string) => {

        if (selection.includes(optionValue)) {
            //removing
            if (selection.length > minChoices) {
                setSelection(selection.filter(o => o != optionValue))
            } else {
                setSelection( selection)
            }

        } else {
            // adding
            if (exclusive.includes(optionValue)) {
                setSelection( [optionValue])
            } else {
                setSelection( [...selection.filter(o => !exclusive.includes(o)), optionValue])
            }
        }
    }

    const augmentedChildren = React.Children.map(children, c => {
        if (isInput(c)) {
            const optionValue = c.props.value
            const isSelected = selection.includes(optionValue)
            const childProps = {checked: isSelected, onChange: (e) => makeChoice(e.target.value)}
            return React.cloneElement(c, childProps )
        } else {
            return c
        }
    })

    return (
        <label className={className}>
            {label && <span>{label}</span>}
            {augmentedChildren}
        </label>
    )
}


function isInput(element: ReactElement): element is ReactElement<HTMLInputElement> {
    return element.type === 'input'
}
