import React, {useEffect, useRef, useState} from 'react'
import PeachyLogo from '../Logo/PeachyLogo'
import styles from './Header.module.css'
import useIsScrollTop from '../hooks/useIsScrollTop'
import {classList} from '@peachy/utility-kit-pure'
import {useLocation} from 'react-router'
import useScreenMetrics from '../hooks/useScreenMetrics'
import {CrapNav} from './CrapNav'

export default function Header() {

    const screenMetrics = useScreenMetrics([['MOBILE', 900]])

    return (screenMetrics.breakpoint === 'MOBILE')
        ? <MiniHeader/>
        : <FullHeader/>
}


function MiniHeader({}) {
    const isScrollTop = useIsScrollTop()
    const location = useLocation()
    const isGetCare = location.pathname == '/get-care'
    const headerClasses = classList(styles.Header, (isGetCare || !isScrollTop) && styles.HeaderShadow)

    const [menuOpen, setMenuOpen] = useState(false)

    const toggleMenu = () => {
        setMenuOpen(isOpen => {
            return !isOpen
        })
    }

    const menuClasses = classList(styles.miniNav, menuOpen && styles.miniNavOpen)

    const navRef = useRef<HTMLElement>()
    const headerRef = useRef<HTMLElement>()

    useEffect(() => {
        const dismiss = (e) => {
            if (navRef.current !== e.target.parentElement) {
                setMenuOpen(false)
            }
        }

        document.addEventListener('mousedown', dismiss)

        return () => {
            document.removeEventListener('mousedown', dismiss)
        }

    }, [])

    const activeOrNot = ({isActive}) => isActive ? styles.miniNavActive : undefined

    return (
        <header ref={headerRef} className={headerClasses}>
            <nav ref={navRef} onClick={() => {
                setMenuOpen(false)
            }} className={menuClasses}>
                <a href={'https://www.${servicePatch.rootDomain}/'}>Home</a>
                <a href={'https://www.${servicePatch.rootDomain}/how-it-works'}>How it works</a>
                <a href={'https://www.${servicePatch.rootDomain}/for-business'}>For business</a>
                <a href={'https://intercom-help.eu/peachy/en'}>FAQs</a>
                <a href={'https://www.${servicePatch.rootDomain}/about'}>About us</a>
                <a href={'/get-care'}>Get care</a>
                <a href={'/quote'}>Get a quote</a>

                {/*<NavLink end={true} to={'/'} className={activeOrNot}>Home</NavLink>*/}
                {/*<NavLink to={'/how-it-works'} className={activeOrNot}>How it works</NavLink>*/}
                {/*<NavLink to={'/for-business'} className={activeOrNot}>For business</NavLink>*/}
                {/*<NavLink to={'/faqs'} className={activeOrNot}>FAQs</NavLink>*/}
                {/*<NavLink to={'/about-us'} className={activeOrNot}>About us</NavLink>*/}
                {/*<NavLink to={'/get-care'} className={activeOrNot}>Get care</NavLink>*/}
                {/*<NavLink to={'/quote'} className={activeOrNot}>Get a quote</NavLink>*/}
            </nav>
            <HamburgerMenu onClick={toggleMenu}/>
            <PeachyLogo onClick={toggleMenu} className={styles.miniHeaderLogo}/>
            {/*<Link className={library.buttonSmall} to={'/quote'}>Get a quote</Link>*/}
        </header>
    )
}


function HamburgerMenu({onClick}) {
    return (
        <button onClick={onClick} className={styles.HamburgerButton}>Menu</button>
    )
}


function FullHeader() {
    const isScrollTop = useIsScrollTop()
    const location = useLocation()
    const isGetCare = location.pathname == '/get-care'
    const headerClasses = classList(styles.Header, (isGetCare || !isScrollTop) && styles.HeaderShadow)

    return (
        <header className={headerClasses}>
            <CrapNav/>
            {/*<Nav className={styles.NavList} containerClassName={styles.Nav}>*/}
            {/*    <div>*/}
            {/*        <a href={`https://www.${ROOT_DOMAIN}/how-it-works`}>How it works</a>*/}
            {/*        <a href={`https://www.${ROOT_DOMAIN}/for-business`}>For business</a>*/}
            {/*        <a href={`https://www.${ROOT_DOMAIN}/faqs`}>FAQs</a>*/}
            {/*        <a href={`https://www.${ROOT_DOMAIN}/about`}>About</a>*/}
            {/*    </div>*/}
            {/*    <a href={`https://www.${ROOT_DOMAIN}`}>*/}
            {/*        <PeachyLogo/>*/}
            {/*    </a>*/}
            {/*    <div>*/}
            {/*        <a href={'get-care'}>Get care</a>*/}
            {/*        <a className={library.button} href={`https://www.${ROOT_DOMAIN}/register`}>Sign up</a>*/}
            {/*    </div>*/}
            {/*</Nav>*/}
        </header>
    )
}
