import React from 'react'
// @ts-ignore
import PeachyLogoSvg from './peachy-tm.svg'
import styles from './PeachyLogo.module.css'

export type PeachyLogoProps = {
    className?: string,
    onClick?: () => void
}


export default function PeachyLogo({className, onClick}: PeachyLogoProps) {
    const cursorStyle = onClick ? {cursor: 'pointer' } : {}
    const classes = className ? [styles.PeachyLogo, className].join(' ') : styles.PeachyLogo
    return <PeachyLogoSvg style={cursorStyle} onClick={onClick ?? (() => {})} className={classes}/>
}
