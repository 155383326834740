import React, {Dispatch} from 'react'

import baseClasses from './PhoneNumberInput.module.css'
import parsePhoneNumber, {AsYouType, PhoneNumber} from 'libphonenumber-js'
import {classList} from '@peachy/utility-kit-pure'
import TextInput from '../TextInput/TextInput'

export type ReactState<T> = [T, Dispatch<React.SetStateAction<T>>]

export type ReactSetState<T> = Dispatch<React.SetStateAction<T>>


type PhoneNumberInputProps = {
    phone: string;
    setPhone: ReactSetState<string>
    className?: string,
    autoFocus?: boolean
}

export default function PhoneNumberInput(props: PhoneNumberInputProps) {


    const {className, phone, setPhone, autoFocus} = props

    const inputClasses = classList(baseClasses.input, className)

    const onChange = (value) => {
        let raw = value.replace(/ /, '')
        if (raw.length > 10) {
            raw = raw.slice(0, 10)
        }

        const parsed: PhoneNumber = parsePhoneNumber(raw, 'GB')
        if (parsed) {
            setPhone(parsed.number.toString())
        } else {
            setPhone(raw)
        }
    }

    const icon = <span className={baseClasses.flag}>+44 (0)</span>

    const formattedNumber = phone ? new AsYouType('GB').input(phone).replace(/\+44 ?/, '') : ''


    return (
        <TextInput
            className={inputClasses}
            value={formattedNumber}
            label={'Phone number'}
            setValue={onChange}
        />
    )
}

PhoneNumberInput.propTypes = {}
