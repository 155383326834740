import {Quote} from './quote'
import {Type} from 'class-transformer'
import IsUUID from './validate/validators/IsUUID'
import IsIsoDateString from './validate/validators/IsIsoDateString'
import {CardDetails} from './CardDetails'

export class Policy {
    @Type(() => PolicyRequest)
    request: PolicyRequest = new PolicyRequest()

    @Type(() => PolicyResponse)
    response: PolicyResponse = undefined
}

export class PolicyRequest {
    @IsUUID()
    id: string = undefined

    @IsIsoDateString()
    timestamp: string = undefined

    @Type(() => Quote)
    quote: Quote = undefined

    @Type(() => CardDetails)
    cardDetails: CardDetails = undefined

    intercomVisitorId?: string
}

export class PolicyResponse {
    request: PolicyRequest
    policyHash: string
}

