import styles from './RipeIdea.module.css'
import useScrollAwareRef from '../../../hooks/useScrollAwareRef'

export default function RipeIdea() {

    const ref = useScrollAwareRef<HTMLOListElement>('deg')

    return (
        <section className={styles.RipeIdea}>
            <h2>We've got the ripe idea</h2>
            <p>
                <span>
                    Peachy happened when we realised private health insurance options weren't cutting it.
                    We built the UK's first digital insurance platform to streamline everything for you.
                </span>
            </p>
            <ul ref={ref} className={styles.Points}>
                <li>
                    <h4>Clear pricing</h4>
                    <p>Fast quotes, simplified options, and complete clarity around what's exactly covered in your plan.</p>
                </li>
                <li>
                    <h4>Breezy claims</h4>
                    <p>No complex questionnaires. Make a claim as fast as you can order a cab—in just a few taps.</p>
                </li>
                <li>
                    <h4>Power to choose</h4>
                    <p>Go with the health expert you're most comfortable with, and we'll pay for all or most of it.</p>
                </li>
            </ul>
        </section>
    )
}




