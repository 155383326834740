import {defineValidator, MessageProducer, ValidateIf} from '../validate-core'
import {isIsoDate} from '@peachy/utility-kit-pure'

export default function IsIsoDateString<T>(
    message?: string | MessageProducer<T>,
    validateIf?: ValidateIf<T>
) {
    return defineValidator<T>({
        name: 'IsIsoDateString',
        message:
            message ??
            (({property}) =>
                `Property ${property} must be a valid ISO date string`),
        validateIf,
        validate({value}) {
            return isIsoDate(value)
        },
    })
}
