import {isBefore} from 'date-fns'
import {isString} from './type-check-kit'
import {Dictionary} from './utility-types'

export class HttpError extends Error {
    constructor(public readonly statusCode: number, message?: string) {
        super(message)
    }
}

export class HttpUnauthorizedError extends HttpError {
    constructor(message?: string) {
        super(401, message)
    }
}

export class HttpValidationError extends HttpError {
    constructor(message: string) {
        super(400, message)
    }
}

export class HttpNotFoundError extends HttpError {
    constructor(message?: string) {
        super(404, message)
    }
}

export function respond(status: number, body: any = '') {
    return {
        statusCode: status,
        body: isString(body) ? body : JSON.stringify(body),
    }
}

export function asXFormBody(body: Dictionary<string | number | boolean>) {
    return Object.entries(body).map(([key, value]) => encodeURIComponent(key) + '=' + encodeURIComponent(value)).join('&')
}

export type HttpMethod = 'GET'|'POST'|'PUT'|'PATCH'|'DELETE'

export class AuthDetails {
    access_token: string
    refresh_token?: string
    token_type: string
    expiresAt?: number
    scope?: string

    private constructor(props: Omit<AuthDetails, 'isExpired'>) {
        this.access_token = props.access_token
        this.refresh_token = props.refresh_token
        this.token_type = props.token_type
        this.expiresAt = props.expiresAt
        this.scope = props.scope
    }

    public static fromOidcAuthResponse(json: Omit<AuthDetails, 'expiresAt' | 'isExpired'> & {expires_in?: string}) {
        if (json.access_token) {
            const expiresAt = json.expires_in ? Date.now() + Number(json.expires_in) : undefined
            return new AuthDetails({...json, expiresAt})
        } else {
            throw json
        }
    }

    public static ifUsable(auth?: AuthDetails) {
        return (auth && !auth.isExpired()) ? auth! : undefined
    }

    public isExpired() {
        const now = Date.now()
        return this.expiresAt && !isBefore(now, this.expiresAt)
    }
}

export interface AuthProvider {
    getOrRefetchAuth: () => Promise<AuthDetails>
    fetchAuth: () => Promise<AuthDetails>
}

export const errorResponse = (errorCode: number, message: string) => respond(errorCode, { message })
