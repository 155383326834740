import styles from './ProfileLocation.module.css'
import Distance from '../Distance/Distance'
import {ContactInfo} from '../ProfilePanel'
import Contact from '../Contact/Contact'
import {classList} from '@peachy/utility-kit-pure'
import {Consultant, Practice, PracticeHours} from '@peachy/legacy-domain'

type ProfileLocationProps = {
    profile: Consultant
    location: Practice
    secretaries: ContactInfo[]
}

export default function ProfileLocation({location, profile, secretaries}: ProfileLocationProps) {

    const seen = new Set<string>()

    const locationSecretaries = location.secretaries.map(i => secretaries[i]).map(s => {
        s.phoneNumbers.forEach(p => seen.add(p))
        s.emails.forEach(e => seen.add(e))
        return <Contact emails={s.emails} phoneNumbers={s.phoneNumbers} name={s.name}/>
    })

    const phones = (location.phoneNumbers ?? [])
        .filter(p => !seen.has(p))

    const emails = (location.emails ?? [])
        .filter(e => !seen.has(e))

    const locationContact = <Contact emails={emails} phoneNumbers={phones}/>

    const hasSecretaries = !!locationSecretaries.length

    return (
        <article className={styles.location}>
            <h4 className={styles.locationName}>
                <span>{location.name}</span>
                <Distance meters={location.distance}/>
            </h4>

            <p className={styles.address}>
                {location.address.map((l, i) => {
                    const classes = classList(styles.addressLine, i === location.address.length - 1 && styles.postcode)
                    return <span className={classes} key={i}>{l.toLowerCase()}</span>
                })}
            </p>
            <OpeningTimes openingTimes={location.practiceHours}/>
            {locationContact}
            {/*{(emails.length || phones.length) && locationContact}*/}
            {hasSecretaries && <h5>Medical secretaries</h5>}
            {locationSecretaries}
        </article>
    )
}


export const Email = ({email}: {email: string}) =>
    <a onClick={e => e.stopPropagation()} href={`mailto:${email}`} className={styles.email}>{email}</a>


export const Phone = ({phone}: {phone: string}) =>
    <span className={styles.phone}>{phone}</span>



type OpeningTimesProps = {
    openingTimes: PracticeHours[]
}


function OpeningTimes({openingTimes}: OpeningTimesProps) {
    // openingTimes = openingTimesMock

    const days = openingTimes?.map(ph => <>
        <dt>{ph.day}</dt>
        <dd>{ph.hours}</dd>
    </>)

    return openingTimes?.length ?
        <dl className={styles.openingTimes}>
            {days}
        </dl> : null
}


const openingTimesMock = [
    {
        day: 'Monday',
        hours: '09:00 - 15:00',
    },
    {
        day: 'Tuesday',
        hours: '09:00 - 15:00',
    },
    {
        day: 'Wednesday',
        hours: '09:00 - 15:00',
    },
    {
        day: 'Thursday',
        hours: '09:00 - 15:00',
    },
    {
        day: 'Friday',
        hours: '09:00 - 15:00',
    }
]

