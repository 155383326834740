import React, {useState} from 'react'
import styles from './QuoteFooter.module.css'
import library from '../../global-style/library.module.css'
import FormattedPrice from '../BuildQuote/FormattedPrice/FormattedPrice'
import {useCurrentQuote, useQuoteService} from '../QuoteController'
import Expander from '../../elements/Expander/Expander'
import Modal from '../../elements/Modal/Modal'
import {QuoteResponse, toClass} from '@peachy/legacy-domain'
import {classList, currencyFromPence} from '@peachy/utility-kit-pure'
import {PriceBreakdown} from './PriceExplainedModal'

export type QuoteFooterProps = {
    className?: string
    show: boolean
}

export default function QuoteFooter({show, className}: QuoteFooterProps) {

    const rootClasses = classList(styles.QuoteFooter, className)
    const buttonClasses = classList(library.button, library.buttonPalePink)

    const quoteService = useQuoteService()

    return (
        <Expander expanded={show} tag={'footer'} className={rootClasses}>
            <section>
                <Price/>
                <a id={'accept-quote-footer'} onClick={() => quoteService.apply()} className={buttonClasses}>Let's go &rarr;</a>
            </section>
        </Expander>
    )
}


function Price() {

    const [showModal, setShowModal] = useState(false)

    const onDismiss = () => {
        setShowModal(false)
    }

    return (<>
            <article className={styles.price} onClick={() => setShowModal(true)}>
                <FormattedPrice/>
                <span className={styles.priceLink}>Price explained &rarr;</span>
            </article>
            {showModal && <Modal onDismiss={onDismiss}>
                <section className={styles.priceBreakdown}>
                    <Cancel onDismiss={onDismiss}/>
                    <PriceBreakdown/>
                </section>
            </Modal>}
        </>
    )
}
//
// function PriceBreakdown() {
//
//     const quote = useCurrentQuote()
//     const totalPrice = quote.response?.totalPolicyPrice?.value ?? 0
//
//     const quoteResponse = toClass(quote.response.get(), QuoteResponse)
//
//     const totalBasePremium = quoteResponse.lives.map(l =>
//         Object.values(l.benefitPriceBreakdown.adjustedForLossRatio).reduce((s, a) => s + a)
//     ).reduce((s, a) => s + a)
//
//     const ipt = quoteResponse.lives.map(l =>
//         Object.values(l.benefitPriceBreakdown.adjustedForIpt).reduce((s, a) => s + a)
//     ).reduce((s, a) => s + a) - totalBasePremium
//
//     const remoteCare = quoteResponse.lives.length * 300
//
//     const commission = (totalBasePremium - remoteCare) / 5
//
//     return (<>
//         <h3>Your price explained</h3>
//         <p>
//             It's simple, we earn money from the premium that you pay. Here is the price breakdown for the plan you've selected:
//         </p>
//         <table>
//             <thead>
//             <tr>
//                 <th>Breakdown</th>
//                 <th>£ per month</th>
//                 <th></th>
//             </tr>
//             </thead>
//             <tr>
//                 <td>Premium</td>
//                 <td>{currencyFromPence(totalBasePremium)}</td>
//                 <td></td>
//             </tr>
//             <tr>
//                 <td>Insurance premium tax</td>
//                 <td>{currencyFromPence(ipt)}</td>
//                 <td></td>
//             </tr>
//             <tr>
//                 <th>Total monthly price</th>
//                 <th>{currencyFromPence(totalPrice)}</th>
//                 <th></th>
//             </tr>
//             <tr>
//                 <th>How Peachy makes money</th>
//                 <th>£ per month</th>
//                 <td></td>
//             </tr>
//             <tr>
//                 <td>Virtual GP</td>
//                 <td>{currencyFromPence(remoteCare)}</td>
//                 <td>£3 per member per month</td>
//             </tr>
//             <tr>
//                 <th>Commission</th>
//                 <th>{currencyFromPence(commission)}</th>
//                 <td>20% commission on premium after virtual GP fee. This is to cover everything we do from claims processing, customer service, marketing etc. Best of all - we don't profit from not paying claims!</td>
//             </tr>
//         </table>
//     </>)
// }

const Cancel = ({onDismiss}) =>
    <button className={classList('fal fa-times', styles.cancel)} onClick={(e) => {
        onDismiss()
    }}>X</button>
