import React, {useState} from 'react'
import CaptureForm, {CaptureFieldsProps, CaptureFormProps} from '../CaptureForm'
import styles from './CaptureAddress.module.css'
import Toggle from '../../../elements/Toggle/Toggle'
import {Life} from '@peachy/legacy-domain'
import AddressLookup from '../../../elements/LocationLookup/AddressLookup'

export default function CaptureAddress(props: CaptureFieldsProps<Life>) {
    const {instance, visitedFields} = props

    const [vetoSubmit, setVetoSubmit] = useState(false)

    const onFieldChange = (value: string) => {
        const hasChanged = instance.address?.display?.value !== value
        if (hasChanged) {
            setVetoSubmit(true)
        } else {
            setVetoSubmit(false)
        }
    }

    const primaryLifeName = instance.firstname.value

    const captureAddressProps: CaptureFormProps<Life> = {
        ...props,
        buttonId: 'capture-address',
        className: styles.CaptureAddress,
        vetoSubmit,
        headers: <>
            <h4>Nice to meet you {primaryLifeName}!</h4>
            <h4>Where do you live?</h4>
        </>,
        captureFields: ['address', 'ukResidentConfirmed']
    }

    return (
        <CaptureForm  {...captureAddressProps}>
            <fieldset>
                <AddressLookup autoFocus initialSearchTerm={instance.address?.display?.value} residentialOnly onFieldChange={onFieldChange} onSelectAddress={(address) => {
                    instance.address.set(address)
                    setVetoSubmit(false)
                }} onBlur={() => visitedFields.toggleOn('address')}/>
            </fieldset>
            <fieldset className={styles.optIns}>
                <label>
                    <span>I confirm the following:</span>
                    <Toggle className={styles.toggle} state={instance.ukResidentConfirmed.value} setState={instance.ukResidentConfirmed.set}/>
                    <ul>
                        <li>I am a UK resident</li>
                        <li>I live at this address for at least 180 days per year</li>
                        <li>I have been registered with a UK NHS GP for at least 6 months, or that I'm able to provide my full medical records in English</li>
                    </ul>
                </label>
            </fieldset>
        </CaptureForm>
    )
}
