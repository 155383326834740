import {Properties} from '@peachy/utility-kit-pure'
import {AnEvent} from '../../../event-kit'
import {LifeEventData, LifeTurnover} from '../../interfaces'
import {AccountType} from '../../../../domain/models/Account'

export class MemberPolicyAlterationDocSendRequest extends  AnEvent implements LifeEventData {
    constructor(from: Properties<MemberPolicyAlterationDocSendRequest>) {
        super()
        Object.assign(this, from)
    }

    lifeTurnover?: LifeTurnover

    cognitoId: string

    accountId: string
    accountType: AccountType
    lifeId: string
    policyId: string
    subscriptionId: string
}
