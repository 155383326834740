import React from 'react'
import styles from './FaqPanel.module.css'
import {useFaqLink} from './FaqPanel'

export type FaqLinkProps = {
    link: string
}

export default function FaqLink({link}: FaqLinkProps) {
    const goToFaq = useFaqLink()
    return (
        <span className={styles.FaqLink} onClick={() => goToFaq(link)}> '{link}'</span>
    )
}
