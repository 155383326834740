import React, {useEffect, useRef, useState} from 'react'
import {
    classList,
    DateArray,
    dateFrom,
    dateFromIso,
    formatIsoDateOnly,
    isValidDateArray
} from '@peachy/utility-kit-pure'
import {State as HookState, useState as useHookState} from '@hookstate/core'
import useToggles from '../../../hooks/useToggles'
import styles from './DateInput.module.css'
import TextBox from '../TextBox/TextBox'
import Modal from '../../../elements/Modal/Modal'
import DatePicker from '../DatePicker/DatePicker'
import pickerStyles from '../DatePicker/StyledDatePicker.module.css'
import library from '../../../global-style/library.module.css'


type DateInputProps = {
    onAllVisited(): void
    autofocus?: boolean
    enableTextInput?: boolean
    header: string
    legend?: string
    dateState: HookState<string>
    defaultPickerDate?: Date
    minDate?: Date
    maxDate?: Date
}

export default function DateInput<T>({onAllVisited, dateState, defaultPickerDate, autofocus = false, legend, header, minDate, maxDate}: DateInputProps) {

    const [showPicker, setShowPicker] = useState(false)

    const actualDate = dateFromIso(dateState?.value)

    const dateParts = useHookState<DateState>(dateStateFromDate(actualDate))

    const updateDate = () => {
        const dateArray = stateAsDateArray(dateParts.value)

        if (dateArray[2] > 1900 && isValidDateArray(dateArray)) {
            dateState.set(formatIsoDateOnly(dateFrom(dateArray)))
        } else {
            dateState.set(undefined)
        }
    }

    const onPickDate = (date: Date) => {
        dateParts.set(dateStateFromDate(date))
        dateState.set(formatIsoDateOnly(date))

        // oooh dirty - must move
        const nextButton = fieldsetRef.current.parentElement.lastElementChild as HTMLElement
        requestAnimationFrame(() => {
            nextButton.focus()
        })
    }

    const visitedFields = useToggles(['day', 'month', 'year'], onAllVisited)

    const pickerIconClasses = classList('fal fa-calendar', styles.pickerIcon)


    const dayField = useRef<HTMLInputElement>()
    const monthField = useRef<HTMLInputElement>()
    const yearField = useRef<HTMLInputElement>()
    const fieldsetRef = useRef<HTMLFieldSetElement>()


    useEffect(() => {
        if (actualDate) {
            dateParts.set(dateStateFromDate(actualDate))
        }
    }, [actualDate?.getTime() ?? 0])


    return (<>
            <fieldset ref={fieldsetRef} className={styles.DateInput}>
                {legend && <legend>{legend}</legend>}
                <TextBox autoFocus={autofocus} inputRef={dayField} placeholder={'Day'} value={dateParts.day.value}
                         setValue={(v) => {
                             dateParts.day.set(v)
                             if (v.length == 2) {
                                 monthField.current.focus()
                             }
                             updateDate()
                         }} onBlur={() => visitedFields.toggleOn('day')} numeric maxLength={2}/>
                <TextBox inputRef={monthField} placeholder={'Month'} value={dateParts.month.value} setValue={(v) => {
                    dateParts.month.set(v)
                    if (v.length == 2) {
                        yearField.current.focus()
                    }
                    updateDate()
                }} onBlur={() => visitedFields.toggleOn('month')} numeric maxLength={2}/>
                <TextBox inputRef={yearField} placeholder={'Year'} value={dateParts.year.value} setValue={(v) => {
                    dateParts.year.set(v)
                    updateDate()
                }} onBlur={() => visitedFields.toggleOn('year')} numeric maxLength={4}/>
                {!showPicker &&
                <button type={'button'} onClick={(e) => {
                    setShowPicker(true)
                }}>
                    <i className={pickerIconClasses}>Date picker</i>
                </button>
                }
            </fieldset>
            {
                showPicker &&
                <Picker minDate={minDate} maxDate={maxDate} header={header} defaultPickerDate={defaultPickerDate} selectedDate={actualDate} onSelect={onPickDate} onDismiss={() => {
                    setShowPicker(false)
                }}/>
            }
        </>
    )
}


function Picker({selectedDate, defaultPickerDate, onSelect, onDismiss, header, minDate, maxDate}) {

    return (
        <Modal onDismiss={onDismiss}>
            <DatePicker
                onSelectDate={onSelect}
                header={
                    <h3>{header}</h3>
                }
                minDate={minDate}
                maxDate={maxDate}
                onDismiss={onDismiss}
                selectedDate={selectedDate}
                displayDate={selectedDate ?? defaultPickerDate}
                className={pickerStyles.datePicker}
                invalidNudgeClassName={pickerStyles.invalidMonthYearNudge}
                monthYearSelectorClassName={pickerStyles.monthYearSelector}
                monthYearSelectorListClassName={library.lookup}
                weekendClassName={pickerStyles.weekend}
                weekdayClassName={pickerStyles.weekday}
                outOfMonthClassName={pickerStyles.outOfMonth}
                invalidDayClassName={pickerStyles.invalidDay}
                todayClassName={pickerStyles.today}
                selectedDateClassName={pickerStyles.selected}
                leftArrowClassName={'fal fa-angle-left'}
                rightArrowClassName={'fal fa-angle-right'}
                cancelButtonClassName={classList('fal fa-times', pickerStyles.cancel)}
            />
        </Modal>
    )

}


type DateState = {
    day: string,
    month: string,
    year: string,
}

function stateAsDateArray(dateState: DateState) {
    const dateArray: DateArray = [undefined, undefined, undefined]
    if (dateState.day) {
        dateArray[0] = +dateState.day
    }
    if (dateState.month) {
        dateArray[1] = +dateState.month
    }
    if (dateState.year) {
        dateArray[2] = +dateState.year
    }
    return dateArray
}

function dateStateFromDate(date: Date): DateState {

    if (date) {
        return {
            day: `${date.getDate()}`,
            month: `${date.getMonth() + 1}`,
            year: `${date.getFullYear()}`,
        }
    } else {
        return {
            day: '',
            month: '',
            year: '',
        }
    }
}
