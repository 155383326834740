import {LifeQuoteResponse as OldLifeQuote, Quote as OldQuote} from '../quote'
import {
    Address as NewAddress,
    BenefitType,
    Life,
    LifeBenefit,
    PaymentDetails,
    Plan,
    PlanBenefit,
    Policy,
    Premium,
} from '@peachy/core-domain-pure'
import {parseISO} from 'date-fns'
import {Life as OldLife} from '../people'
import {BenefitType as OldBenefitType, Plan as OldPlan} from '../plans'
import {CardDetails as OldCardDetails} from '../CardDetails'
import {KeyMapped} from '@peachy/utility-kit-pure'

const dentalLimit = 20000
const opticalLimit = 10000
const mentalInLimit = 2000000
const mentalOutLimit = 150000
const therapyLimit = 250000

export function mapOldQuoteToNewDomain(quote: OldQuote): [Policy, KeyMapped<Plan>] {
    const newPlan = mapOldPlanToNewPlan(quote.request.plans[0])
    const policyStartDate = parseISO(quote.request.startDate).getTime()

    const oldLives = quote.request.lives
    const oldLifeQuotes = quote.response.lives
    const newLives = mapOldLivesToNewLives(oldLives, oldLifeQuotes, newPlan, policyStartDate)

    const newPolicy = new Policy()

    newPolicy.id = quote.response.quoteResponseId
    newPolicy.lives = newLives
    newPolicy.startDate = policyStartDate
    newPolicy.totalMonthlyPremium = Object.values(newLives).reduce((total, life) => total + life.totalMonthlyPremium, 0)

    return [newPolicy, {[newPlan.id]: newPlan}]
}


function mapOldLivesToNewLives(oldLives: OldLife[], oldLifeQuotes: OldLifeQuote[], newPlan: Plan, policyStartDate: number) {

    const newLives: KeyMapped<Life> = {}

    oldLives.forEach((oldLife: OldLife) => {
        const newLife = new Life()
        const oldLifeQuote = oldLifeQuotes.find(l => l.lifeId === oldLife.id)
        newLife.id = oldLife.id
        newLife.type = oldLife.type
        newLife.email = oldLife.email
        newLife.firstname = oldLife.firstname
        newLife.lastname = oldLife.lastname
        newLife.phone = oldLife.phone
        newLife.smsMarketingAccepted = oldLife.smsMarketingAccepted
        newLife.marketingAccepted = oldLife.marketingAccepted
        newLife.gender = oldLife.gender
        newLife.dateOfBirth = parseISO(oldLife.birthDate).getTime()
        newLife.postcode = oldLife.address.postcode
        newLife.address = oldLife.address as NewAddress
        newLife.planId = oldLife.planId
        newLife.benefits = lifeBenefits(newPlan.benefits, policyStartDate, oldLifeQuote)
        newLife.totalMonthlyPremium = newLife.benefits.reduce((total, ben) => total + ben.premium.total, 0)
        newLives[newLife.id] = newLife
    })
    return newLives
}

export function mapOldCardDetailsToNewPaymentDetails(oldCardDetails: OldCardDetails): PaymentDetails {

    const {gatewayPaymentId, gatewayCustomer, ...reusable} = oldCardDetails

    // @ts-ignore
    const paymentDetails: PaymentDetails = {
        ...reusable,
        name: reusable.name ?? gatewayCustomer.fullName,
        email: gatewayCustomer.email,
        stripePaymentId: gatewayPaymentId,
    }

    return paymentDetails
}


function mapOldPlanToNewPlan(oldPlan: OldPlan, planName?: string) {
    const newPlan = new Plan()
    newPlan.id = oldPlan.id
    newPlan.name = planName ?? 'Individual Plan'
    newPlan.benefits = planBenefits(oldPlan)
    return newPlan
}


export function lifeBenefits(newPlanBenefits: PlanBenefit[], startDate: number, oldLifeQuote: OldLifeQuote) {
    return newPlanBenefits.map(planBen => {
        const lifeBen = new LifeBenefit()
        lifeBen.utilisation = 0
        lifeBen.type = planBen.type
        lifeBen.startDate = startDate
        lifeBen.premium = lifePremium(oldLifeQuote, planBen.type)
        return lifeBen
    })
}


function lifePremium(oldLifeQuote: OldLifeQuote, newBenType: BenefitType) {

    const premium = new Premium()

    const exclude: BenefitType[] = ['OPTICAL', 'MENTAL_HEALTH_OUT']

    if (exclude.includes(newBenType)) {
        return premium
    }

    premium.baseRiskCost = oldLifeQuote.benefitPriceBreakdown.baseRiskCost[toOldBenType(newBenType)]
    premium.adjustedForContingency = oldLifeQuote.benefitPriceBreakdown.adjustedForContingency[toOldBenType(newBenType)]
    premium.adjustedForLossRatio = oldLifeQuote.benefitPriceBreakdown.adjustedForLossRatio[toOldBenType(newBenType)]
    premium.adjustedForIpt = oldLifeQuote.benefitPriceBreakdown.adjustedForIpt[toOldBenType(newBenType)]
    premium.adjustedForCorrelation = oldLifeQuote.benefitPriceBreakdown.adjustedForCorrelation[toOldBenType(newBenType)]
    premium.adjustedForPriceResolution = oldLifeQuote.benefitPriceBreakdown.adjustedForPriceResolution[toOldBenType(newBenType)]
    premium.total = premium.adjustedForPriceResolution
    return premium
}


function planBenefits(oldPlan: OldPlan) {

    const newBenefits: PlanBenefit[] = []

    oldPlan.benefits.forEach(oldBen => {

        if (oldBen.name === 'DENTAL_OPTICAL') {
            newBenefits.push({
                type: 'DENTAL',
                limit: dentalLimit,
            })
            newBenefits.push({
                type: 'OPTICAL',
                limit: opticalLimit,
            })
        } else if (oldBen.name === 'MENTAL_HEALTH') {
            newBenefits.push({
                type: 'MENTAL_HEALTH_IN',
                limit: mentalInLimit,
            })
            newBenefits.push({
                type: 'MENTAL_HEALTH_OUT',
                limit: mentalOutLimit,
            })
        } else if (oldBen.name === 'REMOTE_CARE') {
            newBenefits.push({
                type: 'VIRTUAL_GP',
            })
        } else if (oldBen.name === 'THERAPIES') {
            newBenefits.push({
                type: 'THERAPIES',
                limit: therapyLimit
            })
        } else {
            newBenefits.push({
                type: oldBen.name,
                limit: oldBen.limit * 100
            })
        }
    })
    return newBenefits
}

function toOldBenType(newBenType: BenefitType): OldBenefitType {
    switch (newBenType) {
        case 'VIRTUAL_GP':
            return 'REMOTE_CARE'
        case 'MENTAL_HEALTH_IN':
            return 'MENTAL_HEALTH'
        case 'MENTAL_HEALTH_OUT':
            return 'MENTAL_HEALTH'
        case 'DENTAL':
            return 'DENTAL_OPTICAL'
        case 'OPTICAL':
            return 'DENTAL_OPTICAL'
        default:
            return newBenType as OldBenefitType
    }
}
