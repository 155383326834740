import {createContext, useContext, useState} from 'react'
import {createWebShopServices, WebShopServices} from './WebShopServices'
import useAsyncEffect from 'use-async-effect'
import {getZoneInfo} from '@peachy/zone-config-pure'

const WebShopServiceContext = createContext<WebShopServices>(null)

export type WebShopServiceControllerProps = {
    children: any
}


export function WebShopServiceController(props: WebShopServiceControllerProps) {

    const [webShopServices, setWebShopServices] = useState<WebShopServices>()

    useAsyncEffect(async (isActive) => {
        const services = await createWebShopServices()

        if (isActive()) {
            console.log({services})
            setWebShopServices(services)
        }
    }, [])

    return webShopServices
        ? (
            <WebShopServiceContext.Provider value={webShopServices}>
                {props.children}
            </WebShopServiceContext.Provider>
        ) : null
}

export function useWebShopServices() {
    return useContext(WebShopServiceContext)
}


export function useServicePatch() {
    const zoneInfo = getZoneInfo()
    return zoneInfo.servicePatchUrl
}

function cleanName(name: string): string {
    return name.split(/[\W_]+/).map(s => s && (s[0].toUpperCase() + s.slice(1))).join('').toLowerCase()
}
