import React from 'react'
import styles from './PeachySelector.module.css'
import {classList} from '@peachy/utility-kit-pure'
import ListSelector, {ListSelectorProps} from '../../ListSelector/ListSelector'

export type PeachySelectorProps = ListSelectorProps & {
    list: string[]
}


export default function PeachySelector({className, ...otherProps}: PeachySelectorProps) {

    const rootClasses = classList(styles.PeachySelector, className)

    return (
        <aside className={rootClasses}>
            <ListSelector className={styles.options} {...otherProps}/>
        </aside>
    )
}
