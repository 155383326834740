import {toClass} from '../../validation/validation-core'
import {Company} from '../Company'
import {Plan} from '../Plan'
import {BenefitType} from '../PlanBenefit'
import {Policy} from '../Policy'


export class CompanyPolicyDocData {
    domain: string
    isSample: boolean
    subscriptionStartDate: number
    planNumber: string

    plans: Plan[]
    policies: Policy[]

    company: Company
    email: string
    isAlteration: boolean

    versionDate?: number
    totalMonthlyPremium?: number

    constructor (private readonly dataIn: CompanyPolicyDocData) {
        this.domain = dataIn.domain
        this.isSample = dataIn.isSample
        this.subscriptionStartDate = dataIn.subscriptionStartDate
        this.planNumber = dataIn.planNumber
        this.plans = dataIn.plans.map(plan => toClass(plan, Plan))
        this.policies = dataIn.policies.map(policy => toClass(policy, Policy))
        this.company = toClass(dataIn.company, Company)
        this.email = dataIn.email
        this.isAlteration = dataIn.isAlteration
        this.versionDate = dataIn.versionDate
        this.totalMonthlyPremium = dataIn.totalMonthlyPremium
    }

    hasBenefit(benefitType: BenefitType) {
        return this.plans.some(plan => plan.hasBenefit(benefitType))
    }

    hasExcess() {
        return this.plans.some(plan => plan.hasExcess())
    }

    getPlan(planId: string) {
        return this.plans.find(plan => plan.id === planId)
    }
}
