import {useEffect, useRef} from 'react'

export default function useScrollAwareRef<T extends HTMLElement>(units: string) {
    const ref = useRef<T>()

    const listener = e => {
        ref?.current?.style.setProperty('--scroll-y', window.scrollY + units)
    }

    useEffect(() => {
        window.addEventListener('scroll', listener, {passive:true})
        return () => window.removeEventListener('scroll', listener)
    })

    return ref
}
