import {CareSearchQuery, CareSearchResults} from './care-search-domain'
import {API} from '@aws-amplify/api'

import {dump, unique} from '@peachy/utility-kit-pure'
import {Consultant} from '@peachy/legacy-domain'

export default async function searchCare(query: CareSearchQuery): Promise<CareSearchResults> {

    if (query.rangeInMeters == 0 || !query.searchTerms.length) return {
        query,
        profiles:[],
    }


    const request = {
        body: query
    }

    try {

        console.log('Looking up...')
        dump(query)

        const response = await API.post(MarketplaceApi, lookupApiPath, request) as Consultant[]

        //
        // if (query.paediatric) {
        //     response = response.filter(c => {
        //         return paediatricTerms.includes(c.primarySpeciality) || c.otherSpecialities.some(s => paediatricTerms.includes(s))
        //     })
        // }
        console.log(response.length + ' Consultants found')

        return {
            query,
            profiles: response,
        }

    } catch (error) {
        // todo - complex error handling!!!
        console.log('ERROR RECEIVED FROM LOOKUP!!')
        console.log(error)
        throw error
    }

    // return response
    //
    // return await promiseTimeout(200, () => {
    //     return generateProfiles(query.locus, query.rangeInMeters)
    // })
}



const MarketplaceApi = 'MarketplaceApi'
const lookupApiPath = '/lookup'




const specialities = [
    "Cardiology",
    "Cardiothoracic surgery",
    "Dermatology",
    "ENT surgery",
    "Endocrinology",
    "General medicine",
    "General surgery",
    "Haematology",
    "Nephrology (Renal medicine)",
    "Neurology",
    "Neurosurgery",
    "Obstetrics & gynaecology",
    "Oncology",
    "Ophthalmology",
    "Oral & maxillofacial surgery",
    "Orthopaedic surgery",
    "Paediatric surgery",
    "Paediatrics",
    "Psychiatry",
    "Rheumatology",
    "Sexual health",
    "Urology",
    "Physiotherapy"
]

const specialisms = [
    "Breast surgery",
    "Care of the elderly medicine",
    "Child and adolescent psychiatry",
    "Endocrine surgery",
    "Forensic psychiatry",
    "Gastroenterology",
    "Haematology",
    "Infectious diseases",
    "Lower GI surgery",
    "Medical psychotherapy",
    "Nephrology (Renal medicine)",
    "Neurology",
    "Old age psychiatry",
    "Oncology",
    "Paediatric cardiology",
    "Paediatric endocrinology",
    "Paediatric gastroenterology",
    "Paediatric haematology",
    "Paediatric nephrology",
    "Paediatric neurology",
    "Paediatric oncology",
    "Paediatric surgery",
    "Psychiatry of learning disability",
    "Respiratory medicine",
    "Rheumatology",
    "Sexual health",
    "Transplant surgery",
    "Upper GI surgery",
    "Urology",
    "Vascular surgery",
]


// const physioPracticeAreas = [
//     "Workplace assessment",
//     "Orthopaedics",
//     "Manipulation & mobilisation",
//     "Back care",
//     "Acupuncture",
//     "Sports injuries",
//     "Neurology - adult",
//     "Repetitive strain injury",
//     "Breathing & heart problems",
//     "Hydrotherapy",
//     "Rehabilitation",
//     "Connective tissue manipulation",
//     "On-site commercial",
//     "Electrotherapy",
//     "Pilates classes",
//     "Myofascial release",
//     "Chronic pain",
//     "Children's conditions",
//     "Expert witness",
//     "Other activities",
//     "Joint diseases",
//     "Neurology - children",
//     "Headaches & migraines",
//     "Jaw pain / TMJ disorder",
//     "Aquatic therapy",
//     "Women's health",
//     "McKenzie practitioner",
//     "Reflexology",
//     "Skin conditions",
//     "Alexander technique",
//     "Injection therapy",
//     "Animal therapy",
//     "Avoid treatments"
// ]

// export const allSearchCategories = uniqueElements([...specialisms, ...specialities, ...physioPracticeAreas]).sort()
export const allSearchCategories = unique([...specialisms, ...specialities]).sort()
