import {AnEvent} from '../event-kit'
import {StripeEvent} from '@peachy/stripe-pure'


export class RawStripeEvent extends AnEvent {
    public payload: StripeEvent
    constructor(from: RawStripeEvent) {
        super()
        Object.assign(this, from)
    }
}
