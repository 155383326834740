import styles from './HealthInsuranceSimplyDone.module.css'

// @ts-ignore
import AppScreenshot from '../../../global-style/elements/app-blank.png'

import library from '../../../global-style/library.module.css'
import {Link} from 'react-router-dom'
import OneTwoColumnGrid from '../../../elements/layout/OneTwoColumnGrid/OneTwoColumnGrid'
import React from 'react'

export default function HealthInsuranceSimplyDone() {

    return (
        <OneTwoColumnGrid className={styles.HealthInsuranceSimplyDone}>
            <SimplyDone/>
            <AppOverview/>
            <LocalSpecialists/>
            <VirtualAppointments/>
        </OneTwoColumnGrid>
    )
}


function AppOverview() {
    return (
        <figure className={styles.appScreenshot}>
            <img src={AppScreenshot} alt={'Peachy app screenshot'}/>
        </figure>
    )

}

function SimplyDone() {
    return (
        <article className={styles.simplyDone}>
            <h1>Health Insurance Simply Done</h1>
            <p>
                    <span>Peachy is about simple, digital access to healthcare
                    across the UK. We're here to help with all you need to
                    stay on top of your mind & body.</span>
            </p>
            <Link className={library.button} to={'/register-interest'}>Interested?</Link>
        </article>
    )
}


function VirtualAppointments() {
    return (
        <article className={styles.virtual}>
            <h3>Virtual appointments</h3>
            <p>
                Book an on-demand video call with your GP to get questions answered, specialist referrals, and the
                prescription meds you need, 24/7.
            </p>
            <figure className={styles.virtualGpScreenshot}>
                <figcaption>Virtual GP Screenshot</figcaption>
            </figure>
        </article>
    )
}

function LocalSpecialists() {
    return (
        <article className={styles.local}>
            <h3>Local specialists</h3>
            <p>
                Check out all available providers near you. Filter through professionals to find who you need and
                contact them from the Peachy app.
            </p>
            <figure className={styles.mapScreenshot}>
                <figcaption>Local specialist map</figcaption>
            </figure>
        </article>
    )
}
