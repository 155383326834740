import React, {useEffect} from 'react'
import styles from './BrowseCare.module.css'
import {classList} from '@peachy/utility-kit-pure'
import Filters from './Filters/Filters'
import CareResults from './CareResults/CareResults'
import ResultMap from './ResultMap/ResultMap'
import CareSearchController, {useCareSearchQuery} from '../../../service-stub/CareSearchController'
import useDataThrottle from '../../../hooks/useDataThrottle'

export type BrowseCareProps = {
    className?: string
}


export default function BrowseCare(props: BrowseCareProps) {
    return (
        <CareSearchController>
            <BrowseCareMain {...props}/>
        </CareSearchController>
    )
}



function BrowseCareMain({className}: BrowseCareProps) {

    const rootClasses = classList(styles.BrowseCare, className)

    const [query, _, fireQuery] = useCareSearchQuery()

    const throttledQuery = useDataThrottle(query, 1000)

    useEffect(() => {
        if (query.locus) {
            fireQuery()
        }

    }, [throttledQuery])


    return (
        <main className={rootClasses}>
            <ResultMap/>
            <Filters className={styles.Filters}/>
            <CareResults/>
        </main>
    )
}

