import React from 'react'
import styles from './FilterExpander.module.css'
import baseStyles from '../../BrowseCare.module.css'
import {classList} from '@peachy/utility-kit-pure'

export type FilterExpanderProps = {
    isOpen: boolean
    setIsOpen(isOpen:boolean): void
}

export default function FilterExpander({isOpen, setIsOpen}: FilterExpanderProps) {
    const classes = classList(baseStyles.panel, styles.FilterExpander, isOpen && styles.FilterExpanderOpen)
    return (
        <button onClick={() => setIsOpen(!isOpen)} className={classes}>Toggle filter panel</button>
    )
}
