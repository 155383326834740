import React from 'react'
import {ROOT_DOMAIN} from '../../App/App'


export const PeachyMailHelp = () => <PeachyMail to={'help'}/>
export const PeachyMailDpo = () => <PeachyMail to={'dpo'}/>
export const PeachyMailComplaints = () => <PeachyMail to={'complaints'}/>
export const PeachyMailCompliance = () => <PeachyMail to={'compliance'}/>

function PeachyMail({to}: {to:string}) {
    return <a href={`mailto:${to}@${ROOT_DOMAIN}`}>{to}@{ROOT_DOMAIN}</a>
}

