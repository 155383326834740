import React from 'react'
import Faq from '../../../shared/FaqPanel/Faq'

export default function Changes() {
    return (
        <Faq question={'Changes to this Privacy Policy'}>
            <p>
                We reserve the right to make changes to this Privacy Policy from time to time.
                These changes might be necessary because of changes or developments in data protection laws, privacy best practice or the introduction of new technologies.
                You should check this policy occasionally to ensure you are aware of the most recent Privacy Policy.
            </p>
            <p>
                This policy was last updated on Monday 01st January 2021.
            </p>
        </Faq>
    )
}

