import {defineValidator, MessageProducer} from '../validate-core'
import isUUID from 'validator/es/lib/isUUID'

export default function IsUUID<T>(message?: string | MessageProducer<T>) {
    return defineValidator<T>({
        name: 'IsUUID',
        message:
            message ??
            (({property}) => `Property ${property} must be a valid UUID`),
        validate({value}) {
            return value && isUUID(value)
        },
    })
}
