import {Properties} from '@peachy/utility-kit-pure'
import {AnEvent} from '../../../event-kit'
import {SubscriptionEventData} from '../../interfaces'
import {AccountType} from '../../../../domain/models/Account'


export class CompanyPolicyDocSendRequest extends AnEvent implements SubscriptionEventData {
     constructor(from: Properties<CompanyPolicyDocSendRequest>) {
        super()
        Object.assign(this, from)
    }
    accountId: string
    accountType: AccountType
    subscriptionId: string
}
