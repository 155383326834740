import React, {useState} from 'react'
import styles from './BenefitLimit.module.css'
import {Benefit} from '@peachy/legacy-domain'
import {classList, Draft, first, last} from '@peachy/utility-kit-pure'
import PeachySelector from '../../../../elements/branded-elements/PeachySelector/PeachySelector'

export type BenefitLimitProps = {
    benefitTemplate: Draft<Benefit>
    updateLimit(benefitName: string, limit: number)
    limit?: number;
    hasBenefit: boolean
    className?: string
}

export default function BenefitLimit({benefitTemplate, className, hasBenefit, updateLimit, limit}: BenefitLimitProps) {

    const maxCover = last(benefitTemplate.availableLimits)
    const minCover = first(benefitTemplate.availableLimits)

    const rootClasses = classList(styles.BenefitLimit, !hasBenefit && styles.disabled, className)

    const [showSelector, setShowSelector] = useState(false)

    const adjustLimit = (e, dir: number) => {
        const newLimitIdx = benefitTemplate.availableLimits.indexOf(limit) + dir
        const newLimit = benefitTemplate.availableLimits[newLimitIdx]
        updateLimit(benefitTemplate.name, newLimit)
        e.stopPropagation()
    }


    const selectLimit = (_, limitIndex) => {
        const newLimit = benefitTemplate.availableLimits[limitIndex]
        setShowSelector(false)
        updateLimit(benefitTemplate.name, newLimit)
    }

    const openSelector = (e) => {
        setShowSelector(true)
        e.stopPropagation()
    }



    const displayLimits = benefitTemplate.availableLimits.map(l => formatLimit(l, maxCover))
    const limitIndex = benefitTemplate.availableLimits.indexOf(limit)


    return (

        <fieldset className={rootClasses}>
            <legend className={styles.annualLimitSelect} onClick={openSelector}>Annual Limit</legend>
            <button onClick={(e) => adjustLimit(e, -1)} disabled={limit === minCover}>-</button>
            <input type={'text'} readOnly={true} value={formatLimit(limit, maxCover)}/>
            <button onClick={(e) => adjustLimit(e, 1)}  disabled={limit === maxCover}>+</button>

            {showSelector &&
                <PeachySelector list={displayLimits} selection={limitIndex} selectionIsLocus={true} className={styles.BoSelector} onSelect={selectLimit} onDismiss={() => setShowSelector(false)}/>
            }

        </fieldset>
    )
}


function formatLimit(limit: number, maxCover) {
    if (limit === maxCover) {
        return `${formatter.format(limit)}`
    } else {
        return formatter.format(limit)
    }
}

const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    maximumFractionDigits:0
});
