import {Premium} from '../Premium'
import {QuoteParams} from '@peachy/quote-pure'
import {BenefitType} from '../PlanBenefit'
import {HasLifecycleStatus, LifecycleStatus, TransferDetails} from './LifecycleStatus'


export class MrLifeBenefit implements QuoteParams, HasLifecycleStatus {

    quoteModelVersion?: string
    renewalPeriodMonths?: number

    status: LifecycleStatus
    startDate?: number
    endDate?: number
    timezone?: string

    cancellationReason?:string
    lastRenewalDate?: number
    nextRenewalDate?: number

    transfer?: TransferDetails

    rateSet?: 'INDIVIDUAL' | 'SME' | 'VOLUNTARY'
    groupSize?: number
    moriDate?: number
    effectiveDate?: number
    annualBilling?: boolean
    excess?: number
    benefitSpecificExcess?: boolean

    id: BenefitType

    premium?: Premium
    limit?: number
}
