import React from 'react'
import Faq from '../../../shared/FaqPanel/Faq'

export default function WhatTypes() {

    return (
        <Faq question={'What types of cookie we use'}>
            <p>
                We use 4 types of cookie category, set out below:
            </p>
            <h4>Necessary cookies</h4>
            <p>
                These cookies are essential in allowing you to move around our website and using our online services
                such
                as our get a quote service.
            </p>
            <p>
                These cookies only last for a single browsing session, when you leave our website they will be removed.
            </p>
            <h4>Performance cookies</h4>
            <p>
                These cookies help us monitor what users do on our website and where they have come from, and allow us
                to make improvements. They do things such as collecting information on which pages are visited,
                notifying us if an error message is displayed and understanding what links people click on, including
                blogs.
            </p>
            <p>
                The information collected by these cookies is aggregated, it can't be used to identify individuals.
            </p>
            <h4>Functionality cookies</h4>
            <p>
                These cookies allow our website to remember choices you make, such as information filled in during the
                get a quote journey. Choices you make include sharing your location, changes made to text sizes or
                anything else that can be customised.
            </p>
            <p>
                The information collected by these cookies may be anonymised and they cannot track your activity on other websites.
            </p>
        </Faq>
    )
}
