import {Properties} from '@peachy/utility-kit-pure'
import {AnEvent} from '../../event-kit'
import {AccountType} from '../../../domain/models/Account'
import {SubscriptionEventData} from '../interfaces'


export class SubscriptionActivatedNotification extends AnEvent implements SubscriptionEventData {
    constructor(from: Properties<SubscriptionActivatedNotification>) {
        super()
        Object.assign(this, from)
    }

    accountId: string
    accountType: AccountType
    subscriptionId: string
}
