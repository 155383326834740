import React from 'react'
import Faq from '../../../shared/FaqPanel/Faq'
import {PeachyMailDpo} from '../../../../elements/branded-elements/PeachyMail'

export default function OurDPO() {
    return (


        <Faq question={'Our Data Protection Officer'}>

            <p>
                We have appointed a Data Protection Officer, who oversees how we handle your personal information.
                If you have any questions about our Privacy Policy or how we store your personal information, please
                contact us
                at <PeachyMailDpo/>
            </p>
        </Faq>
    )
}

