import styles from './Footer.module.css'
import PeachyLogo from '../Logo/PeachyLogo'
import React from 'react'
import SocialLinks from './SocialLinks'
import {useNavigate} from 'react-router-dom'
import {useLocation} from 'react-router'
import {classList} from '@peachy/utility-kit-pure'
import useScreenMetrics from '../hooks/useScreenMetrics'
import {ROOT_DOMAIN} from '../App/App'


function PrimaryFooterLinks() {
    return (
        <nav className={styles.PrimaryLinks}>
            <a href={'/how-it-works'}>How it works</a>
            <a href={'/for-business'}>For business</a>
            <a href={'https://intercom-help.eu/peachy/en'}>FAQs</a>
            <a href={'/about-us'}>About us</a>
            <a href={'/contact-us'}>Contact</a>
            {/*<Link to={'/how-it-works'}>How it works</Link>*/}
            {/*<Link to={'/for-business'}>For business</Link>*/}
            {/*<Link to={'/faqs'}>FAQs</Link>*/}
            {/*<Link to={'/about-us'}>About us</Link>*/}
            {/*<Link to={'/contact-us'}>Contact</Link>*/}
        </nav>
    )
}





function SecondaryFooterLinks() {
    return (
        <nav className={styles.SecondaryLinks}>
            <a href={`https://www.${ROOT_DOMAIN}/legal`}>Terms & conditions</a>
            <a href={`https://www.${ROOT_DOMAIN}/privacy-policy`}>Privacy policy</a>
            <a href={`https://www.${ROOT_DOMAIN}/cookie-policy`}>Cookie policy</a>
        </nav>
    )
}

function SecondaryFooterLinksForGetCare() {
    const classes = classList(styles.SecondaryLinks, styles.SecondaryLinksForGetCare)

    const isMobile = useScreenMetrics().width < 800

    return (
        <nav className={classes}>
            <a href={`https://www.${ROOT_DOMAIN}/legal`}>{isMobile ? 'T&Cs' : 'Terms & conditions'}</a>
            <a href={`https://www.${ROOT_DOMAIN}/privacy-policy`}>{isMobile ? 'Privacy' : 'Privacy policy'}</a>
            <a href={`https://www.${ROOT_DOMAIN}/cookie-policy`}>{isMobile ? 'Cookies' : 'Cookie policy'}</a>
            <a href={`https://www.${ROOT_DOMAIN}/contact`}>Contact</a>
        </nav>
    )
}

export default function Footer() {

    const navigate = useNavigate()

    // bag o frogs
    const isGetCare = useLocation().pathname === '/get-care'

    const footerClasses = classList(styles.Footer, isGetCare && styles.FooterForGetCare)
    const socialClasses = classList(styles.SocialLinks, isGetCare && styles.SocialLinksForGetCare)
    const isMobile = useScreenMetrics().width < 800

    return isGetCare
        ? (<footer className={footerClasses}>
            <PeachyLogo className={styles.logo} onClick={() => {navigate('/')}}/>
            <SocialLinks className={socialClasses}/>
            <SecondaryFooterLinksForGetCare/>
        </footer>) : (
            <footer className={footerClasses}>
                <PeachyLogo className={styles.logo} onClick={() => {navigate('/')}}/>
                <SocialLinks className={socialClasses}/>
                <PrimaryFooterLinks/>
                <SecondaryFooterLinks/>
            </footer>
        )

}
