import {LifeBenefit} from './LifeBenefit'
import {Address} from './Address/Address'
import {enumerate, keysOf} from '@peachy/utility-kit-pure'
import * as dates from 'date-fns'
import Required from '../validation/validators/Required'
import IsEmail from '../validation/validators/IsEmail'
import {Type} from 'class-transformer'
import IsIn from '../validation/validators/IsIn'
import Optional from '../validation/validators/Optional'
import {LegacyIdCheckStatus, LegacyLivenessCheckStatus, LegacySanctionsCheckStatus} from './Account'

export type LifeType =
    | 'PRIMARY'
    | 'SECONDARY'
    | 'DEPENDANT'

export const LifeTypes = keysOf<LifeType>()


export const GenderTypeArray = [
    'MALE',
    'FEMALE',
    'BLENDED',
] as const


export const GenderTypes = enumerate(GenderTypeArray)

export type GenderType = keyof typeof GenderTypes


export class Life {
    id: string
    cognitoUserId?: string
    intercomUserId?: string

    @Required()
    type: LifeType

    @Required()
    firstname: string

    @Required()
    lastname: string

    @Required()
    dateOfBirth: number

    @IsIn(GenderTypeArray)
    @Optional()
    gender?: GenderType

    @IsEmail()
    email?: string

    phone?: string
    @Type(() => Address)
    @Required()
    address: Address

    @Required()
    postcode: string

    planId: string

    benefits: LifeBenefit[]
    totalMonthlyPremium: number

    smsMarketingAccepted?: boolean
    marketingAccepted?: boolean

    idVerificationStatus?: LegacyIdCheckStatus = 'DRAFT'
    sanctionsCheckStatus?: LegacySanctionsCheckStatus = 'DRAFT'
    livenessCheckStatus?: LegacyLivenessCheckStatus = 'DRAFT'

    startDate: number
    endDate?: number

    fullName?() {
        return `${this.firstname} ${this.lastname}`
    }

    age?() {
        let age = undefined
        if (this.dateOfBirth) {
            const date = new Date(this.dateOfBirth)
            if (date) {
                age = dates.differenceInYears(Date.now(), this.dateOfBirth)
            }
        }
        return age
    }

    ageAt?(aDate: Date) {
        let ageAt = undefined
        if (this.dateOfBirth) {
            ageAt = dates.differenceInYears(aDate, this.dateOfBirth)
        }
        return ageAt
    }
}
