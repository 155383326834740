import React from 'react'
import Faq from '../../../shared/FaqPanel/Faq'

export default function Viruses() {

    return (
        <Faq question={'Viruses'}>
            <p>
                We make reasonable efforts to try and ensure our website and any materials available for download from
                our website are free from infection, bugs, viruses and/or harmful code, however we do not guarantee that
                it will be so. You are responsible for configuring your products and how you access our site, and we
                recommend using your own virus protection software.
            </p>
            <p>
                We are not liable for any loss or damage caused by a distributed denial-of-service attack, viruses or
                other technologically harmful material that may infect your products, programs, data or other
                proprietary material due to your use of our website or downloading of any material, or use of any
                website linked from our website.
            </p>
            <p>
                You must not misuse our website by knowingly introducing viruses, trojans, worms, logic bombs or other
                material that is malicious or technologically harmful. You must not attempt to gain unauthorised access
                to our website, the server on which our website is stored or any server, computer or database connected
                to our website. You must not attack our website including via a denial-of-service attack or a
                distributed denial-of-service attack. By doing this, you would be committing a criminal offence under the
                Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities and
                we will co-operate with those authorities by disclosing your identity to them. In the event of such a
                breach, your right to use our website will cease immediately.
            </p>
        </Faq>
    )
}
