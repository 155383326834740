import {useEffect, useState} from 'react'

import {Loader, LoaderOptions} from '@googlemaps/js-api-loader'

const options: LoaderOptions = {
    apiKey: 'AIzaSyBrkbEcg70JzwOiDgNp-BqhAkdIOefeAu4',
    libraries: ['places'],
    region: 'GB'
}

const googleMapApiLoader = new Loader(options)

export default function useEnsureGoogleMaps() {

    const [state, setState] = useState('start')

    useEffect(() => {
        if (state == 'start') {
            googleMapApiLoader.load()
                .then(() => setState('loaded'))
                .catch(() => setState('failed'))
        }

    }, [state])

    return state
}


