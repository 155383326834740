import styles from './ClaimInAFewTaps.module.css'

export default function ClaimInAFewTaps() {
    return (
        <section className={styles.ClaimInAFewTaps}>
            <article>
                <h2>Claim in a few taps</h2>
                <p>When getting cover is lighting fast, making claims should be too.</p>
            </article>
            <Steps/>
        </section>
    )
}



function Steps() {
    return (
        <ol className={styles.Steps}>
            <li>
                <h3>Open Peachy</h3>
                <p>In the app, tap Make Claim to get started.</p>
            </li>
            <li>
                <h3>Tell us what's up</h3>
                <p>Message our smart concierge to let us know what happened.</p>
            </li>
            <li>
                <h3>Get care, snap and claim</h3>
                <p>If you need hospital care, let us know. We pay you as soon as you're treated so you can take care of the bill fast.</p>
            </li>
        </ol>
    )
}
