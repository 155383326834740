import {UkRegion} from './uk-regions'
import {GeoLocation} from './GeoLocation'


export class Address {
    display: string
    building: string[]
    settlement: string[]
    county?: string
    country: string
    region: UkRegion
    postcode: string
    location?: GeoLocation
}

export type AddressMatch = {
    id: string
    address: string,
}

export type MatchResponse = {
    suggestions: AddressMatch[]
}
