import React from 'react'
import Faq from '../../../shared/FaqPanel/Faq'

export default function WhenDelete() {
    return (

        <Faq question={'When do we delete your information'}>
            <p>
                We keep your information as long as is necessary in line with the reasons why we collected the
                information,
                which includes satisfying any legal, accounting or reporting requirements.
            </p>
            <p>
                In some cases we may anonymise your personal information so that it can no longer be associated with
                you,
                in which case we can use this information without notifying you.
            </p>
            <p>
                To determine the appropriate amount of time to keep your information, we take into account the
                following:
            </p>
            <ul>
                <li>The amount, nature and sensitivity of the information</li>
                <li>The potential risk of harm from unauthorised use or disclosure of your information</li>
                <li>The reasons for collecting and processing your information and whether we can achieve these reasons
                    through other means
                </li>
                <li>Any applicable legal requirements</li>
            </ul>
            <p>
                Under certain circumstances we may need to keep your information for longer,
                this includes when we have a legal obligation to, to defend or manage legal claims,
                and if we suspect, detect or investigate fraud or money laundering.
            </p>
        </Faq>

    )
}

