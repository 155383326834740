import React from 'react'
import styles from './BenefitCard.module.css'
import BenefitCard from './BenefitCard'
import {RemoteCareBenefit} from '@peachy/legacy-domain'

const benefitTemplate = RemoteCareBenefit()

export default function RemoteCareCard() {

    return (
        <BenefitCard header={'Virtual GP'} className={styles.remoteCare} canDeselect={false} benefitTemplate={benefitTemplate}>
            <p>Unlimited 24/7 access to a GP via video or telephone through the Peachy app to get questions answered,
                specialist referrals, and the prescription meds you need</p>
        </BenefitCard>
    )
}

