import React from 'react'
import styles from './Spinner.module.css'
import Modal from '../Modal/Modal'
import {clampNumber, NOOP} from '@peachy/utility-kit-pure'
import {DotPulse} from '@uiball/loaders'
import useScreenMetrics from '../../hooks/useScreenMetrics'

export type SpinnerProps = {
    className?: string
}

export default function Spinner() {
    const screen = useScreenMetrics()

    const spinnerSize = clampNumber(screen.width/4, 100, 300)

    return (
        <Modal className={styles.Modal} onDismiss={NOOP}>
            <aside className={styles.Spinner}>
                <DotPulse
                    size={spinnerSize}
                    speed={0.8}
                    color="#FF5B65AA"
                />
            </aside>
            <button/>
        </Modal>
    )
}
