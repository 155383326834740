import {IdCheckEvent} from './IdCheckEvent'
import {Props} from '@peachy/utility-kit-pure'

export class IdCheckPassed extends IdCheckEvent {

    constructor(from: Props<IdCheckPassed>) {
        super(from)
        this.type = this.constructor.name
    }
}
