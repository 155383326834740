import React, {ReactNode, useState} from 'react'
import styles from './Exclusion.module.css'
import {classList} from '@peachy/utility-kit-pure'
import Expander from '../../../../elements/Expander/Expander'

export type ExclusionProps = {
    name: string,
    children: ReactNode
}

export default function Exclusion({name, children}: ExclusionProps) {

    const rootClasses = classList(styles.Exclusion)

    const [isExpanded, setIsExpanded] = useState(false)

    return (
        <li className={rootClasses}>
            <h6 className={isExpanded ? styles.expanded : undefined} onClick={() => setIsExpanded(e => !e)}>{name}</h6>
            <Expander expanded={isExpanded} tag={'article'}>
                {children}
            </Expander>
        </li>
    )
}
