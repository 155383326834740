import {Props} from '@peachy/utility-kit-pure'
import {SanctionsCheckEvent} from './SanctionsCheckEvent'

export class SanctionsCheckFailed extends SanctionsCheckEvent {

    constructor(from: Props<SanctionsCheckFailed>) {
        super(from)
        this.status = 'FAILED'
        this.type = this.constructor.name
    }
}