import useDataBuffer from './useDataBuffer'
import {useEffect, useRef} from 'react'


export default function useDataThrottle(data: any, maxRate: number, bufferSize = 2) {

    const [currentData, advanceBuffer, buffer] = useDataBuffer(data, bufferSize)

    const timeoutRef = useRef<number>()
    const lastUpdatedRef = useRef<number>(0)

    useEffect(() => {
        if (buffer.length > 1 && !timeoutRef.current) {

            const waitTime = Math.max((lastUpdatedRef.current + maxRate) - Date.now(), 0)

            // @ts-ignore
            timeoutRef.current = setTimeout(() => {
                timeoutRef.current = null
                lastUpdatedRef.current = Date.now()
                advanceBuffer()
            }, waitTime)
        }

    }, [buffer])

    return currentData
}
