import {Readable} from 'node:stream'

async function readStream(stream: Readable): Promise<Uint8Array[]> {
    return new Promise((resolve, reject) => {
        const chunks: Uint8Array[] = []
        stream.on('data', (chunk) => chunks.push(chunk))
        stream.on('error', reject)
        stream.on('end', () => resolve(chunks))
    })
}

export async function streamToBuffer(stream: Readable): Promise<Buffer> {
    const chunks = await readStream(stream)
    return Buffer.concat(chunks)
}

export async function streamToString(stream: Readable): Promise<string> {
    const chunks = await readStream(stream)
    return Buffer.concat(chunks).toString('utf8')
}
