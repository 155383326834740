
export function modalLinkMarkdown(modalId: string, linkText?: string) {
    return markdownTemplate(linkText || modalId, modalId, 'modal')
}

export function browserLinkMarkdown(url: string, linkText?: string) {
    return markdownTemplate(linkText || url, url, 'url')
}

export function phoneLinkMarkdown(phoneNumber: string, linkText?: string) {
    return markdownTemplate(linkText || phoneNumber, phoneNumber,'tel')
}

export function emailLinkMarkdown(email: string, linkText?: string) {
    return markdownTemplate(linkText || email, email,'mailto')
}

export function chatLinkMarkdown(linkText: string, prefillText = '_none_') {
    return markdownTemplate(linkText,prefillText,'chat')
}

const markdownTemplate = (key: string,value: string, templateText: string) => {
    return `[${key}](${templateText}:${value})`
}
