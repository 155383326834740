import {Dictionary} from '@peachy/utility-kit-pure'
import {Class} from 'type-fest'

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'ALL'

export type HttpAction<M extends HttpMethod, RQ, RS, G extends string> = {
    method: M,
    requestType: Class<RQ>
    responseType: Class<RS>
    groups: readonly G[]
}

export type ApiActions<G extends string> = {
    [k: string]: HttpAction<any, any, any, G>
}


export type ApiDef<G extends string, A extends ApiActions<G>> = {
    apiName: string,
    groups: readonly G[]
    actions: A
}

export const ApiMetadataKey: unique symbol = Symbol('Api metadata')

export type Api<T extends ApiDef<any, any>> = T extends ApiDef<infer _G, infer _A> ? {
    [a in keyof T['actions']]: T['actions'][a] extends HttpAction<infer _M, infer RQ, infer RS, infer _G>
        ? (request: RQ, headers?: Dictionary<string>) => Promise<RS>
        : never
} : never


export type ApiMetadata<A extends string> = {
    routes: ApiRoutes<A>
}

export type ApiRoutes<A extends string> = {
    [_ in A]: string // some http endpoint
}



export function defineApi(apiName:string) {
    return {
        forGroups<G extends string>(groups: readonly G[]) {
            return {
                withActions<A extends ApiActions<G>>(actions: A): ApiDef<G, A> {
                    return {
                        apiName,
                        groups,
                        actions
                    }
                }
            }
        },
        withActions<A extends ApiActions<undefined>>(actions: A): ApiDef<undefined, A> {
            return {
                apiName,
                groups: [],
                actions
            }
        }
    }
}


export function postOf<RQ>(request: Class<RQ>) {
    return requestOf('POST', request)
}

export function putOf<RQ>(request: Class<RQ>) {
    return requestOf('PUT', request)
}

export function getOf<RQ>(request: Class<RQ>) {
    return requestOf('GET', request)
}

class NoParams {
}

export function getOfNoParams() {
    return getOf(NoParams)
}

function requestOf<RQ, M extends HttpMethod>(method: M, request: Class<RQ>) {
    return {
        fromGroups<G extends string>(...groups: readonly G[]) {
            return {
                willReturn<RS>(response: Class<RS>): HttpAction<M, RQ, RS, G> {
                    return {
                        method,
                        groups,
                        requestType: request,
                        responseType: response
                    }
                }
            }
        },
        fromAnyone() {
            return {
                willReturn<RS>(response: Class<RS>): HttpAction<M, RQ, RS, undefined> {
                    return {
                        method,
                        groups: [],
                        requestType: request,
                        responseType: response
                    }
                }
            }
        },
    }
}
