import {CloudZoneConfig} from './config-types/CloudZoneConfig'
import {WebZoneConfig} from './config-types/WebZoneConfig'
import {ZoneInfo} from './config-types/ZoneInfo'
import cloudConfig from './dynamic/CloudZoneConfig.json'
import webConfig from './dynamic/WebZoneConfig.json'
import zoneInfo from './dynamic/ZoneInfo.json'

export type Zone = {
    name: string,
    account: string,
}


export function getCloudZoneConfig(): CloudZoneConfig {
    return cloudConfig as CloudZoneConfig
}

export function getWebZoneConfig(): WebZoneConfig {
    return webConfig as WebZoneConfig
}


export function getZoneInfo(): ZoneInfo {
    return zoneInfo as ZoneInfo
}