import FaqPanel from '../../shared/FaqPanel/FaqPanel'
import styles from '../Policies.module.css'
import React from 'react'
import Availability from './questions/Availability'
import Content from './questions/Content'
import Permission from './questions/Permission'
import Linking from './questions/Linking'
import Viruses from './questions/Viruses'
import Cookies from './questions/Cookies'
import Privacy from './questions/Privacy'
import Changes from './questions/Changes'
import Governance from './questions/Governance'
import {ROOT_DOMAIN} from '../../../App/App'

export default function TermsOfUse() {
    return (
        <FaqPanel heading={'Website terms of use'} blurb={<Blurb/>} className={styles.faqPanel}>
            <Availability/>
            <Content/>
            <Permission/>
            <Linking/>
            <Viruses/>
            <Cookies/>
            <Privacy/>
            <Changes/>
            <Governance/>
        </FaqPanel>
    )
}


function Blurb() {
    return (
        <article className={styles.blurb}>
            <p>
                These Terms of Use, Privacy Policy and Cookie Policy (together referred to as 'Terms') governs your use
                of our website {ROOT_DOMAIN} and all subdomains.
                If you continue to browse and use our website, you confirm that you accept these Terms and agree to
                comply with them. If you do not agree to these Terms, please do not use our website.
            </p>
            <p>
                Within these Terms of Use, the terms 'Peachy', 'we',' 'us' and 'our' refer to the owner of this website
                who's registered office is [address]. Our company registration number is [number].
                The terms 'you', 'your' and 'yourself' refer to anyone that browses and uses our website.
            </p>
            <p>
                Our website is controlled and operated from the United Kingdom, and all information delivered via our
                website is directed solely at people accessing our website from the United Kingdom.
            </p>
        </article>
    )
}
