import 'reflect-metadata'

export * from "./src/location/Address"
export * from "./src/location/GeoLocation"
export * from "./src/location/AddressGoogle"
export * from "./src/location/uk-county-region"
export * from "./src/location/uk-regions"
export * from "./src/people"
export * from "./src/plans"
export * from "./src/quote"
export * from "./src/policy"
export * from "./src/validate/validate-core"
export * from "./src/validate/validation-kit"
export * from "./src/model-kit/quote-model-kit"
export * from "./src/CardDetails"
export * from "./src/legacy/legacy-quote"
export * from "./src/legacy/legacy-quote-kit"

export * from './src/market/Consultant'


export * from './src/new-domain-mappers/mapOldQuoteToNewPolicy'

        import * as me from '@peachy/legacy-domain'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported)) {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/Users/dalph/DalphSpace/peachy-home/repos/peachy-mono-repo/comp-kit/legacy-domain'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@peachy/legacy-domain'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    