import {Draft, formatIsoDateTimeOnly, newUUID} from '@peachy/utility-kit-pure'
import {Quote} from '../quote'
import {Benefit, BenefitTypes, Plan} from '../plans'


export function createBlankQuote(): Draft<Quote> {
    return {
        request: {
            id: newUUID(),
            startDate: formatIsoDateTimeOnly(new Date()),
            lives: [],
            plans: [createDefaultPlan() as Plan]
        },
        response: null
    }
}

export function createDefaultPlan(): Draft<Plan> {

    const plan = {
        id: newUUID(),
        benefits: [
            // {...HospitalCareBenefit(), limit: 10000},
            {...ConsultationsDiagnosticsBenefit(), limit: 2500},
            // {...TherapiesBenefit()},
            {...RemoteCareBenefit()},
            {...MentalHealthBenefit()},
            // {...DentalOpticalBenefit()},
        ],
    }

    return plan
}


export const HospitalCareBenefit = (): Draft<Benefit> => ({
    name: BenefitTypes.HOSPITAL_CARE,
    displayName: 'Hospital Care',
    availableLimits: [
        10000,
        20000,
        30000,
        40000,
        50000,
        60000,
        70000,
        80000,
        90000,
        100000,
        110000,
        120000,
        130000,
        140000,
        150000,
        160000,
        170000,
        180000,
        190000,
        200000,
        210000,
        220000,
        230000,
        240000,
        250000,
        260000,
        270000,
        280000,
        290000,
        300000,
        310000,
        320000,
        330000,
        340000,
        350000,
    ]
})

export const ConsultationsDiagnosticsBenefit = (): Draft<Benefit> => ({
    name: BenefitTypes.CONSULTATIONS_DIAGNOSTICS,
    displayName: 'Consultations & Diagnostics',
    availableLimits: [
        500,
        750,
        1000,
        1250,
        1500,
        1750,
        2000,
        2250,
        2500,
        3000,
        3500,
        4000,
        4500,
        5000,
        10000,
        15000,
        20000,
        25000,
        30000,
        35000,
        40000,
        45000,
        50000,
        55000,
        60000,
        65000,
        70000,
        75000,
        80000,
        85000,
        90000,
        95000,
        100000
    ]
})

export const TherapiesBenefit = (): Draft<Benefit> => ({
    name: BenefitTypes.THERAPIES,
    displayName: 'Therapies',
    availableLimits: [2500]
})

export const MentalHealthBenefit = (): Draft<Benefit> => ({
    name: BenefitTypes.MENTAL_HEALTH,
    displayName: 'Mental Health',
    availableLimits: undefined,
})

export const RemoteCareBenefit = (): Draft<Benefit> => ({
    name: BenefitTypes.REMOTE_CARE,
    displayName: 'Remote Care',
    availableLimits: undefined
})

export const DentalOpticalBenefit = (): Draft<Benefit> => ({
    name: BenefitTypes.DENTAL_OPTICAL,
    displayName: 'Dental & Optical',
    availableLimits: undefined
})
