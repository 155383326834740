import React, {ReactNode} from 'react'
import styles from './PageHeading.module.css'
import {classList} from '@peachy/utility-kit-pure'

export type PageHeadingProps = {
    className?: string
    children: ReactNode
}

export default function PageHeading({className, children}: PageHeadingProps) {

    const classes = classList(styles.PageHeading, className)

    return (
        <section onScroll={() => console.log('scrollin')} className={classes}>
            {children}
        </section>
    )
}
