import {AnEvent} from '../../event-kit'
import {Props} from '@peachy/utility-kit-pure'

export class IndividualSanctionsCheckRequest extends AnEvent {
    constructor(from: Props<IndividualSanctionsCheckRequest>) {
        super()
        Object.assign(this, from)
    }
    firstname: string
    lastname: string
    birthdate: string
    cognitoId: string
    email?: string
}
