import React from 'react'
import styles from './PeachyChoice.module.css'
import {classList} from '@peachy/utility-kit-pure'
import Choice, {ChoiceProps} from '../../Choice/Choice'

export default function PeachyChoice({className, children, ...otherProps}: ChoiceProps) {
    const rootClasses = classList(styles.PeachyChoice, className)
    return (
        <Choice className={rootClasses} {...otherProps}>
            {children}
        </Choice>
    )
}
