import React from 'react'
import Faq from '../../../shared/FaqPanel/Faq'
import FaqLink from '../../../shared/FaqPanel/FaqLink'

export default function HowWeCollect() {

    return (
        <Faq question={'How we collect information'}>
            <p>
                We collect information from you and third parties for all reasons outlined in
                <FaqLink link={'What we use your information for'}/> using the following methods:
            </p>
            <h4>Web analytics and cookies</h4>
            <p>
                When you use our website we automatically send cookies to your computer from our website,
                and we also use tracking software such as Google Analytics.
            </p>
            <p>
                We use these cookies to improve the way our website operates and for system administration purposes.
            </p>
            <p>
                We use tracking software to build a profile of our users and enables us to optimise our website design.
                Some of this data is statistical, which means we will not be able to identify you individually.
            </p>
            <p>
                For more information on cookies and tracking software please see our Cookie Policy.
            </p>
            <h4>Monitoring and recording conversations</h4>
            <p>
                We may monitor and record communications with you (via telephone, email and support chat) for quality
                and training purposes,
                as well as to help us improve the products and services we provide.
            </p>
            <h4>From yourself</h4>
            <p>
                We collect information through all your contact with us, including by phone, email, through the
                website,
                through our app, by filling out a quote, through social media, by entering competitions and by post.
            </p>
            <h4>From other people or organisations</h4>
            <p>
                We will collect information from a parent or guardian if you are under 18, or from the plan holder if
                you are a spouse.
                We will also collect information related to healthcare from doctors, clinicians, hospitals,
                clinics and any other health-care professionals or providers.
            </p>
            <p>
                We will collect information from service providers that work with us in relation to your product or
                service that is not provided directly by us,
                for example the virtual GP, medical and dental treatment and virtual physio.
            </p>
            <p>
                We may collect information from fraud-detection and credit-reference agencies,
                or other sources available to the public such as the edited electoral register, social media and
                internet searches.
            </p>
            <p>
                For more information on fraud please see <FaqLink link={'Fraud prevention and detection'}/>
            </p>
        </Faq>
    )
}
