import {AnEvent} from '../event-kit'

export class CancelLifeRequest extends AnEvent {
    public accountId: string
    public subscriptionId: string
    public policyId: string
    public lifeId: string
    public effectiveDate?: number
    public cancellationReason?: string

    constructor(from: CancelLifeRequest) {
        super()
        Object.assign(this, from)
    }
}
