import React from 'react'
import styles from './SeeAnyone.module.css'
import Photo from '../../shared/Photo/Photo'
import PicturePanel from '../../shared/PicturePanel/PicturePanel'

export default function SeeAnyone() {

    return (

        <PicturePanel className={styles.SeeAnyone}>
            <article>
                <h2>See anyone about your health</h2>
                <p>
                    We're not here to judge who you can and can't
                    visit under your plan. See any accredited provider
                    and we'll cover the fees up to your annual limit.
                </p>
                {/*<Link className={library.button} to={'/how-it-works'}>Learn more</Link>*/}
            </article>
            <Photo caption={'mad professor'} className={styles.Photo}/>
        </PicturePanel>
    )
}
