import styles from './PlansGrow.module.css'
import PicturePanel from '../../shared/PicturePanel/PicturePanel'
import Photo from '../../shared/Photo/Photo'
import React from 'react'

export default function PlansGrow() {
    return (
        <PicturePanel className={styles.PlansGrow}>
            <Photo caption={'Team photo board'}/>
            <article>
                <h3>Our plans grow with you</h3>
                <p>As your business gets bigger, add new team members to your plan without any hassle.</p>
                <h3>Stable body, sound mind</h3>
                <p>Treating our minds as well as we treat our bodies is essential for holistic health. Peachy takes care of your team's mental health, too.</p>
            </article>
        </PicturePanel>
    )
}
