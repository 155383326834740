import {Auth} from '@aws-amplify/auth'
import {DocGenApi, DocGenApiDefinition} from '@peachy/doc-gen-pure'

import {getApiMetadata, makeApiClient} from '@peachy/core-domain-client'
import {Signer} from '@aws-amplify/core'

export type DocGenService = DocGenApi & {
    getPdfLink: (ticketId: string, asFile: string) => string
}


export async function createDocGenService(servicePatchUri: string): Promise<DocGenService> {
    const api: DocGenApi = await makeApiClient(DocGenApiDefinition, servicePatchUri, Signer, Auth)

    return {
        ...api,
        getPdfLink(ticketId: string, asFile: string) {
            const routes = getApiMetadata(api).routes
            return `${routes.renderPdfTicket}/${asFile}?ticketId=${ticketId}`
        }
    }
}
