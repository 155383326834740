import React from 'react'
import Faq from '../../../shared/FaqPanel/Faq'

export default function HowToManage() {

    return (
        <Faq question={'How to manage cookies'}>
            <p>
                You can manage cookie settings (including deleting and switching off) or opt out of cookies using your
                browser settings. Please remember that if you switch off cookies, you may not be able to use all of the
                services on our website.
            </p>
        </Faq>
    )
}
