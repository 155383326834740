import React from 'react'
import styles from './Distance.module.css'
import {formatDistance} from '../../../../../../Map/MapKit'
import {classList} from '@peachy/utility-kit-pure'


export type DistanceProps = {
    className?: string,
    meters: number
}


export default function Distance({meters, className}: DistanceProps) {
    const classes = classList(className, styles.distance)
    return (
        <span className={classes}>{formatDistance(meters)}</span>
    )
}
