import {Address, GeoLocation} from '@peachy/core-domain-pure'
import {createComputed, createEffect, createResource, createSignal} from 'solid-js'
import {useGeoLocationServiceClient} from '../../services/geo-location/GeoLocationServiceController'
import {TextBox} from '../TextBox/TextBox'
import {ListSelectorAppearance} from '../ListSelector/ListSelector'
import {createThrottledSignal} from '../../hooks/createThrottledSignal'
import {classList} from '@peachy/utility-kit-pure'
import {PEACHY_HQ} from '../../../index'
import styles from './AddressLookup.module.css'
import {AddressMatch} from '@peachy/geo-location-pure'
import {PopupListSelector} from '../PopupListSelector/PopupListSelector'


export type AddressLookupProps = {
    residentialOnly?: boolean
    initialSearchTerm?: string,
    searchLocus?: GeoLocation
    autoFocus?: boolean,
    onSelectAddress?(address: Address): void
    onFieldChange?(value: string): void
    onBlur?: () => void
    onFocus?: () => void
    onClick?: () => void
    resetStateOnClearInput?: boolean
    readonly ?: boolean
    selectAllOnFocus ?: boolean
    showLoadingSpinner ?: boolean
    onLoading ?: (loading: boolean) => void
    appearance: AddressLookupAppearance
}

const maxLookupRateMs = 1000

const minSearchContent = 5


export type AddressLookupAppearance = ListSelectorAppearance & {
    lookupField?: string
}


export function AddressLookup(props: AddressLookupProps) {

    let requestId = 0

    const geoLocationServiceClient = useGeoLocationServiceClient()
    const [showSelector, setShowSelector] = createSignal(false)
    const [selectedMatch, setSelectedMatch] = createSignal<AddressMatch>()
    const [enteredSearchTerm, setEnteredSearchTerm] = createSignal(props.initialSearchTerm ?? '')
    const [throttledSearchTerm, pushSearchTerm] = createThrottledSignal(props.initialSearchTerm ?? '', maxLookupRateMs)

    const [addressMatches] = createResource(throttledSearchTerm, async (searchTerm): Promise<AddressMatch[]> => {
        if (searchTerm !== selectedMatch()?.address && searchTerm !== props.initialSearchTerm && hasEnoughSearchContent(searchTerm)) {

            const response = await geoLocationServiceClient.lookupAddress({
                searchTerm,
                requestId: `${++requestId}`,
                location: props.searchLocus ?? PEACHY_HQ,
            })

            if (+response.requestId === requestId) {
                setShowSelector(!!response.matches?.length)
                return response.matches
            } else {
                return addressMatches()
            }

        } else {
            return []
        }
    })

    const [selectedAddress, selectedAddressActions] = createResource(selectedMatch, async (selectedMatch) => {
        let selectedAddress: Address = null
        if (selectedMatch) {
            selectedAddress = await geoLocationServiceClient.fetchAddress({ addressId: selectedMatch.id })
            setEnteredSearchTerm(selectedAddress.display)
            props.onSelectAddress?.(selectedAddress)
        }
        return selectedAddress
    })

    const hasMatches = () => !!addressMatches()?.length
    const displayMatches = () => addressMatches()?.map(p => p.address) ?? []
    const isLoading = () => addressMatches.loading || selectedAddress.loading

    createComputed(() => {
        pushSearchTerm(enteredSearchTerm())
    })

    createEffect(() => {
        props?.onLoading?.(isLoading())
    })

    const onSelectMatch = async (match: string, index: number) => {
        if (match !== enteredSearchTerm()) {
            setEnteredSearchTerm(match)
            setSelectedMatch(addressMatches()[index])
        }
        setShowSelector(false)
    }

    const inputClassList = () => classList(styles.AddressLookup, props.appearance?.lookupField)

    const onBlur = (e: Event) => {

        if (props.resetStateOnClearInput) {
            const element = e.currentTarget as HTMLInputElement
            if (!element.value) {
                setSelectedMatch(undefined)
                selectedAddressActions.mutate(undefined)
            }
        }

        if (selectedAddress() && !geoLocationServiceClient.isFetching()) {
            setEnteredSearchTerm(selectedAddress().display)
        } else if (enteredSearchTerm() && props.initialSearchTerm) {
            setEnteredSearchTerm(props.initialSearchTerm)
        }

        props.onBlur?.()
    }


    const onFocus = (e: Event) => {
        const element = e.currentTarget as HTMLInputElement
        element.select()
        props.onFocus?.()
    }

    const onChange = (text: string) => {
        setEnteredSearchTerm(text)
        props.onFieldChange?.(text)
    }

    let textBoxRef: HTMLElement

    return <>
        <TextBox
            ref={textBoxRef}
            placeholder={'Address'} value={enteredSearchTerm()} onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            class={inputClassList()}
            readonly={props.readonly}
            selectAllOnFocus={props.selectAllOnFocus}
            spin={props?.showLoadingSpinner && isLoading()}
        />


        <PopupListSelector
            list={displayMatches()}
            enabled={showSelector() && hasMatches()}
            selection={selectedMatch()?.address}
            onDismiss={() => setShowSelector(false)}
            onSelect={onSelectMatch}
            appearance={{
                ...props.appearance,
            }}
            locatorElement={textBoxRef}
            center={false}
        />
    </>
}

function hasEnoughSearchContent(searchTerm: string) {
    return searchTerm.replace(/\s/g, '').length >= minSearchContent
}
