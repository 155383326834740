import {defineApiGateway} from '@peachy/core-domain-pure'

import {Coupon, Promo} from '@peachy/payments-pure'
import {PaymentIntent, PeachyPaymentCard} from './domain'

export const PaymentApiGatewayDefinition = defineApiGateway('PaymentsApi').withRoutes({
    createIntent: {
        method: 'POST',
        path: '/createIntent',
        isPublic: true,
        responseType: PaymentIntent
    },
    abandonIntent: {
        method: 'POST',
        path: '/abandonIntent',
        isPublic: true,
        requestType: class {intentId: string},
        responseType: PaymentIntent
    },
    requestApiKey: {
        method: 'GET',
        path: '/requestApiKey',
        isPublic: true,
        responseType: class {key: string}
    },

    customerCards: {
        method: 'GET',
        path: '/customer/cards',
        isPublic: false,
        responseType: Array<PeachyPaymentCard>
    },
    companyCards: {
        method: 'GET',
        path: '/customer/company/cards',
        isPublic: false,
        responseType: Array<PeachyPaymentCard>
    },
    getCoupon: {
        method: 'GET',
        path: '/coupon/{id}',
        isPublic: true,
        responseType: Coupon
    },
    getPromo: {
        method: 'GET',
        path: '/promo/{code}',
        isPublic: true,
        responseType: Promo
    },
})
