import React, {ReactNode, useContext, useEffect, useRef, useState} from 'react'
import mapStyle from './themes/map-peachy-config.js'
import {GeoLocation, getOffsetCenter, getZoomForRange, Vector} from './MapKit'

const MapContext = React.createContext<React.MutableRefObject<google.maps.Map>>(null)

export type GoogleMapProps = {
    center: GeoLocation
    zoom?: number
    offset?: Vector
    range?: number
    className: string
    children?: ReactNode
}


export default function GoogleMap(
    {
        center,
        zoom = 12,
        offset,
        range,
        className,
        children

    }: GoogleMapProps) {

    const mapRef = useRef<google.maps.Map>()
    const mapHolderRef = useRef<HTMLDivElement>()

    const [hasMap, setHasMap] = useState(false)

    useEffect(() => {
        mapRef.current = new google.maps.Map(mapHolderRef.current, {
            center,
            zoom,
            styles: mapStyle,
            disableDefaultUI: true,
        })

        mapRef.current.addListener('projection_changed', () => {
            setHasMap(true)
        })

    }, [])


    useEffect(() => {
        if (offset && hasMap) {
            const currentCenter = mapRef.current.getCenter()
            mapRef.current.setCenter(center)

            if (range) {
                mapRef.current.setZoom(getZoomForRange(mapRef.current, range))
            } else {
                mapRef.current.setZoom(zoom)
            }

            const offsetCenter = getOffsetCenter(mapRef.current, offset)
            mapRef.current.setCenter(currentCenter)
            mapRef.current.panTo(offsetCenter)
        }

    }, [center, zoom, range, offset, hasMap])


    return (
        <MapContext.Provider value={hasMap ? mapRef : null}>
            <div className={className} ref={mapHolderRef}/>
            {hasMap && children}
        </MapContext.Provider>
    )
}



export function useMap() {
    const mapContext = useContext(MapContext)
    return mapContext.current
}
