import {useEffect, useState} from 'react'


export default function useScrollPosition(): number {
    const [scrollPosition, setScrollPosition] = useState<number>(window.scrollY)

    const listener = e => {
        setScrollPosition(window.scrollY)
    }

    useEffect(() => {
        window.addEventListener('scroll', listener, {passive:true})
        return() => window.removeEventListener('scroll', listener)
    })


    return scrollPosition
}
