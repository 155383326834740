import React from 'react'

import styles from './AboutUs.module.css'
import {classList} from '@peachy/utility-kit-pure'
import PageHeading from '../shared/PageHeading/PageHeading'
import LessHoops from './LessHoops/LessHoops'
import Believe from './Believe/Believe'
import HappyPeople from './HappyPeople/HappyPeople'
import Orchard from './Orchard/Orchard'
import FeelingPeachy from '../shared/FeelingPeachy/FeelingPeachy'


export type AboutUsProps = {
    className?: string
}



export default function AboutUs({className}: AboutUsProps) {

    const rootClasses = classList(styles.AboutUs, className)

    return (
        <main className={rootClasses}>
            <PageHeading className={styles.heading}>
                <h1>Simple health insurance... <br/>That's just peachy</h1>
                <p>There's no need for healthcare brokers in the UK—that's old school. We found a way to get health insurance directly to you while adding even more value.</p>
            </PageHeading>
            <LessHoops/>
            <Believe/>
            <HappyPeople/>
            <Orchard />
            <FeelingPeachy className={styles.FeelingPeachy}/>
        </main>
    )
}

