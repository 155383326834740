import React from 'react'
import Faq from '../../../shared/FaqPanel/Faq'
import FaqLink from '../../../shared/FaqPanel/FaqLink'

export default function WhatFor() {

    return (
        <Faq question={'What we use your information for'}>
            <p>
                We collect and process your information that is relevant and necessary for us to provide our products
                and services to you.
                We also collect and process any information that is required by law, or that is in our or any third
                parties legitimate interests.
            </p>
            <p>
                Detailed explanations of what we use your information for are:
            </p>
            <ul>
                <li>So we can provide services set out in our contract, including services powered by a third party</li>
                <li>So we can identify you and manage your account and our relationship with you</li>
                <li>So we can carry out customer profiling and analyse your preferences, which will allow us to
                    customise our website, app and any applicable content so it is most relevant to you
                </li>
                <li>So we can carry out statistical research and analysis, which will allow us to develop, manage and
                    improve our products and services
                </li>
                <li>When it is required or allowed by law, including laws regarding public interest</li>
                <li>So we can give you an accurate insurance quote</li>
                <li>So we can efficiently process and undertake checks to allow us to give the green light on your cover
                    check
                </li>
                <li>So we can efficiently process and undertake checks to allow us to accept your claim and pay you
                    back
                </li>
                <li>To prevent or detect fraud (further details under <FaqLink link={'Fraud prevention and detection'}/>)
                </li>
                <li>To let you know about other products or services that may be of interest to you, only if you have
                    opted in to marketing (further details under <FaqLink link={'Marketing'}/>)
                </li>
                <li>So we can monitor our business performance and maintain appropriate company records</li>
                <li>If we need to establish, make or defend a legal claim</li>
                <li>To enforce or apply our website terms of use, policy terms and conditions or any other contracts
                </li>
                <li>To protect our, our customers or other people's rights, property or safety</li>
                <li>To take part in, or be the subject of any sale, purchase, merger or takeover of all or part of the
                    Peachy business
                </li>
            </ul>
        </Faq>
    )
}
