import React from 'react'

import styles from './ProfilePanel.module.css'
import Expander from '../../../../../elements/Expander/Expander'
import {classList} from '@peachy/utility-kit-pure'
import baseStyles from '../../BrowseCare.module.css'
import {useHovered} from '../../../../../service-stub/CareSearchController'
import ProfileHeader from './ProfileHeader/ProfileHeader'
import ProfileLocation from './Location/ProfileLocation'
import Contact from './Contact/Contact'
import Speciality from '../../Speciality/Speciality'
import {unique} from '../../Filters/Filters'
import {Consultant, Practice, Secretary} from '@peachy/legacy-domain'

export type ProfileProps = {
    className?: string
    onClick?(): void
    mode: 'mini' | 'full'
    profile: Consultant
}


export default function ProfilePanel(profileProps: ProfileProps) {
    const {profile, mode, className, onClick} = profileProps

    const rootClasses = classList(baseStyles.panel, styles.Profile, mode === 'full' && styles.fullProfile, className)
    const [_, setHovered] = useHovered()

    return (
        <article className={rootClasses} onClick={onClick} onMouseEnter={() => setHovered(profile)}
                 onMouseLeave={() => setHovered(null)}>
            <ProfileHeader {...profileProps} />
            <ProfileBody  {...profileProps} />
        </article>
    )
}


function ProfileBody(profileProps: ProfileProps) {
    const {profile, mode} = profileProps

    const specialities = gatherSpecs(profile).map(s =>
        <Speciality name={s} small={true}/>
    )

    const allSecretaries = secretaryContacts(profile.secretaries)
    const practiceSecretaries = profile.practices
        .map(p => p.secretaries)
        .flat()
        .map(i => allSecretaries[i])

    const generalSecretaries = allSecretaries
        .filter(s => !practiceSecretaries.includes(s))
        .map(s =>
            <Contact {...s}/>
        )

    const locations = profile.practices.map(practice =>
        <ProfileLocation profile={profile} location={practice} secretaries={allSecretaries}/>
    )


    const languages = unique(profile.languages.map(l => l.language))
        .map(l =>
            <span className={styles.language}>{l}</span>
        )

    // profile.languages
    //     .map(l =>
    //         <span className={classList(styles.language, languageStyles[l] ?? languageStyles.Unknown)}>{l}</span>
    //     )
    //
    // const languages = unique(profile.languages.map(l => l.language.split(/,/).reverse()[0].trim()))
    //     .map(l => <span className={classList(styles.language, languageStyles[l] ?? languageStyles.Unknown)}>{l}</span>)

    // const languages = profile.languages.map(l => {
    //     const language = l.language.split(/,/).reverse()[0].trim()
    //     return <span className={classList(styles.language, languageStyles[language])}>{l.language}</span>
    // })
    //

    return (
        <Expander className={styles.profileBody} expanded={mode === 'full'} tag={'div'}>
            <aside className={styles.locations}>
                {!!specialities.length && <>
                    <h5>Clinical specialities</h5>
                    <ul className={styles.specialities}>{specialities}</ul>
                </>}
                {!!generalSecretaries.length &&
                <h5>Medical secretaries</h5>
                }
                {generalSecretaries}
                <h5>Locations</h5>
                {locations}
                {!!languages.length &&
                <h5>Languages</h5>
                }
                {languages}
                {profile.gmcId && <>
                    <h5>GMC membership number</h5>
                    <span className={styles.gmc}>{profile.gmcId}</span>
                </>}
            </aside>
        </Expander>
    )

}


function gatherSpecs(profile: Consultant) {
    const specialities = []
    if (profile.primarySpeciality) {
        specialities.push(profile.primarySpeciality)
    }
    specialities.push(...profile.otherSpecialities)
    return specialities
}


export type ContactInfo = {
    name?: string
    emails?: string[]
    phoneNumbers?: string[]
}


function secretaryContacts(secretaries: Secretary[]): ContactInfo[] {
    return secretaries.map(s => ({
        name: s.name,
        emails: s.email ? [s.email] : [],
        phoneNumbers: s.phoneNumbers
    }))
}

function locationContacts(location: Practice): ContactInfo[] {
    const contactInfo: ContactInfo[] = []
    if (location.emails || location.phoneNumbers) {
        contactInfo.push({
            emails: location.emails,
            phoneNumbers: location.phoneNumbers
        })
    }
    return contactInfo
}
