import styles from './FaqPage.module.css'
import React from 'react'
import AboutOurPlansFaqs from './AboutOurPlansFaqs'
import GettingCareFaqs from './GettingCareFaqs'
import VirtualGpFaqs from './VirtualGpFaqs'
import PageHeading from '../shared/PageHeading/PageHeading'
import StillCurious from './StillCurious'
import FeelingPeachy from '../shared/FeelingPeachy/FeelingPeachy'

export default function FaqPage() {

    return (
        <main className={styles.FAQs}>
            <PageHeading className={styles.pageHeading}>
                <h1>Get to the pit of your questions</h1>
                <p>
                    Want to know a bit more about Peachy?
                    You're in the right place.
                </p>
            </PageHeading>
            <AboutOurPlansFaqs/>
            <GettingCareFaqs/>
            <VirtualGpFaqs/>
            <StillCurious/>
            <FeelingPeachy className={styles.FeelingPeachy}/>
        </main>
    )
}

