import {KeyMapped} from '@peachy/utility-kit-pure'
import {MrPlanBenefit} from './MrPlanBenefit'
import {BenefitType} from '../PlanBenefit'
import {Excess} from '../Excess'


export class MrPlan {
    id: string
    name: string
    benefits: KeyMapped<MrPlanBenefit, BenefitType>
    configId?: string
    excess?: Excess
}
