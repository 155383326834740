import styles from './WholeCrew.module.css'
import library from '../../../global-style/library.module.css'
import {classList} from '@peachy/utility-kit-pure'
import OneTwoColumnGrid from '../../../elements/layout/OneTwoColumnGrid/OneTwoColumnGrid'
import Photo from '../../shared/Photo/Photo'

export default function WholeCrew() {

    const photoClasses = classList(styles.crew, library.photo)

    return (
        <OneTwoColumnGrid className={styles.WholeCrew}>
            <article>
                <h2>Get the whole crew covered fast</h2>
                <p>Create your custom plan and get your team covered the same day. Upload everyone's details at once, and we'll automatically pull what we need to get started.</p>
            </article>
            <Photo className={styles.crew} caption={'Work Colleagues'}/>
        </OneTwoColumnGrid>
    )
}
