import styles from './TextInput.module.css'
import {useRef} from 'react'
import {classList} from '@peachy/utility-kit-pure'


export type TextAreaProps = {
    label: string
    value: string
    setValue: (value: string) => void
    className?: string
}


export default function TextArea({label, value, setValue, className}: TextAreaProps) {
    const ref = useRef(undefined)

    const classes = classList(styles.Label, styles.TextAreaLabel, !value && styles.placeholder, className)

    const onChange = (v) => {
        ref.current.style.height = 'auto'
        ref.current.style.height = ref.current.scrollHeight + 'px'
        setValue(v)
    }

    return <>
        <label className={classes}>
            <span>{label}</span>
            <textarea ref={ref} onChange={e => onChange(e.target.value)} value={value}/>
        </label>
    </>

}
