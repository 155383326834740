import React, {ReactElement, useRef} from 'react'
import styles from './Scroller3.module.css'
import {classList} from '@peachy/utility-kit-pure'


type ScrollerProps<T> = {
    list: T[]
    className?: string
    template(props: ItemTemplateProps<T>): ReactElement
}

export type ItemTemplateProps<T> = {
    item: T
    index: number
    key?: any

}

export type Setter<T> = (f: (t: T) => T) => void


export default function Scroller3<T>({list, template, className}: ScrollerProps<T>) {
    const rootClasses = classList(styles.Scroller3, className)

    const containerRef = useRef<HTMLOListElement>()

    const ItemTemplate = template

    let renderedElements = null
    renderedElements = list.map((item, i) => {
        return (
            <ItemTemplate key={i}  item={item} index={i}/>
        )
    })

    return (
        <ul ref={containerRef} className={rootClasses}>
            {renderedElements}
        </ul>
    )
}
