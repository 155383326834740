import React from 'react'
import styles from './Believe.module.css'
import {classList} from '@peachy/utility-kit-pure'
import PageHeading from '../../shared/PageHeading/PageHeading'

export type BelieveProps = {
    className?: string
}

export default function Believe({className}: BelieveProps) {

    const rootClasses = classList(styles.Believe, className)

    return (
        <section className={rootClasses}>
            <PageHeading>
                <h2>Here's what we believe in</h2>
                <p>At Peachy, values mean something sweeter than words on a page. This is what brings us together as a team, what we rally behind.</p>
            </PageHeading>
        </section>
    )
}
