import {AnEvent} from '../../../event-kit'
import {Properties} from '@peachy/utility-kit-pure'

export class SendIndividualPolicyDocReIssueRequest extends AnEvent {
    constructor(props: Properties<SendIndividualPolicyDocReIssueRequest>) {
        super()
        Object.assign(this, props)
    }
    accountId: string
    lifeId: string
    policyId: string
    subscriptionId: string
}
