import {createContext, Dispatch, ReactNode, useContext, useState} from 'react'
import {CareSearchQuery, CareSearchResults,} from './care-search-domain'
import searchCare from './care-search-services'
import {areSame} from '../util/util'
import {Draft} from '@peachy/utility-kit-pure'
import Spinner from '../elements/Spinner/Spinner'
import {Consultant} from '@peachy/legacy-domain'

export type CareSearchControllerProps = {
    children?: ReactNode
}


const defaultQuery: CareSearchQuery = {
    locus: undefined,
    rangeInMeters: 3000,
    appointmentTypes: [],
    genderPreferences: [],
    costRanges: [],
    paediatric: false,
    searchTerms: []
}


export type SelectionContext = Consultant[]
export type HoverContext = Consultant

const SearchResultContext = createContext<ReactState<CareSearchResults>>(undefined)
const QueryContext = createContext<ReactState<CareSearchQuery>>(undefined)
const QueryingContext = createContext<ReactState<boolean>>(undefined)
const ShowFiltersContext = createContext<ReactState<boolean>>(undefined)

const SelectionContext = createContext<ReactState<SelectionContext>>(undefined)
const HoverContext = createContext<ReactState<HoverContext>>(undefined)

export default function CareSearchController({children}: CareSearchControllerProps) {


    const queryContext = useState<CareSearchQuery>(defaultQuery)
    const [searchResults, setSearchResults] = useState<CareSearchResults>()

    const [isQuerying, setIsQuerying] = useState(false)
    const [showFilters, setShowFilters] = useState(false)

    const selectionContext = useState<SelectionContext>([])
    const hoverContext = useState<HoverContext>()

    return (
        <QueryingContext.Provider value={[isQuerying, setIsQuerying]}>
            <SearchResultContext.Provider value={[searchResults, setSearchResults]}>
                <QueryContext.Provider value={queryContext}>
                    <ShowFiltersContext.Provider value={[showFilters, setShowFilters]}>
                        <SelectionContext.Provider value={selectionContext}>
                            <HoverContext.Provider value={hoverContext}>
                                {children}
                                {isQuerying && <Spinner/>}
                            </HoverContext.Provider>
                        </SelectionContext.Provider>
                    </ShowFiltersContext.Provider>
                </QueryContext.Provider>
            </SearchResultContext.Provider>
        </QueryingContext.Provider>
    )
}


export function useSelection() {
    return useContext(SelectionContext)
}

export function useHovered() {
    return useContext(HoverContext)
}

export function useIsQuerying() {
    return useContext(QueryingContext)
}

export function useShowFilters() {
    return useContext(ShowFiltersContext)
}


export function useCareSearchResults(): CareSearchResults {
    const [results, _] = useContext(SearchResultContext)
    return results
}

export function useCareSearchQuery(): [CareSearchQuery, (update: Draft<CareSearchQuery>) => void, () => void] {
    const [query, setQuery] = useContext(QueryContext)
    const [isQuerying, setIsQuerying] = useContext(QueryingContext)

    const [searchResults, setSearchResults] = useContext(SearchResultContext)

    const fireQuery = () => {


        if (areSame(query, searchResults?.query)) return

        setIsQuerying(true)
        setSearchResults({
            query,
            profiles: [],
        })


        searchCare(query)
            .then(searchResults => {
                if (areSame(query, searchResults.query)) {
                    setSearchResults(searchResults)
                    setIsQuerying(false)
                }
            })
            .catch((e) => {
                setIsQuerying(false)
                console.log(e)
            })
    }

    const updateQuery = (update: Draft<CareSearchQuery>) => {
        setQuery(current => {
            return {
                ...current,
                ...update
            } as CareSearchQuery
        })
    }

    return [query, updateQuery, fireQuery]
}



export type ReactState<T> = [T, Dispatch<React.SetStateAction<T>>]
