import React, {useState} from 'react'
import styles from './AddFamily.module.css'
import {LifeType, LifeTypes, QuoteRequest, toClass} from '@peachy/legacy-domain'
import Modal from '../../../elements/Modal/Modal'
import CaptureOtherLife from '../../CaptureLife/CaptureOtherLife/CaptureOtherLife'
import {useCurrentQuote, useQuoteService} from '../../QuoteController'
import {classList, NOOP} from '@peachy/utility-kit-pure'

export type AddFamilyProps = {
    type: LifeType
    className?: string
}

export default function AddFamily({type, className}: AddFamilyProps) {

    const rootClasses = classList(styles.AddFamily, className)

    const [showForm, setShowForm] = useState(false)

    const quoteService = useQuoteService()

    const quote = useCurrentQuote()
    const quoteRequestClass = toClass(quote.request.value, QuoteRequest)

    const lives = quoteRequestClass.lives.filter(l => l.is(type))
    const lifeCount = lives.length
    const isSecondLife = type === LifeTypes.SECONDARY

    const [editLifeId, setEditLifeId] = useState<string>()

    const headerProps = {
        onClick() {
            setShowForm(true)
        },
        className: styles.canAdd
    }
    if (isSecondLife && lifeCount > 0) {
        headerProps.onClick = NOOP
        delete headerProps.className
    }

    const header = isSecondLife
        ? <h3 {...headerProps}>Partner/spouse</h3>
        : <h3 {...headerProps}>Children</h3>

    const onDismiss = () => {
        setShowForm(false)
        setEditLifeId(null)
    }

    const onRemoveLife = (id: string) => {
        quoteService.removeLife(id)
    }

    return (
        <section className={rootClasses}>
            {header}
            <ul style={{display: 'contents'}}>
                {
                    lives.map((l, i) => (
                        <li key={i} onClick={() => {
                            setShowForm(true)
                            setEditLifeId(l.id)
                        }
                        }>{l.firstname} {l.lastname}<i onClick={(event) => {
                            onRemoveLife(l.id)
                            event.stopPropagation()
                        }} className={'fal fa-times'}/></li>
                    ))
                }
            </ul>
            {
                showForm &&
                <Modal onDismiss={onDismiss} trapFocus={true}>
                    <CaptureOtherLife onDismiss={onDismiss} lifeType={type} editLifeId={editLifeId}/>
                </Modal>
            }
        </section>
    )
}

const onStage = {
    opacity: 1,
    transform: 'scale(1)'

}

const offStageLeft = {
    opacity: 0,
    transform: 'scale(0.5)'
}

const offStageRight = {
    opacity: 0,
    transform: 'scale(1.5)'
}
