import React from 'react'
import styles from './Filters.module.css'
import {classList} from '@peachy/utility-kit-pure'
import FilterExpander from './FilterExpander/FilterExpander'
import FilterPanel from './FilterPanel/FilterPanel'
import Expander from '../../../../elements/Expander/Expander'
import AppliedFilters from './AppliedFilters/AppliedFilters'
import LocationSelector from './LocationSelector/LocationSelector'
import CategorySelector from './CategorySelector/CategorySelector'
import useEnsureGoogleMaps from '../../../../App/useEnsureGoogleMaps'
import {GeoLocation} from '../../../../Map/MapKit'
import {useCareSearchQuery, useShowFilters} from '../../../../service-stub/CareSearchController'


export type FiltersProps = {
    className?: string
}


export function unique(a: any[]) {
    if (!a) return []
    return [...new Set(a)]
}


export default function Filters({className}: FiltersProps) {


    const [showFilters, setShowFilters] = useShowFilters()

    const rootClasses = classList(styles.Filters, className)

    const hasMapsApiLoaded = useEnsureGoogleMaps() === 'loaded'

    const [query, updateQuery, fireQuery] = useCareSearchQuery()

    const addSearchTerm = (searchTerm: string) => {
        updateQuery({
            searchTerms: unique([searchTerm])
            // searchTerms: unique([...query.searchTerms, searchTerm])
        })
    }

    const removeSearchTerm = (searchTerm: string) => {
        updateQuery({
            searchTerms: query.searchTerms.filter(t => t !== searchTerm)
        })
    }

    const setLocus = (locus: GeoLocation) => {
        updateQuery({locus})
    }

    return (
        <section className={rootClasses}>
            <FilterExpander isOpen={showFilters} setIsOpen={setShowFilters}/>
            <CategorySelector addSearchTerm={addSearchTerm}/>
            {hasMapsApiLoaded &&
            <LocationSelector onLocationSelected={setLocus}/>
            }
            <Expander expanded={showFilters} tag={'section'}>
                <FilterPanel/>
            </Expander>
            <AppliedFilters/>
            {/*<AppliedCategories removeSearchTerm={removeSearchTerm}/>*/}
        </section>
    )
}
