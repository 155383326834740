import styles from './TextInput.module.css'
import {classList} from '@peachy/utility-kit-pure'


export type TextInputProps = {
    label: string
    value: string
    setValue: (value: string) => void,
    className?: string
}


export default function TextInput({label, value, setValue, className}: TextInputProps) {

    const classes = classList(styles.Label, !value && styles.placeholder, className)

    return <>
        <label className={classes}>
            <span>{label}</span>
            <input onChange={v => setValue(v.target.value)} value={value} type={'text'}/>
        </label>
    </>

}

