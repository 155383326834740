import FaqPanel from '../../shared/FaqPanel/FaqPanel'
import styles from '../Policies.module.css'
import WhatTypes from './questions/WhatTypes'
import HowWeCollect from './questions/HowWeCollect'
import WhatFor from './questions/WhatFor'
import WhoShare from './questions/WhoShare'
import HowSecure from './questions/HowSecure'
import WhenDelete from './questions/WhenDelete'
import AutomatedDecisions from './questions/AutomatedDecisions'
import Fraud from './questions/Fraud'
import Marketing from './questions/Marketing'
import YourRights from './questions/YourRights'
import OurDPO from './questions/OurDPO'
import Changes from './questions/Changes'
import React from 'react'
import WhoAreWe from './questions/WhoAreWe'

export default function PrivacyPolicy() {
    return (
        <FaqPanel heading={'Privacy Policy'} blurb={<PrivacyBlurb/>} className={styles.faqPanel}>
            <WhoAreWe/>
            <WhatTypes/>
            <HowWeCollect/>
            <WhatFor/>
            <WhoShare/>
            <HowSecure/>
            <WhenDelete/>
            <AutomatedDecisions/>
            <Fraud/>
            <Marketing/>
            <YourRights/>
            <OurDPO/>
            <Changes/>
        </FaqPanel>
    )
}


function PrivacyBlurb() {
    return (
        <article className={styles.blurb}>
            <p>
                Here at Peachy we value our customers trust and want to ensure you can trust us to process and store
                your
                information in a safe and lawful manner. We take the security of your information and ensuring our
                compliance with privacy laws very seriously. We ask that you read this privacy policy carefully as it
                contains information on the following;
            </p>
            <ol>
                <li>What types of information we collect about you</li>
                <li>What we do with this information</li>
                <li>Who we might share with this information with</li>
                <li>Your rights regarding our processing of this information</li>
            </ol>
            <p>
                Within this privacy policy, the terms 'we', 'us' and 'our' mean Peachy. The terms 'you', 'your' and
                'yourself' refer to anyone that interacts with our products and services in any way.
            </p>
        </article>
    )
}
