import Modal from '../../../elements/Modal/Modal'
import DatePicker from './DatePicker'
import pickerStyles from './StyledDatePicker.module.css'
import library from '../../../global-style/library.module.css'
import {classList} from '@peachy/utility-kit-pure'
import React, {useState} from 'react'
import styles from '../DateInput/DateInput.module.css'


export type StyledDatePickerProps = {
    selectedDate?: Date,
    defaultPickerDate?: Date,
    onSelect: (selectedDate: Date) => void,
    onDismiss?: () => void,
    header?: string,
    minDate?: Date,
    maxDate?: Date
}


export function StyledDatePicker(props: StyledDatePickerProps) {
    const [showPicker, setShowPicker] = useState(false)

    const onDismiss = () => {
        setShowPicker(false)
        props.onDismiss?.()
    }

    return <>
        {showPicker && <DatePickerModal {...props} onDismiss={onDismiss}/> }
        <PickerTrigger showPicker={showPicker} setShowPicker={setShowPicker} />
    </>

}


function PickerTrigger({showPicker, setShowPicker}) {

    const pickerIconClasses = classList('fal fa-calendar', styles.pickerIcon)

    return (
        <button type={'button'} onClick={(e) => {
            setShowPicker(true)
        }}>
            <i className={pickerIconClasses}>Date picker</i>
        </button>
    )
}


function DatePickerModal({selectedDate, defaultPickerDate, onSelect, onDismiss, header, minDate, maxDate}: StyledDatePickerProps) {

    return (
        <Modal onDismiss={onDismiss}>
            <DatePicker
                onSelectDate={onSelect}
                header={
                    <h3>{header}</h3>
                }
                minDate={minDate}
                maxDate={maxDate}
                onDismiss={onDismiss}
                selectedDate={selectedDate}
                displayDate={selectedDate ?? defaultPickerDate}
                className={pickerStyles.datePicker}
                invalidNudgeClassName={pickerStyles.invalidMonthYearNudge}
                monthYearSelectorClassName={pickerStyles.monthYearSelector}
                monthYearSelectorListClassName={library.lookup}
                weekendClassName={pickerStyles.weekend}
                weekdayClassName={pickerStyles.weekday}
                outOfMonthClassName={pickerStyles.outOfMonth}
                invalidDayClassName={pickerStyles.invalidDay}
                todayClassName={pickerStyles.today}
                selectedDateClassName={pickerStyles.selected}
                leftArrowClassName={'fal fa-angle-left'}
                rightArrowClassName={'fal fa-angle-right'}
                cancelButtonClassName={classList('fal fa-times', pickerStyles.cancel)}
            />
        </Modal>
    )
}

