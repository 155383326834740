import {defineValidator, MessageProducer} from '../validate-core'

export default function IsIn<T>(
    array: unknown[] | readonly unknown[],
    message?: string | MessageProducer<T>
) {
    return defineValidator<T>({
        name: 'IsIn',
        messageParams: {array},
        message:
            message ??
            (({property}) => `Property ${property} must be in array '${array}'`),
        validate({value}) {
            return array.includes(value)
        },
    })
}
