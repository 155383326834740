import React from 'react'
import baseClasses from '../../BrowseCare.module.css'
import styles from './FilterPanel.module.css'
import {classList} from '@peachy/utility-kit-pure'

import Slider from '../../../../../elements/Slider/Slider'
import GenderSelector from '../GenderSelector/GenderSelector'
import PaediatricSelector from '../PaediatricSelector/PaediatricSelector'
import {Gender} from '../../../../../service-stub/care-search-domain'
import {useCareSearchQuery} from '../../../../../service-stub/CareSearchController'
import useScreenMetrics from '../../../../../hooks/useScreenMetrics'

export type FilterPanelProps = {
    className?: string,
}


export default function FilterPanel({className}: FilterPanelProps) {

    const rootClasses = classList(baseClasses.panel, styles.FilterPanel, className)

    const [query, updateQuery, fireQuery] = useCareSearchQuery()

    const rangeInKm = query.rangeInMeters / 1000

    const setRangeInKm = (km) => {
        updateQuery({rangeInMeters: km * 1000})
    }

    const setGender = (genderPreferences: string[]) => {
        updateQuery({genderPreferences: genderPreferences as Gender[]})
    }


    const togglePaediatric = (f:(b:boolean) => boolean) => {
        updateQuery({paediatric: f(query.paediatric)})
    }

    const isMobile = useScreenMetrics().width < 900

    const label = isMobile ? 'Distance' : 'Distance from location'

    return (
        <fieldset className={rootClasses}>
            {/*<AppointmentTypeSelector selection={filterState.appointmentTypes.value} setSelection={filterState.appointmentTypes.set}/>*/}
            <GenderSelector selection={query.genderPreferences} setSelection={setGender}/>
            {/*<CostSelector selection={filterState.costRanges.value} setSelection={filterState.costRanges.set}/>*/}
            <Slider value={rangeInKm} setValue={setRangeInKm} label={label} min={1} max={20} step={0.1}/>
            <PaediatricSelector state={query.paediatric} setState={togglePaediatric}/>
            {/*<button className={library.button} onClick={fireQuery}>Apply</button>*/}
        </fieldset>
    )
}
