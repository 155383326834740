import {Auth} from '@aws-amplify/auth'
import {API} from '@aws-amplify/api'
import {configureApiGateway, fetchServiceConfig} from '@peachy/core-domain-client'
import {useServicePatch} from '@peachy/client-kit'

let ROOT_DOMAIN: string

// const apis = [
//     'PaymentsApi',
//     'LookupApi',
//     'MarketplaceApi',
//     'SubscriptionApi',
//     'QuoteApi',
//     'Intercom'
// ]



export async function configureAws() {

    const servicePatchUrl = useServicePatch()

    const fetchServicePatch = (service: string) => fetchServiceConfig(service, servicePatchUrl)

    const domainConfig = await fetchServicePatch('DomainConfig')
    ROOT_DOMAIN = domainConfig.rootDomainName

    const iamConfig = await fetchServicePatch('IamConfig')

    Auth.configure(iamConfig)


    await configureApiGateway('PaymentsApi', API)
    await configureApiGateway('LookupApi', API)
    await configureApiGateway('MarketplaceApi', API)
    await configureApiGateway('QuoteApi', API)
    await configureApiGateway('Intercom', API)
}
