import {Properties} from '@peachy/utility-kit-pure'
import {AnEvent} from '../../event-kit'
import {CancellationEventData, SubscriptionEventData} from '../interfaces'
import {AccountType} from '../../../domain/models/Account'
import {CancellationType} from '../../../domain/models/CancellationType'

export class SubscriptionCancelledNotification extends AnEvent implements SubscriptionEventData, CancellationEventData {
    constructor(from: Properties<SubscriptionCancelledNotification>) {
        super()
        Object.assign(this, from)
    }

    accountId: string
    accountType: AccountType
    subscriptionId: string
    cancellationReason: string
    cancellationType: CancellationType
}
