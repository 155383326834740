import React, {useRef, useState} from 'react'
import styles from './PlaceLookup.module.css'
import {NOOP} from '@peachy/utility-kit-pure'
import useDataThrottle from '../../hooks/useDataThrottle'
import ListSelector from '../ListSelector/ListSelector'
import TextBox from '../../Quote/Elements/TextBox/TextBox'
import Expander from '../Expander/Expander'
import {Address, AddressMatch} from '@peachy/legacy-domain'
import useAsyncEffect from 'use-async-effect'
import {LookupAddressRequest} from '@peachy/geo-location-pure'
import {PEACHY_HQ} from '../../pages/ContactUs/OurOffice/OurOffice'
import {createGeoLocationServiceClient} from '@peachy/geo-location-client'
import {useServicePatch} from '../../service/WebShopServiceController'
import {Signer} from '@aws-amplify/core'


const lookupService = createGeoLocationServiceClient(useServicePatch(), Signer)

export type AddressLookupProps = {
    className?: string
    residentialOnly?: boolean
    initialSearchTerm?: string,
    autoFocus?: boolean,
    onSelectAddress?(address: Address): void
    onFieldChange?(value: string): void
    onBlur?: () => void
    onFocus?: () => void
    onClick?: () => void
}


const maxLookupRateMs = 500


export default function AddressLookup(
    {
        onSelectAddress = NOOP,
        onFieldChange = NOOP,
        onBlur = NOOP,
        onFocus = NOOP,
        initialSearchTerm = '',
        residentialOnly = false,
        autoFocus: autoFocus = false,
        className
    }: AddressLookupProps) {

    const requestIdRef = useRef('')
    const [isQueryEnabled, setIsQueryEnabled] = useState(false)
    const [enteredSearchTerm, setEnteredSearchTerm] = useState(initialSearchTerm)

    const setAndNotifyEnteredSearchTerm = (value: string) => {
        onFieldChange?.(value)
        setEnteredSearchTerm(value)
    }

    const [selectedMatch, setSelectedMatch] = useState<AddressMatch>()
    const [addressMatches, setAddressMatches] = useState<AddressMatch[]>()
    const matchedAddressesDisplay = addressMatches?.map(p => p.address)


    const hasMatches = !!(addressMatches?.length)

    const throttledEnteredSearchTerm = useDataThrottle(enteredSearchTerm, maxLookupRateMs)

    const [keyEvent, setKeyEvent] = useState<React.KeyboardEvent>()

    const [showSelector, setShowSelector] = useState(false)

    const onSelectMatch = async (match: string, index: number) => {
        if (match !== enteredSearchTerm) {
            setEnteredSearchTerm(match)
            setSelectedMatch(addressMatches[index])
            await lookupService.fetchAddress({addressId: addressMatches[index].id}).then(onSelectAddress)
        }
        setShowSelector(false)
    }

    const onEscape = () => {
        setShowSelector(false)
    }


    const onKeyDown = (e: React.KeyboardEvent) => {
        setIsQueryEnabled(true)
        if (['Enter', 'Tab'].includes(e.key) && matchedAddressesDisplay && showSelector) {
            e.preventDefault()
        }
        setKeyEvent(e)
    }


    useAsyncEffect(async isActive => {

        if (isQueryEnabled && throttledEnteredSearchTerm && throttledEnteredSearchTerm != selectedMatch?.address) {

            if (hasEnoughSearchContent(throttledEnteredSearchTerm)) {

                requestIdRef.current = `${Math.random()}`

                const lookupRequest: LookupAddressRequest = {
                    searchTerm: throttledEnteredSearchTerm,
                    requestId: requestIdRef.current,
                    location: PEACHY_HQ,
                    residentialOnly
                }

                const response = await lookupService.lookupAddress(lookupRequest)

                if (isActive() && response.requestId === requestIdRef.current) {
                    setAddressMatches(response.matches)
                    setShowSelector(true)
                }

            } else {
                setAddressMatches(null)
                setShowSelector(false)
            }
        }

    }, [throttledEnteredSearchTerm])

    // console.log('Rendering', {showSelector, isQueryEnabled, selectedMatch, matches: matchedAddressesDisplay?.length})



    return (<>
        <TextBox autoFocus={autoFocus} className={styles.PlaceLookup} placeholder={'Start typing your home address'}
                 value={enteredSearchTerm}
                 setValue={setAndNotifyEnteredSearchTerm} onKeyDown={onKeyDown} onBlur={onBlur} onFocus={onFocus} />
        {addressMatches &&
            <Expander expanded={showSelector && hasMatches} tag={'aside'}>
                <ListSelector list={matchedAddressesDisplay} selection={selectedMatch?.address}
                              externalKeyboardEvent={keyEvent} className={styles.options} onSelect={onSelectMatch}
                              enabled={showSelector}
                              onDismiss={onEscape}/>
            </Expander>
        }

    </>)
}


const minSearchContent = 5

function hasEnoughSearchContent(searchTerm: string) {
    return searchTerm.replace(/\s/g, '').length >= minSearchContent
}
