import {Dictionary} from './utility-types'

// Not used
export function escapeForRegex(string: string) {
    return string.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
}

//should probably move this elsewhere but at least it's now shared with everywhere we're performing this hacky check
export function isAscenti(thingWithFullname: { fullName: string }) {
    return thingWithFullname.fullName === 'Ascenti'
}

export function possessiveNoun(noun?: string) {
    if (noun) {
        const addition = noun.toLowerCase().endsWith('s') ? '\'' : '\'s'
        return noun + addition
    }
}

export function truncate(length: number, input?: string) {
    return input?.slice(0, length)
}

// TODO String util or html util
export const classList = (...classes: string[]) => prune(classes).join(' ')
const prune = <T>(array: T[]) => array.filter((e) => e)

export function toBase64(value: string) {
    return Buffer.from(value, 'utf8').toString('base64')
}

export function parameterize(template: string, params: Dictionary<any>) {
    if (!params) return template

    let result = template
    Object.entries(params).forEach(([param, value]) => {
        const pattern = '\\$\\{' + param + '\\}'
        const regex = new RegExp(pattern, 'g')
        result = result.replace(regex, value)
    })
    return result
}

export interface TemplateExecutor {
    (data?: Dictionary<any>): string
}

export function template(templateString: string, defaultData: Dictionary<any> = {}): TemplateExecutor {
    return function (runtimeData: Dictionary<any> = {}) {
        const data = {...defaultData, ...runtimeData}
        return parameterize(templateString, data)
    }
}

export function reverseString(s: string) {
    return [...s].reverse().join('')
}


export function utf8ByteLength(s: string) {
    // returns the byte length of an utf8 string
    let length = s.length
    for (let i = s.length - 1; i >= 0; i--) {
        const code = s.charCodeAt(i)
        if (code > 0x7f && code <= 0x7ff) {
            length++
        } else if (code > 0x7ff && code <= 0xffff) {
            length += 2
        }
        if (code >= 0xDC00 && code <= 0xDFFF) i-- //trail surrogate
    }
    return length
}


export function toTitleCase(input?: string) {
    return input.replace(
        /\w\S*/g,
        (txt) => txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase()
    )
}
