import {Properties} from '@peachy/utility-kit-pure'
import {AnEvent} from '../../event-kit'
import {LifeTurnover, SubscriptionEventData} from '../interfaces'
import {AccountType} from '../../../domain/models/Account'


export class SubscriptionAlteredNotification extends AnEvent implements SubscriptionEventData {
    constructor(from: Properties<SubscriptionAlteredNotification>) {
        super()
        Object.assign(this, from)
    }


    lifeTurnover?: LifeTurnover

    accountId: string
    accountType: AccountType
    subscriptionId: string
}
