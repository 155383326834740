import React from 'react'
import Faq from '../../../shared/FaqPanel/Faq'
import FaqLink from '../../../shared/FaqPanel/FaqLink'

export default function Marketing() {
    return (
        <Faq question={'Marketing'}>
            <p>
                We will ask you whether you would like us and other businesses to send you marketing information when
                you complete our quote and buy process,
                and you can opt out at any time via our app or by contacting a member of our team.
            </p>
            <p>
                We would like to use your personal information to send you marketing by post,
                email or SMS related to competitions and special offers which may be of interest to you.
                Other businesses that we have carefully selected may also send you similar marketing messages.
            </p>
            <p>
                Under Data Protection Laws you have the right to object to marketing, for more information please see
                <FaqLink link={'Your rights'}/>
            </p>
        </Faq>
    )
}

