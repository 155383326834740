import React, {ReactNode} from 'react'
import styles from './OneTwoColumnGrid.module.css'
import {classList} from '@peachy/utility-kit-pure'

export type OneTwoColumnGridProps = {
    tag?: string
    className?: string
    children: ReactNode
}

export default function OneTwoColumnGrid({tag = 'section', className, children}: OneTwoColumnGridProps) {

    const ContainerTag = tag as keyof JSX.IntrinsicElements

    const rootClasses = classList(styles.OneTwoColumnGrid, className)

    return (
        <ContainerTag className={rootClasses}>
            {children}
        </ContainerTag>
    )
}
