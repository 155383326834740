import {
    addHours,
    addMonths,
    differenceInMonths,
    differenceInYears,
    format,
    isAfter,
    isBefore,
    parse,
    parseISO
} from 'date-fns'
import {isDateObject} from './type-check-kit'
import {IsoDatePartOnly, plusYears, yearsBetweenAccurateToTheMillisecond} from './calendar-kit'

type DateOrNum = Date | number

/*
parseISO(str) can give Invalid Date which is a DateObject
This will pass for an invalid string.
 */
export const isIsoDate = (str: string) => str && isDateObject(parseISO(str))

export const formatDmyDate = (epoch: number) => {
    let dateAsString = ''
    try {
        dateAsString = format(epoch, 'dd/MM/yyyy')
    } catch (e) {
        // Most likely invalid input
    }
    return dateAsString
}

export const parseDmyDate = (rawText: string) => parse(rawText, 'dd/MM/yyyy', new Date())

/*
* NOTE: this only formats the date in the standard uk style (i.e day/month/year) but does nothing about making sure the timezone is correct
*/
export function ukStyleDate(date?: Date) {
    return date ? format(date, 'dd/MM/yy') : undefined
}

export function readableDate(date?: DateOrNum) {
    return date ? format(date, 'dd MMM yy') : undefined
}

export function readableDateFromIsoDatePartOnly(dateStr?: IsoDatePartOnly) {
    return dateStr ? readableDate(new Date(dateStr)) : undefined
}

export function readableDateWithDay(date?: Date) {
    return date ? format(date, 'iii do MMM') : undefined
}

export function readableDateWithDayAndTime(date?: Date, dateTimeSeparator?: string) {
    const separator = dateTimeSeparator ? ' ' + dateTimeSeparator + ' ' : ' '
    return date ? format(date, 'iii do MMM') + separator + format(date, 'HH:mm') : undefined
}

export function getNextAnniversaryOf(eventDateTime: Date, atDateTime: Date = new Date()) {
    const wholeYearsBetween = isBefore(eventDateTime, atDateTime) ? yearsBetweenAccurateToTheMillisecond(eventDateTime, atDateTime) : 0
    const nextAnniversary = plusYears(wholeYearsBetween + 1, eventDateTime)
    return nextAnniversary
}

export function getLastAnniversaryOf(eventDateTime: Date, atDateTime: Date = new Date()) {
    const wholeYearsBetween = yearsBetweenAccurateToTheMillisecond(eventDateTime, atDateTime)
    const lastAnniversary = plusYears(wholeYearsBetween, eventDateTime)
    return lastAnniversary
}

export class DateInterval implements Interval {

    readonly start: Date
    readonly end: Date

    constructor(props: Omit<AnnualInterval, 'id'>) {
        this.start = props.start
        this.end = props.end
    }
}
export class AnnualInterval extends DateInterval {}

export function getCurrentAnnualIntervalOf(eventDateTime: Date) {
    const rightNow = new Date()
    return getAnnualIntervalAtDateOf({eventDateTime, atDateTime: rightNow})
}

export function getAnnualIntervalsToDateOf(eventAtDate: {eventDateTime: Date, atDateTime: Date, fromDateTime?: Date}) {
    const {atDateTime, eventDateTime, fromDateTime} = eventAtDate

    const intervals: AnnualInterval[] = []

    if (!isBefore(atDateTime, eventDateTime)) {
        const numIntervals = yearsBetweenAccurateToTheMillisecond(eventDateTime, atDateTime) + 1
        for (let i = 0; i < numIntervals; i++) {
            const currentIntervalAtDateTime = plusYears(-i, atDateTime)
            const interval = getAnnualIntervalAtDateOf({eventDateTime, atDateTime: currentIntervalAtDateTime})
            if (!fromDateTime || isAfter(interval.end, fromDateTime)) {
                intervals.push(interval)
            }
        }
    }

    return intervals.reverse()
}

export function getAnnualIntervalAtDateOf(eventAtDate: {eventDateTime: Date, atDateTime: Date}): AnnualInterval {
    const {atDateTime, eventDateTime} = eventAtDate

    if (!isBefore(atDateTime, eventDateTime)) {
        return new AnnualInterval({
            start: getLastAnniversaryOf(eventDateTime, atDateTime),
            end: getNextAnniversaryOf(eventDateTime, atDateTime)
        })
    }
}

export function getOrdinalDayWithMonth(date?: DateOrNum) {
    return date ? format(date, 'do MMM') : undefined
}

/**
 * Stripe creates the `next_payment_attempt` one hour after the anchor date
 * One hour is added here to reflect this and also to avoid BST issue of midnight subscriptions showing the date before
 */
export function getNextPaymentDateAfter(startDate: Date, after: Date) {
    return addHours(getNextAnchorDateAfter(startDate, after), 1)
}

/**
 * https://stripe.com/docs/billing/subscriptions/billing-cycle?locale=en-GB
 * See link around how stripe works when month does not contain the anchor date (its capped to end of month, same as date-fns.addMonths)
 */
export function getNextAnchorDateAfter(startDate: Date, after: Date) {
    if (startDate > after) {
        return startDate
    } else {
        const fullMonthsBetween = differenceInMonths(after, startDate)
        const monthsToAdd = fullMonthsBetween + 1
        return addMonths(startDate, monthsToAdd)
    }
}

export const isAgeBefore = (dob: DateOrNum, startDate: DateOrNum, age: number) => {
    return ageAt(dob, startDate) < age
}

export const isAgeInRange = (dob: DateOrNum, at: DateOrNum, ageLowerBound: number, ageUpperBound: number) => {
    const age = ageAt(dob, at)
    return age >= ageLowerBound && age <= ageUpperBound
}

export function ageAt(dob: DateOrNum, at: DateOrNum) {
    return dob <= at ? differenceInYears(at, dob) : undefined
}