import {defineValidator, MessageProducer, ValidateIf} from '../validate-core'
import {dateFromIso, isDayInPast, isIsoDate} from '@peachy/utility-kit-pure'

export default function IsFutureIsoDateString<T>(
    message?: string | MessageProducer<T>,
    validateIf?: ValidateIf<T>
) {
    return defineValidator<T>({
        name: 'IsFutureIsoDateString',
        message:
            message ??
            (({property}) =>
                `Property ${property as string} must be a valid ISO date string in the future`),
        validateIf,
        validate({value}) {
            return isIsoDate(value) && !isDayInPast(dateFromIso(value))
        },
    })
}
