import {useEffect, useState} from 'react'
import equal from 'fast-deep-equal/es6'

export type DirectionOfTravel = 'UP' | 'DOWN' | 'STILL'


export default function useDirectionOfTravel(value: number, sampleSize: number = 5): DirectionOfTravel {
    const [_buffer, setBuffer] = useState<number[]>([value])
    const [directionOfTravel, setDirectionOfTravel] = useState<DirectionOfTravel>('STILL')

    useEffect(() => {

        setBuffer(b => {
            let newBuffer = [...b, value]
            if (newBuffer.length > sampleSize) {
                newBuffer = newBuffer.slice(1)
            }

            if (newBuffer.length === sampleSize) {
                const sortedAsc = [...newBuffer].sort((a,b)=> a - b)
                const sortedDesc = [...newBuffer].sort((a,b)=> b - a)
                if (equal(newBuffer, sortedAsc)) {
                    setDirectionOfTravel('DOWN')
                } else if (equal(newBuffer, sortedDesc)) {
                    setDirectionOfTravel('UP')
                } else {
                    setDirectionOfTravel('STILL')
                }
            }

            return newBuffer
        })



    }, [value])


    return directionOfTravel
}
