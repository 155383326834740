import React from 'react'
import styles from './TextBox.module.css'
import {classList, NOOP} from '@peachy/utility-kit-pure'

export type TextBoxProps = {
    placeholder?: string
    value: string | number
    disabled?: boolean
    setValue(value: string): void
    onBlur?(e): void
    onFocus?(e): void
    onKeyDown?(e): void
    inputRef?: React.Ref<HTMLInputElement>
    numeric?: boolean
    email?: boolean
    phone?: boolean
    redact?: boolean
    format?: string
    autoFocus?: boolean
    autoCapitalize?: boolean
    maxLength?: number
    label?: string
    className?: string
    onClick?: () => void
}


export default function TextBox(
    {
        value,
        setValue,
        disabled = false,
        placeholder,
        label,
        className,
        onBlur = NOOP,
        onFocus = NOOP,
        onClick = NOOP,
        onKeyDown = NOOP,
        inputRef,
        autoFocus = false,
        autoCapitalize = false,
        numeric = false,
        email = false,
        phone = false,
        redact = false,
        format,
        maxLength
    }: TextBoxProps) {

    const textBoxClasses = classList(styles.TextBox, className)

    const onChange = (e) => {
        let raw = e.target.value
        if (numeric) raw = raw.replace(/\D/g, '')
        if (format) raw = formatValue(raw, format)
        setValue(raw)
    }

    const formatValue = (value: string, format) => {

        const formattedChars = []
        let stringIndex = 0
        for (let formatIndex = 0; formatIndex < Math.min(format.length); formatIndex++) {
            const formatChar = format.charAt(formatIndex)
            if (formatChar === '#') {
                if (stringIndex < value.length) {
                    formattedChars.push(value.charAt(stringIndex))
                    stringIndex++
                }
            } else {
                if (stringIndex < value.length) {
                    formattedChars.push(formatChar)
                }
            }
        }
        return formattedChars.join('')
    }

    const additionalProps: any = {}
    if (numeric) {
        additionalProps.inputMode = "numeric"
    }else if (email) {
        additionalProps.inputMode = "email"
    }

    if (phone) {
        additionalProps.type= "tel"
    }


    const myClick = (e) => {
        e.target.focus()
        e.target.select()
    }



    return (
        <label className={textBoxClasses}>
            <span>{label}</span>
            <input type={redact? 'password' : 'text'} value={value}
                   disabled={disabled}
                   autoComplete={''}
                   autoCapitalize={autoCapitalize? 'on' : 'off'}
                   ref={inputRef}
                   autoFocus={autoFocus}
                   onChange={onChange} placeholder={placeholder}
                   onBlur={onBlur}
                   onFocus={onFocus}
                   onKeyDown={onKeyDown}
                   onClick={myClick}
                   maxLength={maxLength}
                {...additionalProps}
            />
        </label>
    )
}
