import React from 'react'
import styles from './PaediatricSelector.module.css'
import Toggle, {ToggleProps} from '../../../../../elements/Toggle/Toggle'


export default function PaediatricSelector({state, setState} : ToggleProps) {

    return (
        <label className={styles.PaediatricSelector}>
            <span>Paediatric care required</span>
            <Toggle state={state} setState={setState} />
        </label>
    )
}
