import {defineValidator, MessageProducer, ValidateIf} from '../validate-core'

export default function ContainsOne<T>(
    suchThat: (e: T) => boolean,
    message?: string | MessageProducer<T>,
    validateIf?: ValidateIf<T>
) {
    return defineValidator<T>({
        name: 'ContainsOne',
        message: message ?? (({property}) => 'Must have one!'),
        messageParams: {suchThat},
        validateIf,
        validate({value}) {
            return value?.filter(suchThat).length === 1
        },
    })
}
