import styles from './LessHoops.module.css'
import PicturePanel from '../../shared/PicturePanel/PicturePanel'
import Photo from '../../shared/Photo/Photo'

export default function LessHoops() {
    return (
        <PicturePanel className={styles.LessHoops}>
            <article>
                <h2>Less hoops, more fruit</h2>
                <p>
                    We've been in the healthcare industry long enough to see its inefficiencies. Tech has outpaced
                    insurance paperwork, and we're here to bring everything up to speed. Bringing better plans to people
                    across the UK is a humble step towards accessible care for all.
                </p>
            </article>
            <Photo className={styles.peaches} caption={'Peaches!'}/>
        </PicturePanel>
    )
}
