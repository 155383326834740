import {API} from '@aws-amplify/api'

import {dump, KeyMapped, Valid} from '@peachy/utility-kit-pure'
import {mapFromLegacyQuoteResponse, mapToLegacyQuoteRequest, QuoteRequest, QuoteResponse} from '@peachy/legacy-domain'

type GraphApi = typeof API

export type QuoteApi = {
    requestQuote(quoteRequest: Valid<QuoteRequest>): Promise<QuoteResponse>
}


export function createQuoteApi(graphApi: GraphApi = API): QuoteApi {
    return {
        requestQuote(quoteRequest: Valid<QuoteRequest>) {
            return requestQuote(quoteRequest, graphApi)
        }
    }
}


async function requestQuote(quoteRequest: Valid<QuoteRequest>, api: GraphApi): Promise<QuoteResponse> {

    const legacyQuote = mapToLegacyQuoteRequest(quoteRequest)

    dump(legacyQuote)

    const request = {
        body: legacyQuote,
        headers: {}
    }
    const response = await api.post(quoteApiName, newQuoteApiPath, request)

    const lifePlanMap: KeyMapped<string> = {}
    quoteRequest.lives.forEach(l => {
        lifePlanMap[l.id] = l.planId
    })
    return mapFromLegacyQuoteResponse(response, lifePlanMap)
}

const quoteApiName = 'QuoteApi'
const newQuoteApiPath = '/new'
