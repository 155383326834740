import React from 'react'
import Faq from '../../../shared/FaqPanel/Faq'

export default function AutomatedDecisions() {
    return (

        <Faq question={'Automated decision making process'}>
            <p>
                <span>
                    Like many businesses, we use automated decision making to provide a faster, better, more consistent and fair service,
                    and to provide marketing information we think will be of interest to you.
                    If you object to an automated decision, we may not be able to offer you an insurance quote.
                </span>
                <span>
                    We use automated decision making for the following:
                </span>
            </p>
            <h4>Offering an insurance plan price</h4>
            <p>
                We ask you a series of questions when you obtain a quote for insurance from us,
                and we use software to calculate the price of products and services based on what we know about you and other customers.
            </p>
            <h4>Aiding in the cover check process</h4>
            <p>
                We ask you a series of questions during the cover check process,
                and we use software to inform us of technical reasons why you are not covered.
                This includes your plan being inactive, and your benefit limit being at £0.
            </p>
            <h4>Aiding in the claims process</h4>
            <p>
                We ask you a series of questions during the claim process,
                and we use software to inform us of technical reasons why you are not covered.
                This includes your plan being inactive and your benefit limit being at £0.
            </p>
        </Faq>
    )
}


