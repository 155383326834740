import {useEffect, useState} from 'react'


export default function useIsScrollTop() {
    const [isScrollTop, setScrollTop] = useState(window.scrollY <= 0)

    const listener = e => {
        if (isScrollTop !== (window.scrollY <= 0)) {
            setScrollTop(!isScrollTop)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', listener, {passive:true})
        return() => window.removeEventListener('scroll', listener)
    })


    return isScrollTop
}
