import styles from './FreeToGo.module.css'
import library from '../../../global-style/library.module.css'
import {classList} from '@peachy/utility-kit-pure'
import PicturePanel from '../../shared/PicturePanel/PicturePanel'

export default function FreeToGo() {
    const photoClasses = classList(styles.astronaut, library.photo)
    return (
        <PicturePanel className={styles.FreeToGo}>
            <article>
                <h3>You're free to go</h3>
                <p>Traditional policies might enforce annual contracts. We don't. You'll pay once a month for Peachy and can leave us any time.</p>
                <h3>Rest easy - you're covered</h3>
                <p>Use our in-app checker to see if a treatment is covered before you get it, and we'll give you the greenlight. You can make a claim up to 16 weeks after your treatment.</p>
            </article>
            <figure className={photoClasses}>
                <figcaption>Astronaut</figcaption>
            </figure>
        </PicturePanel>
    )
}
