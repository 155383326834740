import IsEmail from '../validation/validators/IsEmail'
import IsTrue from '../validation/validators/IsTrue'
import Required from '../validation/validators/Required'

export class User {
    id: string
    cognitoUserId?: string
    intercomUserId?: string

    @Required('Please enter your first name')
    firstname: string

    @Required('Please enter your last name')
    lastname: string

    @IsEmail('Please enter a valid email address')
    email?: string

    @Required()
    marketingAccepted: boolean = false

    @IsTrue<User>('You must agree to our Privacy Policy and Term of Service to proceed...')
    policiesAccepted: boolean = false

}