import React, {ReactNode} from 'react'
import styles from './PicturePanel.module.css'
import {classList} from '@peachy/utility-kit-pure'
import OneTwoColumnGrid from '../../../elements/layout/OneTwoColumnGrid/OneTwoColumnGrid'

export type PicturePanelProps = {
    className?: string
    children: ReactNode;
}

export default function PicturePanel({children, className}: PicturePanelProps) {
    const rootClasses = classList(styles.PicturePanel, className)

    return (
        <OneTwoColumnGrid className={rootClasses}>
            {children}
        </OneTwoColumnGrid>
    )
}
