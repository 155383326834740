import React from 'react'
import styles from './GenderSelector.module.css'
import PeachyChoice from '../../../../../elements/branded-elements/PeachyChoice/PeachyChoice'
import {SelectionProps} from '../../../../../elements/Choice/Choice'

export default function GenderSelector(props: SelectionProps) {

    return (
        <PeachyChoice label={'Gender preference'} className={styles.GenderSelector} {...props}>
            <input id={'male'} data-display-name={'Male'} value={'MALE'} type={'checkbox'}/>
            <input id={'female'} data-display-name={'Female'}  value={'FEMALE'} type={'checkbox'}/>
        </PeachyChoice>
    )
}
