import React from 'react'
import styles from './GenderInput.module.css'
import {classList} from '@peachy/utility-kit-pure'
import {State as HookState} from '@hookstate/core'
import {GenderType, GenderTypes} from '@peachy/legacy-domain'

export type GenderInputProps = {
    className?: string
    legend?: string
    genderState: HookState<GenderType>
}

export default function GenderInput({className, genderState, legend}: GenderInputProps) {

    const rootClasses = classList(styles.GenderInput, className)

    const maleClass = classList(genderState.value === GenderTypes.MALE && styles.selected)
    const femaleClass = classList(genderState.value === GenderTypes.FEMALE && styles.selected)
    const otherClass = classList(genderState.value === GenderTypes.BLENDED && styles.selected)

    return (
        <fieldset className={rootClasses}>
            {legend && <legend>{legend}</legend>}
            <label onClick={() => {genderState.set(GenderTypes.MALE)}} className={maleClass}>
                <span>Male</span>
                <input type={'radio'} name={'gender'} value={'MALE'}/>
            </label>
            <label onClick={() => {genderState.set(GenderTypes.FEMALE)}} className={femaleClass}>
                <span>Female</span>
                <input type={'radio'} name={'gender'} value={'FEMALE'}/>
            </label>
            <label onClick={() => {genderState.set(GenderTypes.BLENDED)}} className={otherClass}>
                <span>Other</span>
                <input type={'radio'} name={'gender'} value={'OTHER'}/>
            </label>
        </fieldset>
    )
}
