import {AnEvent} from '../../event-kit'
import {Props} from '@peachy/utility-kit-pure'
import {PaymentTransaction} from '../../../domain/models/payments/PaymentTransaction'

export class PaymentEvent extends AnEvent {
    public accountId: string
    public subscriptionId: string
    public paymentTransaction: PaymentTransaction

    constructor(from: Props<PaymentEvent>) {
        super()
        Object.assign(this, from)
    }
}
