import {Premium} from './Premium'
import {BenefitType} from './PlanBenefit'


export class LifeBenefit {
    type: BenefitType
    utilisation: number = 0
    startDate: number
    premium: Premium
}
