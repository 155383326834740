import React, {ReactNode} from 'react'

type StageRouterProps<StageName> = {
    children: ReactNode
    currentStage: StageName
}

export default function StageRouter<StageName extends string>({currentStage, children}: StageRouterProps<StageName>) {

    const kids = React.Children.map(children, (route: RouteElement<StageName>) => {
        if (route.props.onStage === currentStage
            || route.props.onStages?.includes(currentStage)) {
            return route
        } else {
            return null
        }
    })

    return <>{kids}</>
}


type RouteElement<StageName extends string> = {
    props: RouteStageProps<StageName>
}

export type RouteStageProps<StageName extends string> = {
    onStage?: StageName
    onStages?: StageName[]
}
