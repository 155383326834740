

export type ServiceProvider<A extends any[], S> = (...args: A) => S


export class ServiceProviderControl<A extends any[], S> {
    #currentProvider: ServiceProvider<A, S>

    constructor(public readonly defaultProvider: ServiceProvider<A, S>) {
        this.#currentProvider = defaultProvider
    }

    overrideProvider(provider: ServiceProvider<A, S>) {
        this.#currentProvider = provider ?? this.defaultProvider
    }
    resetProvider() {
        this.#currentProvider = this.defaultProvider
    }

    get provide() {
        return this.#currentProvider
    }
}
