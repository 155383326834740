



export class DocGenTicket<D = any> {
    templateName: string
    docData: D
}


export function docGenTicketKey(ticketId: string) {
    return `peachy/doc-gen-ticket/${ticketId}`
}