import React, {useEffect, useRef, useState} from 'react'
import styles from './CategorySelector.module.css'
import TextBox from '../../../../../Quote/Elements/TextBox/TextBox'
import PeachySelector from '../../../../../elements/branded-elements/PeachySelector/PeachySelector'
import {allSearchCategories} from '../../../../../service-stub/care-search-services'

export type CategorySelectorProps = {
    className?: string
    addSearchTerm(term: string)
}

export default function CategorySelector({className, addSearchTerm}: CategorySelectorProps) {

    const [value, setValue] = useState('')

    const [matchedTerms, setMatchedTerms] = useState<string[]>([])

    const [keyEvent, setKeyEvent] = useState<React.KeyboardEvent>()

    const onEscape = () => {
        setMatchedTerms([])
    }
    const onSelectSpecialism = (specialism: string) => {
        setMatchedTerms([])
        setValue(specialism)
        addSearchTerm(specialism)
    }

    useEffect(() => {

        if (value.trim()) {
            setMatchedTerms(allSearchCategories.filter(s => {
                return s.toLowerCase().includes(value.toLowerCase())
            }))
        } else {
            setMatchedTerms([])
        }

    }, [value])

    const inputRef = useRef<HTMLInputElement>()

    return (
        <label className={styles.CategoryFilter}>
            <TextBox inputRef={inputRef} autoCapitalize autoFocus placeholder={'Category'} value={value} setValue={setValue}
                     onKeyDown={setKeyEvent} onFocus={(e) => e.target.select()}/>

            {matchedTerms.length
                ? <PeachySelector list={matchedTerms} selection={matchedTerms[0]} externalKeyboardEvent={keyEvent}
                                  className={styles.options} onSelect={onSelectSpecialism} onDismiss={onEscape}/>
                : null
            }

            <button onClick={() => {

                inputRef.current.focus()
            }}>Select Category</button>

        </label>
    )

}

