import {ReactNode, useState} from 'react'
import Expander from '../../../../elements/Expander/Expander'
import styles from './Benefit.module.css'

export type BenefitProps = {
    name: string,
    className: string,
    children: ReactNode
}


export default function Benefit({name, className, children}: BenefitProps) {
    const [isExpanded, setIsExpanded] = useState(false)
    const headerClass = isExpanded ? styles.expanded : ''
    return (
        <article className={`${styles.Benefit} ${className}`}>
            <h4 className={headerClass} onClick={() => setIsExpanded(!isExpanded)}>{name}</h4>
            <Expander expanded={isExpanded} tag={'div'}>
                {children}
            </Expander>
        </article>
    )


}
