import TextBox from '../Elements/TextBox/TextBox'
import CaptureForm, {CaptureFieldsProps, CaptureFormProps} from './CaptureForm'
import {Life} from '@peachy/legacy-domain'

export default function CaptureName(props: CaptureFieldsProps<Life>) {
    const {instance, visitedFields} = props

    const captureNameProps: CaptureFormProps<Life> = {
        ...props,
        buttonId: 'capture-name',
        headers: <>
            <h4>We'll get you an awesome price in seconds. Let's go!</h4>
        </>,
        captureFields:['firstname', 'lastname']
    }

    return (
        <CaptureForm  {...captureNameProps}>
            <fieldset>
                <TextBox autoCapitalize={true} autoFocus placeholder={'First name'} value={instance.firstname.value} setValue={instance.firstname.set}
                    onBlur={() => {
                        instance.firstname.set(instance.firstname.value?.trim())
                        visitedFields.toggleOn('firstname')
                    }} />
                <TextBox placeholder={'Last name'} value={instance.lastname.value} setValue={instance.lastname.set} 
                    onBlur={() => {
                        instance.lastname.set(instance.lastname.value?.trim())
                        visitedFields.toggleOn('lastname')
                    }} />
            </fieldset>
        </CaptureForm>
    )
}
