import React, {useState} from 'react'
import styles from './FaqPanel.module.css'
import Expander from '../../../elements/Expander/Expander'

export default function Faq({question, children}) {

    const [isExpanded, setIsExpanded] = useState(false)
    const className = isExpanded ? styles.expanded : ''

    return (
        <li data-question={question} className={styles.FAQ}>
            <h4 className={className} onClick={() => setIsExpanded(!isExpanded)}>{question}</h4>
            <Expander expanded={isExpanded} tag={'article'}>
                {children}
            </Expander>
        </li>
    )
}
