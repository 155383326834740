import {classList} from '@peachy/utility-kit-pure'
import baseStyles from '../../BrowseCare.module.css'
import styles from './ProfilePanel.module.css'
import headerStyles from './ProfileHeader/ProfileHeader.module.css'

import avatar from './dummy-avatars/avatar-001.png'
import {useShowFilters} from '../../../../../service-stub/CareSearchController'

export default function NoResults() {

    const rootClasses = classList(baseStyles.panel, styles.Profile, styles.NoResults)

    const [showFilters, setShowFilters] = useShowFilters()

    return (
        <article className={rootClasses} onClick={() => {setShowFilters(true)}}>

            <header className={headerStyles.profileHeader}>
                <img style={{borderRadius: '50%'}} src={avatar} alt={''} aria-hidden/>
                <h4>{'Oops!'}</h4>
                    <p className={styles.problem}>Looks like there are no results!</p>
            </header>
            <br/>
            <h5 className={styles.solution}>Try changing the filters, location or search radius &rarr;</h5>
        </article>
    )
}
