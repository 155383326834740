import React, {ReactNode, useState} from 'react'

import baseClasses from './DatePicker.module.css'
import CalendarHeader from './CalendarHeader'
import CalendarTable from './CalendarTable'
import {classList, dateArrayFrom, MonthYearArray, today} from '@peachy/utility-kit-pure'
import {resolveDisplayDate} from './datepicker-util'
import Button from '../Button/Button'


export default function DatePicker(props: DatePickerProps) {
    const {
        minDate,
        maxDate,
        selectedDate,
        displayDate = today(),
        header,
        onDismiss,
        onSelectDate,
        disableYearSelect,
        className,
        invalidNudgeClassName,
        monthYearSelectorSelectedClassName,
        monthYearSelectorClassName,
        monthYearSelectorListClassName,
        weekendClassName,
        weekdayClassName,
        outOfMonthClassName,
        invalidDayClassName,
        todayClassName,
        selectedDayClassName,
        selectedDateClassName,
        leftArrowClassName,
        rightArrowClassName,
        cancelButtonClassName,

    } = props





    const [indicatedDate, setIndicatedDate] = useState(selectedDate)

    const confirmedDisplayDate = resolveDisplayDate(displayDate, selectedDate, minDate, maxDate)

    const [displayMonthYear, setDisplayMonthYear] = useState<MonthYearArray>(dateArrayFrom(confirmedDisplayDate).slice(1) as MonthYearArray)

    const Cancel = () => <button className={cancelButtonClassName} onClick={onDismiss}>X</button>

    const pickerClasses = classList(baseClasses.picker, className)
    return (
        // <aside ref={ref} className={pickerClasses}>
        <aside className={pickerClasses}>
            <Cancel/>
            <CalendarHeader
                header={header}
                displayMonthYear={displayMonthYear}
                lowerBound={minDate}
                upperBound={maxDate}
                onSelect={setDisplayMonthYear}
                outOfBoundsClassName={invalidNudgeClassName}
                monthYearSelectorSelectedClassName={monthYearSelectorSelectedClassName}
                monthYearSelectorClassName={monthYearSelectorClassName}
                monthYearSelectorListClassName={monthYearSelectorListClassName}
                leftArrowClassName={leftArrowClassName}
                rightArrowClassName={rightArrowClassName}
                disableYearSelect={disableYearSelect}
            />
            <CalendarTable
                displayMonthYear={displayMonthYear}
                selectedDate={indicatedDate}
                onSelect={setIndicatedDate}
                lowerBound={minDate}
                upperBound={maxDate}
                weekendClassName={weekendClassName}
                outOfBoundsClassName={invalidDayClassName}
                offMonthClassName={outOfMonthClassName}
                todayClassName={todayClassName}
                selectedDateClassName={selectedDateClassName}
            />
            <Button enabled={!!indicatedDate} text={'Confirm'} onClick={() => {
                if (indicatedDate) {
                    onSelectDate(indicatedDate)
                    onDismiss()
                }
            }}/>
        </aside>
    )
}


type DatePickerProps = {
    minDate?: Date
    maxDate?: Date
    onDismiss: () => void
    onSelectDate: (date: Date) => void
    selectedDate?: Date
    displayDate?: Date
    disableYearSelect?: boolean
    header: ReactNode
    className: string
    invalidNudgeClassName: string
    monthYearSelectorSelectedClassName?: string
    monthYearSelectorClassName: string
    monthYearSelectorListClassName: string
    weekendClassName: string
    weekdayClassName: string
    outOfMonthClassName: string
    invalidDayClassName: string
    todayClassName: string
    selectedDayClassName?: string
    selectedDateClassName?: string
    leftArrowClassName?: string
    rightArrowClassName?: string
    cancelButtonClassName?: string
}
