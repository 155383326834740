import {Properties} from '@peachy/utility-kit-pure'
import {LifeEventData} from '../interfaces'
import {AnEvent} from '../../event-kit'
import {AccountType} from '@peachy/repo-domain'


export class LifeActivatedNotification extends AnEvent implements LifeEventData {

    constructor(from: Properties<LifeActivatedNotification>) {
        super()
        Object.assign(this, from)
    }

    accountId: string
    accountType: AccountType
    subscriptionId: string
    policyId: string
    lifeId: string
    cognitoId: string
}
