import {Class} from 'type-fest'
import {keys, toJson} from '@peachy/utility-kit-pure'


type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'ALL'


type ParamNames<PATH extends string> =
    PATH extends `${string}{${infer PARAM}}${infer REST}`
        ? [PARAM, ...ParamNames<REST>]
        : []

type ParamName<PATH extends string> = ParamNames<PATH>[number]


export type Params<PATH extends string> = {
    [_k in ParamName<PATH>]: string
}



export const paramRegex = /\{(\w*)}/g

export function paramNames<P extends string>(path: P): ParamNames<P> {
    return [...path.matchAll(paramRegex)].flatMap(e => e[1]) as ParamNames<P>
}

export function hasParams<P extends string>(path: P): boolean {
    return !!paramNames(path).length
}

export function stripQueryParams(path: string) {
    const url = new URL(path, 'http://peachy.health')
    const params = [...url.searchParams.keys()]
    for (const param of params) {
        url.searchParams.delete(param)
    }
    return decodeURI(url.pathname)
}


export function insertParams<P extends string>(path: P, params: Params<P>) {

    const expectedParams = paramNames(path)
    const actualParams = keys(params)

    expectedParams.sort()
    actualParams.sort()
    if (expectedParams.length != actualParams.length) {
        throw `Param mismatch - Unable to insert params ${toJson(params)} into path ${path}`
    }

    let pathWithParams: string = path

    actualParams.forEach((actualParam, i) => {
        if (actualParam !== expectedParams[i]) {
            throw `Param mismatch - Unable to insert params ${toJson(params)} into path ${path}`
        }
        pathWithParams = pathWithParams.replace(`{${actualParam}}`, params[actualParam])
    })

    return pathWithParams
}





export type ApiGatewayRouteDefinition = {
    method: HttpMethod
    path: string
    isPublic?: boolean
    requestType?: Class<unknown>,
    responseType?: Class<unknown>,
}


export type ApiGatewayRoutesDefinition = {
    [r: string]: ApiGatewayRouteDefinition
}


export type ApiGatewayDefinition<R extends ApiGatewayRoutesDefinition> = {
    name: string
    routes: R
}

export function defineApiGateway(named: string) {
    return {
        withRoutes<const RD extends ApiGatewayRoutesDefinition>(routes: RD) {
            return {
                name: named,
                routes
            }
        }
    }
}
