
type ClassType<T> = new (...args: any[]) => T;

type EnumArray = readonly string[]
type EnumUnionType<T extends EnumArray> = T[number]
type EnumObjectType<T extends EnumArray> = { [P in EnumUnionType<T>]: P }

// a mapping from strings to themselves!
type KeyEnumeration<T> = { [P in keyof T]: P }

// maps object keys to themselves - ie provides an enum lookup
// enumerateKeys({aProp:1} as const).aProp === 'aProp'
export function enumerateKeys<T>(o: T): KeyEnumeration<T>;
export function enumerateKeys<T>(classType: ClassType<T>): KeyEnumeration<T>;
export function enumerateKeys<T>(objectOrClassType: ClassType<T> | Object): KeyEnumeration<T> {
    if (typeof objectOrClassType === 'function') {
        const classType = objectOrClassType as ClassType<T>
        return enumerateKeys(new classType()) as KeyEnumeration<T>
    } else {
        return enumerate(Object.keys(objectOrClassType)) as KeyEnumeration<T>
    }
}

// enum lookup from an array
// enumerate(['one', 'two'] as const).one === 'one'
export function enumerate<T extends EnumArray>(enums: T): EnumObjectType<T> {
    const mapped: any = {}
    enums.forEach((e) => (mapped[e] = e))
    return mapped as EnumObjectType<T>
}