import React from 'react'
import Faq from '../../../shared/FaqPanel/Faq'
import {PeachyMailHelp} from '../../../../elements/branded-elements/PeachyMail'

export default function Permission() {

    return (
        <Faq question={'Permission to download, share or print content'}>
            <p>
                If you print or download any material from our website, please treat it with respect and do not change,
                edit, manipulate, translate or deface the pages printed or downloaded. You also cannot use any graphics,
                illustrations, photographs, video or audio sequences separately from accompanying text.
            </p>
            <p>
                You must not create or attempt to create derivative copies of or copy our website in whole or in part.
                You must not reverse engineer, decompile, disassemble or otherwise reduce the object code of the website
                to source code form. You also must not use our website or any material on our website for commercial
                purposes without obtaining a written license from us to do so. </p>
            <p>
                If you wish to download, copy or share any material from our website, please check carefully that this
                is allowed under the permissions set out in these Terms. If you wish to reproduce or publish any
                material from our website in your own publication or website, you must always identify Peachy as the
                source of the material and provide a link back to our website. For information on linking to our website
                please see the section Linking to our website. If you do not follow these Terms, you will not be
                authorised to use our website or materials from our website in this way, and you may be in breach of UK
                law or similar local laws which could result in sanctions or legal action being taken against you.
            </p>
            <p>
                If you wish to make use of any material on our website other than what is set out above, please contact
                us at <PeachyMailHelp/>
            </p>
        </Faq>
    )
}
