import React, {useEffect, useRef} from 'react'
import styles from './Slider.module.css'

export type SliderProps = {
    label: string
    min: number
    max: number
    step?: number
    value: number,
    setValue(v): void
}


export default function Slider({label, min, max, step = 1, value, setValue}: SliderProps) {
    const labelRef = useRef<HTMLLabelElement>()
    const inputRef = useRef<HTMLInputElement>()


    // const [draggingValue, setDraggingValue] = useState<number>(value)


    useEffect(() => {
        const norm = ((value - min) / (max - min))
        labelRef.current.style.setProperty('--slider-ratio', norm.toString())
        labelRef.current.style.setProperty('--jeff', value.toFixed(1).toString())
        inputRef.current.style.setProperty('--slider-min', min.toString())
        inputRef.current.style.setProperty('--slider-max', max.toString())
    }, [value])





    return (
        <label ref={labelRef} className={styles.Slider} data-value={value}>
            <span>{label}</span>
            <input ref={inputRef}
                   type="range" min={min} max={max} step={step}
                   value={value}
                   onChange={(e) => {
                       setValue(+e.target.value)
                       console.log(e)
                   }}
            />
        </label>
    )
}
