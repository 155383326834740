import {AnEvent} from '../../event-kit'
import {Props} from '@peachy/utility-kit-pure'


export class IdCheckRequest extends AnEvent {
    constructor(from: Props<IdCheckRequest>) {
        super()
        Object.assign(this, from)
    }
    firstname: string
    lastname: string
    birthdate: number
    selfie: string
    photoId: string
    email?: string
    cognitoId: string
    webhook: string
}

