import {enumerate, onlyTruthy} from '@peachy/utility-kit-pure'
import {UkRegionSearch} from './uk-county-region'


export const UkRegions = enumerate([
    'Crown Dependencies',
    'Scotland',
    'Northern Ireland',
    'Wales',
    'North East England',
    'North West England',
    'Yorkshire and the Humber',
    'West Midlands',
    'East Midlands',
    'East of England',
    'London',
    'South East England',
    'South West England',
] as const)

export type UkRegion = keyof typeof UkRegions;

// pure filth!

export function resolveRegion(...settlements: string[]): UkRegion {
    settlements = onlyTruthy(settlements).reverse()
    let region: UkRegion = null

    for (const s of settlements) {
        const searchTerm = s.trim().toLowerCase().replace(/\W/g, '')
        region = UkRegions[s as UkRegion] ?? UkRegionSearch[searchTerm]
        if (region) break
    }
    return region
}
