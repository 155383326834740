import {AnEvent} from '../event-kit'

export class PolicyReposCreated extends AnEvent {

    policyId: string

    constructor(from: PolicyReposCreated) {
        super()
        Object.assign(this, from)
    }
}
