import {defineValidator, MessageProducer, ValidateIf} from '../validate-core'

export default function IsTrue<T>(
    message?: string | MessageProducer<T>,
    validateIf?: ValidateIf<T>
) {
    return defineValidator<T>({
        name: 'IsTrue',
        validateIf,
        message:
            message ??
            (({property, bannedValue}) => `Property ${property} must be true`),
        validate({value}) {
            return value === true
        },
    })
}
