import React, {ReactNode} from 'react'
import styles from './ActionPanel.module.css'
import {classList} from '@peachy/utility-kit-pure'

export type ActionPanelProps = {
    className?: string,
    children: ReactNode
}

export default function ActionPanel({className, children}: ActionPanelProps) {
    const panelClasses = classList(styles.ActionPanel, className)

    return (
        <section className={panelClasses}>
            {children}
        </section>
    )
}
