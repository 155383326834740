import React, {ReactNode, useEffect, useRef, useState} from 'react'
import ReactDOM from 'react-dom'
import {useMap} from './GoogleMap'
import {createOverlayAdaptor, OverlayAdaptor} from './OverlayAdaptor'
import {OverlayProps} from './MapKit'


export type MapOverlayProps = OverlayProps & {
    children: ReactNode
}


export default function MapOverlay({children, ...overlayProps}: MapOverlayProps) {

    const map = useMap()
    const overlayRef = useRef<OverlayAdaptor>()

    const [hasLoaded, setHasLoaded] = useState(false)

    useEffect(() => {
        const adaptorProps = {
            ...overlayProps,
            containerTag: 'div',
        }

        overlayRef.current = createOverlayAdaptor(adaptorProps)
        overlayRef.current.setMap(map)
        setHasLoaded(true)

        return (() => {
            overlayRef.current.setMap(null)
        })

    }, [])


    useEffect(() => {
        if (hasLoaded) {
            overlayRef.current.setSize(overlayProps.size)
            overlayRef.current.setLocation(overlayProps.location)
            overlayRef.current.draw()
        }

    }, [Object.values(overlayProps), hasLoaded])




    return hasLoaded
        ? ReactDOM.createPortal(children, overlayRef.current.container)
        : null
}


