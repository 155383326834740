import {useEffect, useState} from 'react'

export type Toggles<T> = {
    on: T[]
    toggleOn(toggle: T): void
    toggleOff(toggle: T): void
    toggle(toggle: T): void
    areAllOn(except?: T[]): boolean
}


export default function useToggles<T extends string>(toggles: readonly T[], onAll?: () => void): Toggles<T> {
    const [on, setOn] = useState<T[]>([])

    const areAllOn = (except: T[] = []) => {

        const testOn = on.filter(f => !except.includes(f))
        const testAll = toggles.filter(f => !except.includes(f))

        return testOn.length === testAll.length
    }

    const toggleOn = (toggle: T) => {
        setOn(toggleSet => {

            return toggleSet.includes(toggle)
                ? toggleSet
                : [...toggleSet, toggle]
        })
    }
    const toggleOff =  (toggle: T) => {
        setOn(toggleSet => {
            return !toggleSet.includes(toggle)
                ? toggleSet
                : toggleSet.filter(t => t !== toggle)
        })
    }

    const toggle = (toggle: T) => {
        setOn(toggleSet => {
            return toggleSet.includes(toggle)
                ? toggleSet.filter(t => t !== toggle)
                : [...toggleSet, toggle]
        })
    }

    useEffect(() => {
        if (onAll && on.length === toggles.length) {
            onAll()
        }

    }, [on])

    return {
        on, toggle, toggleOn, toggleOff, areAllOn
    }
}
