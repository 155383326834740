import React, {ReactElement, useEffect, useState} from 'react'
import styles from './CareResults.module.css'
import {classList} from '@peachy/utility-kit-pure'
import Scroller3, {ItemTemplateProps} from '../../../../elements/scratch/Scroller3/Scroller3'
import {useCareSearchResults, useIsQuerying, useShowFilters} from '../../../../service-stub/CareSearchController'
import ProfilePanel from './Profile/ProfilePanel'
import {Consultant} from '@peachy/legacy-domain'
import NoResults from './Profile/NoResults'

export type CareResultsProps = {
    className?: string
}



export default function CareResults({className}: CareResultsProps) {

    const rootClasses = classList(styles.ResultList, className)
    const searchResults = useCareSearchResults()
    const [showFilters, setShowFilters] = useShowFilters()

    const [haveSearched, setHaveSearched] = useState<boolean>(false)

    const [isQuerying] = useIsQuerying()
    const hasResults = !!(searchResults?.profiles?.length)
    const showNoResults = !isQuerying && !hasResults && haveSearched

    useEffect(() => {

        if (isQuerying) {
            if (!haveSearched) setHaveSearched(true)
            setShowFilters(false)
        }

    }, [isQuerying])

    return (
        <section className={styles.Container}>
            {showNoResults &&
            <NoResults/>
            }
            <Scroller3 template={ProfileListItem} list={searchResults?.profiles ?? []} className={rootClasses}/>
        </section>
    )
}


function ProfileListItem({item, index}: ItemTemplateProps<Consultant>): ReactElement {
    const [isFullProfile, setIsFullProfile] = useState(false)

    return (
        <li>
            <ProfilePanel
                profile={item}
                onClick={() => setIsFullProfile(!isFullProfile)} mode={ isFullProfile ? 'full': 'mini' }/>
        </li>
    )
}
