import ClaimInAFewTaps from './ClaimInAFewTaps/ClaimInAFewTaps'
import PersonalPlans from './PersonalPlans/PersonalPlans'
import RipeIdea from './RipeIdea/RipeIdea'
import FreeToGo from './FreeToGo/FreeToGo'
import React from 'react'

import styles from './HowItWorks.module.css'
import PageHeading from '../shared/PageHeading/PageHeading'
import FeelingPeachy from '../shared/FeelingPeachy/FeelingPeachy'

export default function HowItWorks() {
    return (
        <main className={styles.HowItWorks}>
            <PageHeading className={styles.ItsEasy}>
                <h1>It's easy to get peachy</h1>
                <p>Transparent accessible healthcare, available to anyone</p>
            </PageHeading>
            <ClaimInAFewTaps/>
            <PersonalPlans/>
            <FreeToGo/>
            <RipeIdea/>
            <FeelingPeachy className={styles.FeelingPeachy}/>
        </main>
    )
}
