import {Events, EventType} from './event-def'

export abstract class AnEvent {
    public type?: string
    public id?: string
    public source?: string
    public sourceId?: string
    public time?: number

    protected constructor() {
        this.type = this.constructor.name
    }
}


export const AllEvents = Symbol.for('AllEvents')

export type EventTypeMap = typeof Events

export type Fires<E extends AnEvent> = (e: E) => void
export type EventHandler<E extends AnEvent, F extends Fires<any>> = (e: E, fire?: F) => Promise<void>

export type EventHandlerMap = {
    [AllEvents]?: EventHandler<InstanceType<EventTypeMap[EventType]>, Fires<any>>[]
} & {
    [k in EventType]?: EventHandler<InstanceType<EventTypeMap[k]>, Fires<any>>[]
}



export function redisEventKey(eventId: string) {
    return `peachy/core-event/${eventId}`
}