import {Props} from '@peachy/utility-kit-pure'
import {AnEvent} from '../../../event-kit'
import {Payment, PaymentAttempt} from '../../../../domain/models/Payment'

export class PaymentRefunded extends AnEvent {
    public accountId: string
    public subscriptionId: string
    public paymentInfo: Payment
    public paymentAttempt: PaymentAttempt

    constructor(from: Props<PaymentRefunded>) {
        super()
        Object.assign(this, from)
    }
}
