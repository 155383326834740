import React from 'react'
import ReactDOM from 'react-dom'
import {useState as useHookState} from '@hookstate/core'
import CaptureName from '../../DraftLife/CaptureName'
import CaptureAddress from '../../DraftLife/CaptureAddress/CaptureAddress'
import CaptureBirthday from '../../DraftLife/CaptureBirthday/CaptureBirthday'
import CaptureEmail from '../../DraftLife/CaptureEmail/CaptureEmail'
import {CaptureFieldsProps} from '../../DraftLife/CaptureForm'
import {Life} from '@peachy/legacy-domain'
import {useScope} from '../../../elements/Scope'
import PeachyLogo from '../../../Logo/PeachyLogo'

import styles from '../../QuoteJourney.module.css'
import Switcher from '../../../elements/Switcher/Switcher'
import {ROOT_DOMAIN} from '../../../App/App'

export default function CaptureLifeOne({onSubmit, ...otherProps}: CaptureFieldsProps<Life>) {

    const currentStage = useHookState(0)

    const submitStage = () => currentStage.set(i => i + 1)

    const onBack = currentStage.value === 0 ? undefined : () => currentStage.set(i => i - 1)

    return (<>
        <QuoteHeaderNav onX={`https://www.${ROOT_DOMAIN}`} onBack={onBack}/>
        <main className={styles.QuoteJourney}>

            <Switcher
                duration={200}
                on={currentStage.value}
                onStage={{position: 'absolute', opacity: 1, transform: 'translateX(0em)'}}
                offStageLeft={{position: 'absolute', opacity: 0, transform: 'translateX(-10em)'}}
                offStageRight={{position: 'absolute', opacity: 0, transform: 'translateX(10em)'}}
            >
                <CaptureName {...otherProps} onSubmit={submitStage}/>
                <CaptureAddress {...otherProps} onSubmit={submitStage}/>
                <CaptureBirthday {...otherProps} onSubmit={submitStage}/>
                <CaptureEmail {...otherProps} onSubmit={onSubmit}/>

            </Switcher>
        </main>
    </>)
}


function QuoteHeaderNav({onBack, onX}) {

    const quoteNavPortal = useScope<HTMLElement>()

    return quoteNavPortal ? ReactDOM.createPortal(<>
        {onBack &&
        <button className={styles.backButton} onClick={onBack}>
            <i className="fal fa-angle-left"/>
        </button>
        }
        <PeachyLogo/>
        {onX &&

        <a className={styles.exitButton} href={onX}>
            <i className="fal fa-times"/>
        </a>

        // <button className={styles.exitButton} onClick={onX}>
        //     <i className="fal fa-times"/>
        // </button>
        }
    </>, quoteNavPortal) : null
}
