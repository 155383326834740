import React from 'react'
import GetInsuredInMinutes from './GetInsuredInMinutes/GetInsuredInMinutes'
import HealthInsuranceSimplyDone from './HealthInsuranceSimplyDone/HealthInsuranceSimplyDone'
import SeeAnyone from './SeeAnyone/SeeAnyone'
import OurPlans from './OurPlans/OurPlans'
import style from './HomePage.module.css'
import FeelingPeachy from '../shared/FeelingPeachy/FeelingPeachy'
import SimpleTerms from './OurPlans/SimpleTerms/SimpleTerms'

export default function HomePage() {

    return (
        <main className={style.HomePage}>
            <HealthInsuranceSimplyDone/>
            <SeeAnyone/>
            <OurPlans/>
            <SimpleTerms/>
            <GetInsuredInMinutes/>
            <FeelingPeachy className={style.FeelingPeachy}/>
        </main>
    )

}
