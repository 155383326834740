import {UkRegion} from './uk-regions'

export const UkCountyRegionMap: { [K: string]: UkRegion } = {
    Aberdare: 'Wales',
    'Aberdeen City': 'Scotland',
    Aberdeenshire: 'Scotland',
    Adur: 'South East England',
    Allerdale: 'North West England',
    'Amber Valley': 'East Midlands',
    Angus: 'Scotland',
    Antrim: 'Northern Ireland',
    Ards: 'Northern Ireland',
    'Argyll and Bute': 'Scotland',
    Argyllshire: 'Scotland',
    Armagh: 'Northern Ireland',
    Arun: 'South East England',
    Ashfield: 'East Midlands',
    Ashford: 'South East England',
    'Aylesbury Vale': 'South East England',
    Ayrshire: 'Scotland',
    Babergh: 'East of England',
    Ballymena: 'Northern Ireland',
    Ballymoney: 'Northern Ireland',
    Banbridge: 'Northern Ireland',
    Banffshire: 'Scotland',
    Bargoed: 'Wales',
    'Barking and Dagenham': 'London',
    Barnet: 'London',
    Barnsley: 'Yorkshire and the Humber',
    'Barrow-in-Furness': 'North West England',
    Barry: 'Wales',
    Basildon: 'East of England',
    'Basingstoke and Deane': 'South East England',
    Bassetlaw: 'East Midlands',
    'Bath and North East Somerset': 'South West England',
    'Bedford Borough': 'East of England',
    Bedford: 'East of England',
    Bedfordshire: 'East of England',
    Belfast: 'Northern Ireland',
    Berkshire: 'South East England',
    Berwickshire: 'Scotland',
    Bexley: 'London',
    Birmingham: 'West Midlands',
    Blaby: 'East Midlands',
    'Blackburn with Darwen': 'North West England',
    Blackpool: 'North West England',
    'Blaenau Gwent': 'Wales',
    Bolsover: 'East Midlands',
    Bolton: 'North West England',
    Boston: 'East Midlands',
    Bournemouth: 'South West England',
    'Bracknell Forest': 'South East England',
    Bradford: 'Yorkshire and the Humber',
    Braintree: 'East of England',
    Breckland: 'East of England',
    Brent: 'London',
    Brentwood: 'East of England',
    Bridgend: 'Wales',
    'Brighton and Hove': 'South East England',
    Bristol: 'South West England',
    Broadland: 'East of England',
    Bromley: 'London',
    Bromsgrove: 'West Midlands',
    Broxbourne: 'East of England',
    Broxtowe: 'East Midlands',
    Buckinghamshire: 'South East England',
    Burnley: 'North West England',
    Bury: 'North West England',
    Bute: 'Scotland',
    Caerphilly: 'Wales',
    Caithness: 'Scotland',
    Calderdale: 'Yorkshire and the Humber',
    Cambridge: 'East of England',
    Cambridgeshire: 'East of England',
    Camden: 'London',
    'Cannock Chase': 'West Midlands',
    Canterbury: 'South East England',
    Cardiff: 'Wales',
    Carlisle: 'North West England',
    Carmarthenshire: 'Wales',
    Carrickfergus: 'Northern Ireland',
    'Castle Point': 'East of England',
    Castlereagh: 'Northern Ireland',
    'Central Bedfordshire': 'East of England',
    Ceredigion: 'Wales',
    Charnwood: 'East Midlands',
    Chelmsford: 'East of England',
    Cheltenham: 'South West England',
    Cherwell: 'South East England',
    'Cheshire East': 'North West England',
    'Cheshire West and Chester': 'North West England',
    Cheshire: 'North West England',
    Chesterfield: 'East Midlands',
    Chichester: 'South East England',
    Chiltern: 'South East England',
    Chorley: 'North West England',
    Christchurch: 'South West England',
    'City of Bristol': 'South West England',
    'City of Edinburgh': 'Scotland',
    'City of Kingston-upon-Hull': 'Yorkshire and the Humber',
    'City of London': 'London',
    'City of Westminster': 'London',
    Clackmannanshire: 'Scotland',
    Colchester: 'East of England',
    Coleraine: 'Northern Ireland',
    Conwy: 'Wales',
    Cookstown: 'Northern Ireland',
    Copeland: 'North West England',
    Corby: 'East Midlands',
    Cornwall: 'South West England',
    Cotswold: 'South West England',
    'County Armagh': 'Northern Ireland',
    'County Durham': 'North East England',
    'County Fermanagh': 'Northern Ireland',
    Coventry: 'West Midlands',
    Cowbridge: 'Wales',
    Craigavon: 'Northern Ireland',
    Craven: 'Yorkshire and the Humber',
    Crawley: 'South East England',
    Croydon: 'London',
    Cumbria: 'North West England',
    Dacorum: 'East of England',
    Darlington: 'North East England',
    Dartford: 'South East England',
    Daventry: 'East Midlands',
    Denbighshire: 'Wales',
    Derby: 'East Midlands',
    'Derbyshire Dales': 'East Midlands',
    Derbyshire: 'East Midlands',
    Derry: 'Northern Ireland',
    Devon: 'South West England',
    'Dinas Powys': 'Wales',
    Doncaster: 'Yorkshire and the Humber',
    Dorset: 'South West England',
    Dover: 'South East England',
    Down: 'Northern Ireland',
    Dudley: 'West Midlands',
    'Dumfries and Galloway': 'Scotland',
    Dumfriesshire: 'Scotland',
    Dunbartonshire: 'Scotland',
    'Dundee City': 'Scotland',
    Dungannon: 'Northern Ireland',
    Durham: 'North East England',
    Ealing: 'London',
    'East Ayrshire': 'Scotland',
    'East Cambridgeshire': 'East of England',
    'East Devon': 'South West England',
    'East Dorset': 'South West England',
    'East Dunbartonshire': 'Scotland',
    'East Hampshire': 'South East England',
    'East Hertfordshire': 'East of England',
    'East Lindsey': 'East Midlands',
    'East Lothian': 'Scotland',
    'East Northamptonshire': 'East Midlands',
    'East Renfrewshire': 'Scotland',
    'East Riding of Yorkshire': 'Yorkshire and the Humber',
    'East Staffordshire': 'West Midlands',
    'East Sussex': 'South East England',
    Eastbourne: 'South East England',
    Eastleigh: 'South East England',
    Eden: 'North West England',
    'Edinburgh City': 'Scotland',
    Edinburgh: 'Scotland',
    'Eilean Siar': 'Scotland',
    Elmbridge: 'South East England',
    Enfield: 'London',
    'Epping Forest': 'East of England',
    'Epsom and Ewell': 'South East England',
    Erewash: 'East Midlands',
    Essex: 'East of England',
    Exeter: 'South West England',
    Falkirk: 'Scotland',
    Fareham: 'South East England',
    Fenland: 'East of England',
    Fermanagh: 'Northern Ireland',
    Ferndale: 'Wales',
    Fife: 'Scotland',
    Flintshire: 'Wales',
    'Forest Heath': 'East of England',
    'Forest of Dean': 'South West England',
    Fylde: 'North West England',
    Gateshead: 'North East England',
    Gedling: 'East Midlands',
    'Glasgow City': 'Scotland',
    Gloucester: 'South West England',
    Gloucestershire: 'South West England',
    Gosport: 'South East England',
    Gravesham: 'South East England',
    'Great Yarmouth': 'East of England',
    'Greater London': 'London',
    'Greater Manchester': 'North West England',
    Greenwich: 'London',
    Guernsey: 'Crown Dependencies',
    Guildford: 'South East England',
    Gwynedd: 'Wales',
    Hackney: 'London',
    Halton: 'North West England',
    Hambleton: 'Yorkshire and the Humber',
    'Hammersmith and Fulham': 'London',
    Hampshire: 'South East England',
    Harborough: 'East Midlands',
    Haringey: 'London',
    Harlow: 'East of England',
    Harrogate: 'Yorkshire and the Humber',
    Harrow: 'London',
    Hart: 'South East England',
    Hartlepool: 'North East England',
    Hastings: 'South East England',
    Havant: 'South East England',
    Havering: 'London',
    Hengoed: 'Wales',
    Herefordshire: 'West Midlands',
    Hertfordshire: 'East of England',
    Hertsmere: 'East of England',
    'High Peak': 'East Midlands',
    Highland: 'Scotland',
    Hillingdon: 'London',
    'Hinckley and Bosworth': 'East Midlands',
    Horsham: 'South East England',
    Hounslow: 'London',
    Huntingdonshire: 'East of England',
    Hyndburn: 'North West England',
    Inverclyde: 'Scotland',
    'Inverness-shire': 'Scotland',
    IOM: 'Crown Dependencies',
    Ipswich: 'East of England',
    'Isle of Anglesey': 'Wales',
    'Isle of Wight': 'South East England',
    'Isles of Scilly': 'South West England',
    Islington: 'London',
    Jersey: 'Crown Dependencies',
    'Kensington and Chelsea': 'London',
    Kent: 'South East England',
    Kettering: 'East Midlands',
    Kincardineshire: 'Scotland',
    'King\'s Lynn and West Norfolk': 'East of England',
    'Kingston upon Hull': 'Yorkshire and the Humber',
    'Kingston upon Thames': 'London',
    'Kingston-upon-Hull': 'Yorkshire and the Humber',
    'Kinross-shire': 'Scotland',
    Kirkcudbrightshire: 'Scotland',
    Kirklees: 'Yorkshire and the Humber',
    Knowsley: 'North West England',
    Lambeth: 'London',
    Lanarkshire: 'Scotland',
    Lancashire: 'North West England',
    Lancaster: 'North West England',
    Larne: 'Northern Ireland',
    Leeds: 'Yorkshire and the Humber',
    Leicester: 'East Midlands',
    Leicestershire: 'East Midlands',
    Lewes: 'South East England',
    Lewisham: 'London',
    Lichfield: 'West Midlands',
    Limavady: 'Northern Ireland',
    Lincoln: 'East Midlands',
    Lincolnshire: 'East Midlands',
    Lisburn: 'Northern Ireland',
    Liverpool: 'North West England',
    'Llantwit Major': 'Wales',
    London: 'London',
    Luton: 'East of England',
    Maesteg: 'Wales',
    Magherafelt: 'Northern Ireland',
    Maidstone: 'South East England',
    Maldon: 'East of England',
    'Malvern Hills': 'West Midlands',
    Manchester: 'North West England',
    Mansfield: 'East Midlands',
    Medway: 'South East England',
    Melton: 'East Midlands',
    Mendip: 'South West England',
    Merseyside: 'North West England',
    'Merthyr Tydfil': 'Wales',
    Merton: 'London',
    'Mid Devon': 'South West England',
    'Mid Suffolk': 'East of England',
    'Mid Sussex': 'South East England',
    Middlesbrough: 'North East England',
    Midlothian: 'Scotland',
    'Milton Keynes': 'South East England',
    'Mole Valley': 'South East England',
    Monmouthshire: 'Wales',
    Moray: 'Scotland',
    'Mountain Ash': 'Wales',
    Moyle: 'Northern Ireland',
    Nairnshire: 'Scotland',
    'Neath Port Talbot': 'Wales',
    'New Forest': 'South East England',
    'Newark and Sherwood': 'East Midlands',
    'Newcastle Upon Tyne': 'North East England',
    'Newcastle-under-Lyme': 'West Midlands',
    Newham: 'London',
    Newport: 'Wales',
    'Newry and Mourne': 'Northern Ireland',
    Newtownabbey: 'Northern Ireland',
    Norfolk: 'East of England',
    'North Ayrshire': 'Scotland',
    'North Devon': 'South West England',
    'North Dorset': 'South West England',
    'North Down': 'Northern Ireland',
    'North East Derbyshire': 'East Midlands',
    'North East Lincolnshire': 'Yorkshire and the Humber',
    'North Hertfordshire': 'East of England',
    'North Kesteven': 'East Midlands',
    'North Lanarkshire': 'Scotland',
    'North Lincolnshire': 'Yorkshire and the Humber',
    'North Norfolk': 'East of England',
    'North Somerset': 'South West England',
    'North Tyneside': 'North East England',
    'North Warwickshire': 'West Midlands',
    'North West Leicestershire': 'East Midlands',
    'North Yorkshire': 'Yorkshire and the Humber',
    Northampton: 'East Midlands',
    Northamptonshire: 'East Midlands',
    Northumberland: 'North East England',
    Norwich: 'East of England',
    Nottingham: 'East Midlands',
    Nottinghamshire: 'East Midlands',
    'Nuneaton and Bedworth': 'West Midlands',
    'Oadby and Wigston': 'East Midlands',
    Oldham: 'North West England',
    Omagh: 'Northern Ireland',
    'Orkney Islands': 'Scotland',
    Orkney: 'Scotland',
    Oxford: 'South East England',
    Oxfordshire: 'South East England',
    Peeblesshire: 'Scotland',
    Pembrokeshire: 'Wales',
    Penarth: 'Wales',
    Pendle: 'North West England',
    Pentre: 'Wales',
    'Perth and Kinross': 'Scotland',
    Perthshire: 'Scotland',
    Peterborough: 'East of England',
    Plymouth: 'South West England',
    Pontyclun: 'Wales',
    Pontypridd: 'Wales',
    Poole: 'South West England',
    Porth: 'Wales',
    Porthcawl: 'Wales',
    Portsmouth: 'South East England',
    Powys: 'Wales',
    Preston: 'North West England',
    Purbeck: 'South West England',
    Reading: 'South East England',
    Redbridge: 'London',
    'Redcar and Cleveland': 'North East England',
    Redditch: 'West Midlands',
    'Reigate and Banstead': 'South East England',
    Renfrewshire: 'Scotland',
    'Rhondda Cynon Taff': 'Wales',
    Rhoose: 'Wales',
    'Ribble Valley': 'North West England',
    Richmond: 'London',
    Richmondshire: 'Yorkshire and the Humber',
    Rochdale: 'North West England',
    Rochford: 'East of England',
    'Ross and Cromarty': 'Scotland',
    Rossendale: 'North West England',
    Rother: 'South East England',
    Rotherham: 'Yorkshire and the Humber',
    Roxburghshire: 'Scotland',
    Rugby: 'West Midlands',
    Runnymede: 'South East England',
    Rushcliffe: 'East Midlands',
    Rushmoor: 'South East England',
    Rutland: 'East Midlands',
    Ryedale: 'Yorkshire and the Humber',
    Salford: 'North West England',
    Sandwell: 'West Midlands',
    Scarborough: 'Yorkshire and the Humber',
    Sedgemoor: 'South West England',
    Sefton: 'North West England',
    Selby: 'Yorkshire and the Humber',
    Selkirkshire: 'Scotland',
    Sevenoaks: 'South East England',
    Sheffield: 'Yorkshire and the Humber',
    Shepway: 'South East England',
    'Shetland Islands': 'Scotland',
    Shropshire: 'West Midlands',
    Slough: 'South East England',
    Solihull: 'West Midlands',
    Somerset: 'South West England',
    'South Ayrshire': 'Scotland',
    'South Bucks': 'South East England',
    'South Cambridgeshire': 'East of England',
    'South Derbyshire': 'East Midlands',
    'South Gloucestershire': 'South West England',
    'South Hams': 'South West England',
    'South Holland': 'East Midlands',
    'South Kesteven': 'East Midlands',
    'South Lakeland': 'North West England',
    'South Lanarkshire': 'Scotland',
    'South Norfolk': 'East of England',
    'South Northamptonshire': 'East Midlands',
    'South Oxfordshire': 'South East England',
    'South Ribble': 'North West England',
    'South Somerset': 'South West England',
    'South Staffordshire': 'West Midlands',
    'South Tyneside': 'North East England',
    'South Tyrone': 'Northern Ireland',
    'South Yorkshire': 'Yorkshire and the Humber',
    Southampton: 'South East England',
    'Southend-on-Sea': 'East of England',
    Southwark: 'London',
    Spelthorne: 'South East England',
    'St Albans': 'East of England',
    'St Helens': 'North West England',
    'St. Edmundsbury': 'East of England',
    'St. Helens': 'North West England',
    Stafford: 'West Midlands',
    'Staffordshire Moorlands': 'West Midlands',
    Staffordshire: 'West Midlands',
    Stevenage: 'East of England',
    Stirling: 'Scotland',
    Stirlingshire: 'Scotland',
    Stockport: 'North West England',
    'Stockton-on-Tees': 'North East England',
    'Stoke-on-Trent': 'West Midlands',
    Strabane: 'Northern Ireland',
    'Stratford-on-Avon': 'West Midlands',
    Stroud: 'South West England',
    'Suffolk Coastal': 'East of England',
    Suffolk: 'East of England',
    Sully: 'Wales',
    Sunderland: 'North East England',
    'Surrey Heath': 'South East England',
    Surrey: 'South East England',
    Sutherland: 'Scotland',
    Sutton: 'London',
    Swale: 'South East England',
    Swansea: 'Wales',
    Swindon: 'South West England',
    Tameside: 'North West England',
    Tamworth: 'West Midlands',
    Tandridge: 'South East England',
    'Taunton Deane': 'South West England',
    Teignbridge: 'South West England',
    'Telford and Wrekin': 'West Midlands',
    Tendring: 'East of England',
    'Test Valley': 'South East England',
    Tewkesbury: 'South West England',
    Thanet: 'South East England',
    'The Scottish Borders': 'Scotland',
    'The Vale of Glamorgan': 'Wales',
    'Three Rivers': 'East of England',
    Thurrock: 'East of England',
    'Tonbridge and Malling': 'South East England',
    Tonypandy: 'Wales',
    Torbay: 'South West England',
    Torfaen: 'Wales',
    Torridge: 'South West England',
    'Tower Hamlets': 'London',
    Trafford: 'North West England',
    Treharris: 'Wales',
    Treorchy: 'Wales',
    'Tunbridge Wells': 'South East England',
    'Tyne and Wear': 'North East England',
    Uttlesford: 'East of England',
    'Vale of White Horse': 'South East England',
    Wakefield: 'Yorkshire and the Humber',
    Walsall: 'West Midlands',
    'Waltham Forest': 'London',
    Wandsworth: 'London',
    Warrington: 'North West England',
    Warwick: 'West Midlands',
    Warwickshire: 'West Midlands',
    Watford: 'East of England',
    Waveney: 'East of England',
    Waverley: 'South East England',
    Wealden: 'South East England',
    Wellingborough: 'East Midlands',
    'Welwyn Hatfield': 'East of England',
    'West Ayrshire': 'Scotland',
    'West Berkshire': 'South East England',
    'West Devon': 'South West England',
    'West Dorset': 'South West England',
    'West Dunbartonshire': 'Scotland',
    'West Lancashire': 'North West England',
    'West Lindsey': 'East Midlands',
    'West Lothian': 'Scotland',
    'West Midlands': 'West Midlands',
    'West Oxfordshire': 'South East England',
    'West Somerset': 'South West England',
    'West Sussex': 'South East England',
    'West Yorkshire': 'Yorkshire and the Humber',
    Westminster: 'London',
    'Weymouth and Portland': 'South West England',
    Wigan: 'North West England',
    Wigtownshire: 'Scotland',
    Wiltshire: 'South West England',
    Winchester: 'South East England',
    'Windsor and Maidenhead': 'South East England',
    Wirral: 'North West England',
    Woking: 'South East England',
    Wokingham: 'South East England',
    Wolverhampton: 'West Midlands',
    Worcester: 'West Midlands',
    Worcestershire: 'West Midlands',
    Worthing: 'South East England',
    Wrexham: 'Wales',
    Wychavon: 'West Midlands',
    Wycombe: 'South East England',
    'Wyre Forest': 'West Midlands',
    Wyre: 'North West England',
    York: 'Yorkshire and the Humber',
    Zetland: 'Scotland',
}


export const UkRegionSearch: { [K: string]: UkRegion } = {}
Object.keys(UkCountyRegionMap).forEach(k => {
    UkRegionSearch[k.toLowerCase().replace(/\W/g, '')] = UkCountyRegionMap[k]
})