import styles from './ProfileHeader.module.css'
import {ProfileProps} from '../ProfilePanel'
import Distance from '../Distance/Distance'
import randomAvatars from '../random-avatar'
import AscentiSvg from '../known-avatars/ascenti.svg'
import {isAscenti} from '@peachy/utility-kit-pure'


export default function ProfileHeader(profileProps: ProfileProps) {
    const {profile} = profileProps

    const avatar = getRandomAvatar(profile.fullName)

    if (isAscenti(profile)) {
        console.log(avatar)
    }


    return (
        <header className={styles.profileHeader}>
            {isAscenti(profile)
                ? <AscentiSvg/>
                :<img src={avatar} alt={''} aria-hidden/>
            }
            <h4>{profile.fullName}</h4>
            <ul>
                <li><Distance meters={profile.distance}/></li>
                {profile.paediatric && <PaediatricIcon/>}
                {profile.gender && <GenderIcon gender={profile.gender}/>}
                {/*<li>££</li>*/}
                {/*<li>VIRTUAL</li>*/}
            </ul>
        </header>
    )
}


type GenderProps = {
    gender: 'MALE' | 'FEMALE'
}


function GenderIcon({gender}: GenderProps) {
    const genderClass = gender === 'MALE' ? styles.male : styles.female
    return (
        <li><i className={genderClass}/></li>
    )
}

function PaediatricIcon() {
    return (
        <li>
            <i className={styles.child}/>
        </li>
    )
}


function getRandomAvatar(profileName: string): string {

    let hashValue = 0
    for (let i = 0; i < profileName.length; i++) {
        hashValue += profileName.charCodeAt(i);
    }

    const avatarIndex = hashValue % 216
    return randomAvatars[avatarIndex]
}
