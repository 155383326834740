import React from 'react'
import styles from './BenefitCard.module.css'
import BenefitCard from './BenefitCard'
import {ConsultationsDiagnosticsBenefit} from '@peachy/legacy-domain'

const benefitTemplate = ConsultationsDiagnosticsBenefit()

export default function ConsultationsCard() {

    return (
        <BenefitCard header={'Consultations & Diagnostics'} className={styles.consultations} benefitTemplate={benefitTemplate}>
            <p>Don't dwell on worrying symptoms &amp; get a diagnosis – fast.</p>
            <p>Cover up to your chosen benefit limit for consultations &amp; diagnostic tests (e.g. blood tests, scans, biopsies) to obtain a diagnosis.</p>
            <ul>
                <li>Access to outpatient consultations with specialists &amp; diagnostic tests & procedures (e.g. blood
                    tests, scans, endoscopies etc)
                </li>
                <li>Cancer diagnosis</li>
            </ul>
        </BenefitCard>
    )
}
