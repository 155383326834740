import React from 'react'
import Faq from '../../../shared/FaqPanel/Faq'

export default function WhoAreWe() {

    return (
        <Faq question={'Who we are'}>
            <p>
                Peachy is a 'data controller' for the purposes of Data Protection Laws. This means we are responsible
                for deciding the reason why we collect your information and we control the processing of your
                information.
            </p>
        </Faq>

    )
}
