import React, {ReactNode, useContext, useRef} from 'react'
import styles from './FaqPanel.module.css'
import {classList} from '@peachy/utility-kit-pure'

export type FaqPanelProps = {
    heading: string,
    blurb?: ReactNode
    className?: string,
    children: ReactNode
}


const FaqPanelContext = React.createContext(null)


export default function FaqPanel({heading, blurb, children, className}: FaqPanelProps) {

    const rootClasses = classList(styles.FaqPanel, className)
    const panelRef = useRef<HTMLUListElement>()

    const faqLink = (faq) => {
        const linkedFaq = Array.from(panelRef.current.children).find((li: HTMLLIElement) => li.firstElementChild.innerHTML === faq) as HTMLLIElement
        const faqHeading = linkedFaq.firstElementChild as HTMLElement
        const expanderSection = linkedFaq.lastElementChild as HTMLElement
        if (!expanderSection.style.maxHeight) {
            faqHeading.click()
        }

        setTimeout(() => {
            const faqStyle = window.getComputedStyle(linkedFaq)
            const scrollMargin = faqStyle.getPropertyValue('scroll-margin-top').replace('px', '')
            window.scrollBy({top: linkedFaq.getBoundingClientRect().top - (+scrollMargin), behavior: 'smooth' })
        }, 300)
    }

    return (
        <FaqPanelContext.Provider value={faqLink}>
            <section className={rootClasses}>
                <h3>{heading}</h3>
                {blurb}
                <ul ref={panelRef}>{children}</ul>
            </section>

        </FaqPanelContext.Provider>
    )
}

export function useFaqLink() {
    return useContext(FaqPanelContext)
}
