import {keysOf} from '@peachy/utility-kit-pure'

export type IdCheckStatus =
    | 'FLAGGED'
    | 'FAILED'
    | 'PASSED'

export const IdCheckStatuses = keysOf<IdCheckStatus>()


export type SanctionsCheckStatus =
    | 'FLAGGED'
    | 'FAILED'
    | 'PASSED'


export const SanctionsCheckStatuses = keysOf<SanctionsCheckStatus>()

export type AlivenessCheckStatus =
    | 'FLAGGED'
    | 'FAILED'
    | 'PASSED'

export const AlivenessCheckStatuses = keysOf<AlivenessCheckStatus>()
