import ReachOutForm from './ReachOutForm/ReachOutForm'
import OurOffice from './OurOffice/OurOffice'

import styles from './ContactUs.module.css'
import PageHeading from '../shared/PageHeading/PageHeading'
import FeelingPeachy from '../shared/FeelingPeachy/FeelingPeachy'

export default function ContactUs() {

    return (
        <main className={styles.ContactUs}>
            <PageHeading className={styles.pageHeading}>
                <h1>Reach out to friendly people</h1>
                <p>If you have any questions about Peachy, or just fancy talking to some cool people, get in touch below</p>
            </PageHeading>
            <ReachOutForm/>
            <OurOffice/>
            <FeelingPeachy className={styles.FeelingPeachy}/>
        </main>
    )
}
