import {AnEvent} from '../event-kit'

export class SubscriptionCancelled extends AnEvent {
    public accountId: string
    public subscriptionId: string
    constructor(from: SubscriptionCancelled) {
        super()
        Object.assign(this, from)
    }
}
