import React from 'react'
import CaptureForm, {CaptureFieldsProps, CaptureFormProps} from '../CaptureForm'
import {plusYears, todayPlusYears, tomorrow} from '@peachy/utility-kit-pure'
import DateInput from '../../Elements/DateInput/DateInput'
import {Life} from '@peachy/legacy-domain'

const middleAge = todayPlusYears(-36)

export default function CaptureBirthday(props: CaptureFieldsProps<Life>) {
    const {instance, visitedFields} = props


    const captureEmailProps: CaptureFormProps<Life> = {
        ...props,
        buttonId: 'capture-birthday',

        headers: <>
            <h4>Almost there!</h4>
            <h4>What's your date of birth?</h4>
        </>,
        captureFields: ['birthDate']
    }

    return (
        <CaptureForm  {...captureEmailProps}>
            <DateInput minDate={Oldest} maxDate={Youngest} header={'Select your birthdate'} autofocus defaultPickerDate={middleAge} onAllVisited={() => visitedFields.toggleOn('birthDate')} dateState={instance.birthDate}/>
        </CaptureForm>
    )
}

const Oldest =  plusYears(-56, tomorrow())
const Youngest= todayPlusYears(-18)


