import GoogleMap from '../../../Map/GoogleMap'
import styles from './OurOffice.module.css'
import MapOverlay from '../../../Map/MapOverlay'
import {BOTTOM_CENTER, GeoLocation, Vector} from '../../../Map/MapKit'
import useEnsureGoogleMaps from '../../../App/useEnsureGoogleMaps'


const PEACHY_HQ: GeoLocation = {
    lat: 51.5444444,
    lng: -0.0228583
}

const offset: Vector = {
    x: 0.2,
    y: 0.2,
}

export {PEACHY_HQ}

export default function OurOffice() {

    const hasMapsApiLoaded = useEnsureGoogleMaps() === 'loaded'

    return (
        <section className={styles.OurOffice}>
            <hgroup>
                <h3>Our office</h3>
                <p>
                    <span>92 Wallis Road</span>
                    <span>Hackney Wick</span>
                    <span>London</span>
                    <span>E9 5LN</span>
                </p>
            </hgroup>
            {hasMapsApiLoaded &&
                <GoogleMap zoom={13} offset={offset} center={PEACHY_HQ} className={styles.Map}>
                    <MapOverlay location={PEACHY_HQ} anchorPoint={BOTTOM_CENTER}>
                        <i className={styles.hq}/>
                    </MapOverlay>
                </GoogleMap>
            }
        </section>
    )
}
