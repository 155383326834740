import React from 'react'
import Faq from '../../../shared/FaqPanel/Faq'

export default function Changes() {

    return (
        <Faq question={'Changes or updates to our website or Terms'}>
            <p>
                We may amend the Terms from time to time, please check the Terms every time you want to use our website
                to ensure you agree to comply with the Terms that apply at that time.
            </p>
        </Faq>
    )
}
