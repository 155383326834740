import React, {ReactNode, useRef} from 'react'
import styles from './BenefitCard.module.css'
import Toggle from '../../../elements/Toggle/Toggle'
import {classList, Draft, first, NOOP} from '@peachy/utility-kit-pure'
import {
    Benefit,
    ConsultationsDiagnosticsBenefit,
    HospitalCareBenefit,
    PlanBenefit,
    Quote,
    toClass
} from '@peachy/legacy-domain'
import BenefitLimit from './BenefitLimit/BenefitLimit'
import {useCurrentQuote, useQuoteService} from '../../QuoteController'

export type BenefitCardProps = {
    header: ReactNode
    benefitTemplate: Draft<Benefit>
    children: ReactNode
    className?: string
    canDeselect?: boolean
}

const consultationsDiagnosticsBenefit = ConsultationsDiagnosticsBenefit()
const hospitalCareBenefit = HospitalCareBenefit()

export default function BenefitCard({header, benefitTemplate, children, className, canDeselect = true}: BenefitCardProps) {

    const quoteService = useQuoteService()
    const quote = useCurrentQuote()

    const quoteClass = toClass(quote.value, Quote)

    const benefitName = benefitTemplate.name

    const planBenefit: PlanBenefit = quoteClass.request.getDefaultPlan().findBenefit(benefitName)
    const hasBenefit = !!planBenefit

    const hasLimits = benefitTemplate.availableLimits?.length  > 1

    const rootClasses = classList(styles.BenefitCard, canDeselect && styles.canToggle,  className, !hasBenefit && styles.BenefitCardOff)

    const chosenLimitRef = useRef(hasLimits ? planBenefit?.limit ?? first(benefitTemplate.availableLimits) : 0)

    const toggleBenefit = () => {
        if (!canDeselect) {
            return
        }

        const defaultPlan = quoteClass.request.getDefaultPlan()
        quoteService.editPlan(defaultPlan.id)

        if (defaultPlan.hasBenefit(benefitName)) {

            const isAtLeastOne = [consultationsDiagnosticsBenefit.name, hospitalCareBenefit.name].includes(benefitName)
            const atLeastOne = defaultPlan.benefits.filter(b => [consultationsDiagnosticsBenefit.name, hospitalCareBenefit.name].includes(b.name) )

            if (atLeastOne.length > 1 || !isAtLeastOne) {
                defaultPlan.benefits = defaultPlan.benefits.filter(b => b.name !== benefitName)
            } else {
                quoteService.cancel()
                return
            }


        } else {

            const newBenefit = toClass(benefitTemplate, PlanBenefit)


            if (hasLimits) {
                newBenefit.limit = chosenLimitRef.current
            }
            defaultPlan.benefits = [...defaultPlan.benefits, newBenefit]
        }
        quoteService.updatePlan(defaultPlan)
    }

    const updateLimit = (benefitName, limit) => {

        const defaultPlan = quoteClass.request.getDefaultPlan()
        const benefit = defaultPlan.findBenefit(benefitName)

        chosenLimitRef.current = limit

        if (benefit) {
            quoteService.editPlan(defaultPlan.id)
            defaultPlan.findBenefit(benefitName).limit = limit
            quoteService.updatePlan(defaultPlan)
        }
    }


    return (
        <article className={rootClasses} onClick={toggleBenefit}>
            {
                canDeselect && <Toggle state={hasBenefit} setState={NOOP}/>
            }
            {
                !canDeselect && <i className="fas fa-check"/>
            }

            <hgroup>
                <h3>{header}</h3>
            </hgroup>
            <section>
                {children}
            </section>
            {hasLimits &&
            <BenefitLimit benefitTemplate={benefitTemplate} updateLimit={updateLimit} limit={chosenLimitRef.current} hasBenefit={hasBenefit}/>
            }
        </article>
    )
}
