import React, {useEffect, useRef, useState} from 'react'
import useToggles, {Toggles} from '../../../hooks/useToggles'
import styles from './CaptureCard.module.css'
import {State as HookState} from '@hookstate/core'
import {CardCvcElement, CardExpiryElement, CardNumberElement, useElements} from '@stripe/react-stripe-js'
import Expander from '../../../elements/Expander/Expander'
import Button from '../../Elements/Button/Button'
import formStyles from '../../DraftLife/CaptureForm.module.css'
import {StripeElementChangeEvent} from '@stripe/stripe-js'
import {CardDetails, Quote, toClass} from '@peachy/legacy-domain'
import Spinner from '../../../elements/Spinner/Spinner'
import useScreenMetrics from '../../../hooks/useScreenMetrics'
import {classList, Draft, enumerate, KeysFrom} from '@peachy/utility-kit-pure'
// @ts-ignore
import {REACT_APP_STRIPE_PUBLISHABLE_KEY} from 'env'
import Toggle from '../../../elements/Toggle/Toggle'
import {usePaymentsService} from '../../PaymentsController'
import {useCurrentQuote} from '../../QuoteController'

import addressStyles from '../../DraftLife/CaptureAddress/CaptureAddress.module.css'
import useDataThrottle from '../../../hooks/useDataThrottle'
import AddressLookup from '../../../elements/LocationLookup/AddressLookup'
import useIntercom from '../../../hooks/useIntercom'
import {useGetPromoIfValidForPrimaryLife} from '../../../hooks/usePromos'

const CardFieldArray = ['number', 'expiry', 'code', 'billingAddress'] as const
const CardFieldNames = enumerate(CardFieldArray)
type CardFieldNameLookup = typeof CardFieldNames
type CardFieldName = keyof CardFieldNameLookup


export type CaptureCardProps = {
    onSubmit(): void
    draftCard: HookState<Draft<CardDetails>>
}

type ValidationErrors = KeysFrom<CardFieldName, string>


export default function CaptureCard({onSubmit, draftCard}: CaptureCardProps) {
    const rawQuote = useCurrentQuote()
    const intercom = useIntercom()
    const quote = toClass(rawQuote.value, Quote)

    const stripeElements = useElements()

    const paymentsService = usePaymentsService()

    const [cardBrand, setCardBrand] = useState<string>('')

    const visitedFields = useToggles<CardFieldName>(CardFieldArray)
    const [validationErrors, setValidationErrors] = useState<ValidationErrors>({})

    const [submitError, setSubmitError] = useState('')

    const [focused, setFocused] = useState<CardFieldName>()

    const [vetoSubmit, setVetoSubmit] = useState(false)

    const headers = <>
        <h4>Alright!</h4>
        <h4>Let's set up your monthly payments</h4>
    </>

    const [showSpinner, setShowSpinner] = useState(false)
    const throttledSpinner = useDataThrottle(showSpinner, 800)

    useEffect(() => intercom.trackEvent('capture-payment'), [])


    const onSubmitInternal = async () => {
        setShowSpinner(true)
        try {
            const setupPaymentIntent = await paymentsService.confirmCardPaymentIntent(stripeElements, quote, toClass(draftCard.value, CardDetails))
            draftCard.gatewayPaymentId.set(setupPaymentIntent.paymentMethodId)
            draftCard.gatewayCustomer.set(setupPaymentIntent.customer)
            onSubmit()

        } catch (error) {

            console.error('STRIPE ERROR!', error)
            intercom.trackEvent('capture-payment-error', {reason: String(error?.message ?? 'unknown')})

            setSubmitError(error?.message)
        } finally {
            setShowSpinner(false)
        }
    }


    const errorIfAny = submitError ?? getErrorForDisplay(validationErrors, visitedFields)

    const onFocus = (field: CardFieldName) => () => {
        setSubmitError(null)
        setFocused(field)
    }

    const onBlur = (field: CardFieldName) => () => {
        setFocused(null)
        visitedFields.toggleOn(field)
    }

    const onChange = (field: CardFieldName) => (changeEvent: Draft<StripeElementChangeEvent>) => {

        if (changeEvent.elementType === 'cardNumber') {
            // @ts-ignore
            setCardBrand(changeEvent.brand)
        }
        const errorIfAny = getErrorMessage(field, changeEvent)
        if (errorIfAny) {
            setValidationErrors(errors => {
                return {...errors, [field]: errorIfAny}
            })
        } else {
            if (changeEvent.complete) {
                visitedFields.toggleOn(field)
            }
            setValidationErrors(errors => {
                const newErrors = {...errors}
                delete newErrors[field]
                return newErrors
            })
        }
    }

    const exceptions: CardFieldName[] = draftCard.hasSeparateBillingAddress.value ? [] : ['billingAddress']

    const isComplete = !errorIfAny && visitedFields.areAllOn(exceptions) && !vetoSubmit

    const fontFisher = useRef<HTMLFieldSetElement>()

    const [fontSize, setFontSize] = useState<string>('1em')


    const screenWidth = useScreenMetrics().width

    useEffect(() => {
        setFontSize(window.getComputedStyle(fontFisher.current).fontSize)
    }, [screenWidth])


    const onAddressFieldChange = (value: string) => {
        const hasChanged = draftCard.billingAddress?.display?.value !== value
        if (hasChanged) {
            setVetoSubmit(true)
        } else {
            setVetoSubmit(false)
        }
    }


    return (

        <form className={formStyles.CaptureForm} onSubmit={async (e) => {
            if (isComplete) {
                await onSubmitInternal()
            }
        }}>
            <legend>
                <hgroup>{headers}</hgroup>
            </legend>

            <PromoCaptureCardBlurb/>

            <Expander expanded={!!errorIfAny} tag={'mark'}>{errorIfAny}</Expander>

            <CardNumberElement
                options={cardElementStyle('Card number', focused === 'number', fontSize, cardBrand)}
                onFocus={onFocus('number')}
                onBlur={onBlur('number')}
                onChange={onChange('number')}
            />

            <fieldset>
                <CardExpiryElement
                    options={cardElementStyle('Expiry MM/YY', focused === 'expiry', fontSize)}
                    onFocus={onFocus('expiry')}
                    onBlur={onBlur('expiry')}
                    onChange={onChange('expiry')}
                />
                <CardCvcElement
                    options={cardElementStyle('Security code', focused === 'code', fontSize)}
                    onFocus={onFocus('code')}
                    onBlur={onBlur('code')}
                    onChange={onChange('code')}
                />
            </fieldset>

            <fieldset className={styles.optIns} ref={fontFisher}>
                <label>
                    <span>Separate billing address?</span>
                    {/*<span>Billing address is the same as home address?</span>*/}
                    <Toggle className={styles.toggle} state={draftCard.hasSeparateBillingAddress.value} setState={draftCard.hasSeparateBillingAddress.set}/>
                </label>
            </fieldset>


            <Expander expanded={draftCard.hasSeparateBillingAddress.value} tag={'aside'} className={styles.billingAddress} goop={true}>
                <fieldset className={addressStyles.CaptureAddress}>

                    <AddressLookup autoFocus={false} initialSearchTerm={draftCard.billingAddress?.display?.value} residentialOnly={false} onFieldChange={onAddressFieldChange} onSelectAddress={(address) => {
                        draftCard.billingAddress.set(address)
                        setVetoSubmit(false)
                        visitedFields.toggleOn('billingAddress')

                    }} onBlur={() => visitedFields.toggleOn('billingAddress')}
                     // onFocus={onFocus('billingAddress')}

                    />
                </fieldset>
            </Expander>

            <Button id={'capture-card'} text={'Next'} onClick={onSubmitInternal} enabled={isComplete}/>

            {throttledSpinner && <Spinner/>}
        </form>
    )
}


const errorMessages = {
    name: 'Please enter the account name as it appears on the card',
    number: 'Please enter the long number from the card',
    expiry: 'Please enter the card expiry date',
    code: 'Please enter the card security code'
}


function getErrorMessage(field: CardFieldName, changeEvent: Draft<StripeElementChangeEvent>): string {

    if (changeEvent.error) {
        return changeEvent.error.message
    } else if (!changeEvent.complete) {
        return errorMessages[field]
    } else {
        return null
    }
}


function getErrorForDisplay(errors: ValidationErrors, visitedFields: Toggles<CardFieldName>) {
    const errorField = CardFieldArray.find((field) => {
        return !!errors[field] && visitedFields.on.includes(field)
    })
    return errors[errorField]
}


function cardElementStyle(placeholder: string, focused: boolean, fontSize: string, cardBrand?: string) {

    return {
        classes: {
            base: classList(styles.cardField, focused && styles.cardFieldFocus, cardBrand && styles.cardNumber, cardBrand && styles[cardBrand])
        },
        style: {
            base: {
                iconColor: '#c4f0ff',
                fontFamily: 'Poppins, sans-serif',
                lineHeight:'1.5em',
                fontSize,
                fontSmoothing: 'antialiased',
                '::placeholder': {
                    color: '#B0B8C7',
                },

            },
        },
        placeholder
    }
}

function PromoCaptureCardBlurb() {

    const promo = useGetPromoIfValidForPrimaryLife()

    if (!!promo?.captureCardBlurb) {
        return <mark>
            <span>{promo.captureCardBlurb}</span>
        </mark>
    } else {
        return null
    }
}
