import React from 'react'
import Faq from '../../../shared/FaqPanel/Faq'

export default function Availability() {

    return (
        <Faq question={'Availability of our website'}>
            <p>
                We try to make sure our website is available 24/7, however our website may be temporarily unavailable if we need to maintain or protect our site.
                This may happen without notice and applies to either all or parts of our website.
                Whilst we do not expect this to happen regularly, if it does we apologise for any inconvenience that may be caused.
            </p>
            <p>
                Please note that we will not be liable for any inconvenience or losses incurred by you if our website or any material on our website becomes unavailable at any time.
                We ask that you respect our website and material on our website, and also respect the rights of other users to enjoy our website and material.
                In the event of any breach or reasonably suspected breach by you of our Terms, your right to use our website and materials on our website will cease immediately and without notice.
            </p>
        </Faq>
    )
}
