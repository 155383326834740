import {Props} from '@peachy/utility-kit-pure'
import {SanctionsCheckEvent} from './SanctionsCheckEvent'

export class SanctionsCheckFlagged extends SanctionsCheckEvent {

    constructor(from: Props<SanctionsCheckFlagged>) {
        super(from)
        this.status = 'FLAGGED'
        this.type = this.constructor.name
    }
}