import {MrSubscriptionQuoteRequest, MrSubscriptionQuoteResponse} from '@peachy/core-domain-pure'
import {ContentBucketResponse, SubscriptionApiGateway} from '@peachy/subscription-pure'

export type SubscriptionService = Omit<SubscriptionApiGateway, 'reissueCompanyPolicyDoc'>

export function createSubscriptionService(api: SubscriptionApiGateway): SubscriptionService {

    return {
        async activateIndividualSubscription(subscriptionRequest: MrSubscriptionQuoteRequest): Promise<MrSubscriptionQuoteResponse> {
            try {
                return await api.activateIndividualSubscription(subscriptionRequest)
            } catch (e) {
                console.error(`Problem activating individual subscription`, e)
                throw Error(e.toString())
            }
        },

        async alterIndividualSubscription(subscriptionRequest: MrSubscriptionQuoteRequest): Promise<MrSubscriptionQuoteResponse> {
            try {
                return await api.alterIndividualSubscription(subscriptionRequest)
            } catch (e) {
                console.error(`Problem altering individual subscription`, e)
                throw Error(e.toString())
            }
        },

        async getIndividualQuote(subscriptionRequest: MrSubscriptionQuoteRequest): Promise<MrSubscriptionQuoteResponse> {
            try {
                return await api.getIndividualQuote(subscriptionRequest)
            } catch (e) {
                console.error(`Problem quoting individual subscription`, e)
                throw Error(e.toString())
            }
        },

        async resolveContentBucket(): Promise<ContentBucketResponse> {
            try {
                return await api.resolveContentBucket()
            } catch (e) {
                console.error(`Problem resolving content bucket`, e)
                throw Error(e.toString())
            }
        },
    }
}
