import React from 'react'
import {Link} from 'react-router-dom'
import Faq from '../../../shared/FaqPanel/Faq'

export default function Cookies() {

    return (
        <Faq question={'Cookies'}>
            <p>
                We use cookies on our website, for more information on cookies please see our <Link to={'/policies/cookies'}>Cookie Policy</Link>
            </p>
        </Faq>
    )
}
