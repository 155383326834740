import FaqPanel from '../../shared/FaqPanel/FaqPanel'
import styles from '../Policies.module.css'
import Definition from './questions/Definition'
import WhatTypes from './questions/WhatTypes'
import HowToManage from './questions/HowToManage'

export default function CookiePolicy() {
    return (
        <FaqPanel heading={'Cookie Policy'} blurb={<CookieBlurb/>} className={styles.faqPanel}>
            <Definition/>
            <WhatTypes/>
            <HowToManage/>
        </FaqPanel>
    )
}


function CookieBlurb() {
    return (
        <article className={styles.blurb}>
            <p>
                Being the creative crusaders that we are, we want to make sure you have the best experience on our
                website!
                To do this, we use cookies to understand how our customers navigate our website and find ways to improve
                the experience.
                Cookies also remember your preferences and make sure any adverts you see are more relevant to you and
                your interests.
            </p>
            <p>
                By using our website you agree that we can place cookies on your device. Click the headings below to
                read more about what cookies are, what cookies we use and why we use them, and how to manage your cookie
                settings.
            </p>
            <p>
                Visit <a href={'https://www.allaboutcookies.org'}>www.allaboutcookies.org</a> for more information on
                cookies.
            </p>
        </article>
    )
}
