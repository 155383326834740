import {Type} from 'class-transformer'
import Required from '../validation/validators/Required'
import {Address} from './Address/Address'

export class PaymentDetails {
    name?: string
    email?: string
    stripePaymentId?: string
    hasSeparateBillingAddress?: boolean = false

    @Type(() => Address)
    @Required<PaymentDetails>('', ({instance}) => instance.hasSeparateBillingAddress)
    billingAddress?: Address = undefined

    promoCode?: string
}


