import {useEffect, useRef} from 'react'


export default function useDismissible<T extends HTMLElement>(onDismiss: () => void) {

    const ref = useRef<T>(null)

    useEffect(() => {

        const handleBackGroundClick = (e) => {
            if (ref.current && !ref.current.contains(e.target)) {
                e.preventDefault()
                onDismiss()
            }
        }

        const handleEscape = (e) => {
            if (e.key === 'Escape') {
                e.preventDefault()
                onDismiss()
            }
        }

        document.addEventListener('mousedown', handleBackGroundClick)
        document.addEventListener('keydown', handleEscape)
        return () => {
            document.removeEventListener('mousedown', handleBackGroundClick)
            document.removeEventListener('keydown', handleEscape)
        }
    }, [ref])


    return ref
}



