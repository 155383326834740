import React, {ChangeEvent, useEffect, useRef, useState} from 'react'
import styles from './LocationSelector.module.css'
import {NOOP} from '@peachy/utility-kit-pure'
import PeachySelector from '../../../../../elements/branded-elements/PeachySelector/PeachySelector'
import useDataThrottle from '../../../../../hooks/useDataThrottle'
import {GeoLocation} from '../../../../../Map/MapKit'
import TextBox from '../../../../../Quote/Elements/TextBox/TextBox'
import {useIsQuerying} from '../../../../../service-stub/CareSearchController'

export type LocationSelectorProps = {
    className?: string,
    onLocationSelected?(location: GeoLocation): void
}

export default function LocationSelector({className, onLocationSelected = NOOP}: LocationSelectorProps) {

    const [enteredPlaceName, setEnteredPlaceName] = useState('')
    const [selectedPlace, setSelectedPlace] = useState<google.maps.places.AutocompletePrediction>()
    const [matchedPlaces, setMatchedPlaces] = useState<google.maps.places.AutocompletePrediction[]>()

    const [resolvedPlace, setResolvedPlace] = useState<google.maps.places.PlaceResult>()

    const matchedPlaceNames = matchedPlaces?.map(p => p.description)

    const throttledEnteredPlace = useDataThrottle(enteredPlaceName, 200)

    const [keyEvent, setKeyEvent] = useState<React.KeyboardEvent>()
    const sessionTokenRef = useRef(new google.maps.places.AutocompleteSessionToken())

    const onChangeOfEnteredPlace = (e: ChangeEvent<HTMLInputElement>) => {
        setEnteredPlaceName(e.target.value)
    }


    const onSelectPlace = (placeName, index) => {

        if (placeName !== enteredPlaceName) {
            setEnteredPlaceName(placeName)
            setSelectedPlace(matchedPlaces[index])
            setMatchedPlaces(undefined)
            lookupPlace(matchedPlaces[index].place_id, sessionTokenRef, onLocationSelected)
        }
    }

    const onEscape = () => {
        setMatchedPlaces(undefined)
    }

    useEffect(() => {
        if (throttledEnteredPlace && throttledEnteredPlace != selectedPlace?.description) {
            console.log('auto completing ' + throttledEnteredPlace)
            autoCompletePlaces(throttledEnteredPlace, setMatchedPlaces, sessionTokenRef.current)
        }

    }, [throttledEnteredPlace])

    const [isQuerying, setIsQuerying] = useIsQuerying()

    return (<>
            <label className={styles.LocationSelector}>

                <TextBox autoCapitalize placeholder={'Location'} value={enteredPlaceName} setValue={setEnteredPlaceName} onKeyDown={setKeyEvent} />
                {/*<input placeholder={'Location'} type={'text'} value={enteredPlaceName} onChange={onChangeOfEnteredPlace} onKeyDown={setKeyEvent}/>*/}

                <button onClick={() => {
                    setIsQuerying(true)
                    geoLocate((loc) => {
                        onLocationSelected(loc)
                        setIsQuerying(false)
                    }, {
                        maximumAge: 60,

                    }, () => {
                        setIsQuerying(false)
                    })

                }}>Current location
                </button>
                {matchedPlaces &&
                <PeachySelector list={matchedPlaceNames} selection={selectedPlace?.description} externalKeyboardEvent={keyEvent} className={styles.options} onSelect={onSelectPlace} onDismiss={onEscape}/>
                }
            </label>
        </>
    )
}


export type Location = {
    lat: number,
    lng: number
}

const dummyDiv: HTMLDivElement = document.createElement('div')

function geoLocate(onLocated: (l: Location) => void, options?: PositionOptions, onFailLocate?: (e: any) => void) {
    navigator.geolocation.getCurrentPosition(
        location => onLocated({lat: location.coords.latitude, lng: location.coords.longitude}),
        onFailLocate,
        options
    )
}

let placesAutoCompleteService
let placesLookupService


function lookupPlace(placeId: string, sessionTokenRef, onLocationSelected: (location: GeoLocation) => void) {
    placesLookupService = placesLookupService ?? new google.maps.places.PlacesService(dummyDiv)
    placesLookupService.getDetails({
        placeId,
        sessionToken: sessionTokenRef.current,
        fields: ['address_components', 'geometry']

    }, (result: google.maps.places.PlaceResult, status: google.maps.places.PlacesServiceStatus) => {

        onLocationSelected(result.geometry.location.toJSON())

        sessionTokenRef.current = new google.maps.places.AutocompleteSessionToken()
    })

}


function autoCompletePlaces(input: string, setMatchedAddresses, sessionToken: google.maps.places.AutocompleteSessionToken) {

    placesAutoCompleteService = placesAutoCompleteService ?? new google.maps.places.AutocompleteService()

    if (input?.length > 2) {

        const requestId = Math.random()

        try {

            placesAutoCompleteService.getPlacePredictions({
                input,
                sessionToken,
                componentRestrictions: {
                    country: 'GB'
                },

            }, (results: google.maps.places.AutocompletePrediction[], status: google.maps.places.PlacesServiceStatus) => {

                if (requestId === placesAutoCompleteService.requestId) {
                    setMatchedAddresses(status == 'OK' ? results : undefined)
                }

            })
        } catch (e) {
            console.log(e)
        }

        placesAutoCompleteService.requestId = requestId
    } else {
        setMatchedAddresses(undefined)
    }
}









