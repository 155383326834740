import { LegacyQuoteRequest, LegacyQuoteResponse, QuoteLifeRequest, QuoteLifeResponse } from '@peachy/quote-pure'

import { formatIsoDateTimeOnly, getDateAsIfLocalTimezone, keys, UK_TIMEZONE, unique, values } from '@peachy/utility-kit-pure'
import { GenderType } from '../models/Life'


export function mapQuoteRequestFromV2ToV1(
    quoteRequest: QuoteLifeRequest
): LegacyQuoteRequest {

    const individualQuotes= values(quoteRequest.lives).map(life => {

        const benefitLimits = Object.fromEntries(values(life.benefits).map(b => {
            return [b.id, b.limit ?? undefined]
        }))

        const startDates = unique(values(life.benefits).map(b => b.startDate).filter(d=> !!d))
        if (startDates.length !== 1) {
            throw 'Cannot map to V1 quote due to mixed benefit start dates'
        }
        const startDate = startDates[0]

        const getDateAsLocalDateTimeString = (date: number) => formatIsoDateTimeOnly(getDateAsIfLocalTimezone(date, UK_TIMEZONE))

        return {
            lifeId: life.id,
            birthdate: getDateAsLocalDateTimeString(life.dateOfBirth),
            coverStartDate: getDateAsLocalDateTimeString(startDate),
            gender: life.gender as GenderType,
            region: life.region,

            address: {
                display: '',
                postcode: life.postcode,
                addressComponents: [],
                location: null
            },
            benefitLimits,
            requiredBenefits: keys(life.benefits),
            includeBreakdown: true,
        }
    })

    return {
        quoteRequestId: quoteRequest.quoteRequestId,
        groupedIndividuals: individualQuotes,
    }
}


export function mapQuoteResponseFromV1ToV2(
    v2QuoteRequest: QuoteLifeRequest,
    v1QuoteResponse: LegacyQuoteResponse
) {

    const v2QuoteResponse: QuoteLifeResponse = {
        quoteResponseId: v1QuoteResponse.quoteResponseId,
        quoteResponseTimestamp: v1QuoteResponse.timestamp,
        ...v2QuoteRequest
    }

    const v1Lives = [
        v1QuoteResponse.primaryIndividual,
        v1QuoteResponse.secondaryIndividual,
        ...v1QuoteResponse.children,
        ...v1QuoteResponse.groupedIndividuals
    ].filter(l => l)

    v1Lives.forEach(v1LifeResponse => {

        const v2LifeResponse = v2QuoteResponse.lives[v1LifeResponse.lifeId]

        values(v2LifeResponse.benefits).forEach(benefit => {
            benefit.premium = {
                baseRiskCost: v1LifeResponse.benefitPriceBreakdown.a_baseRiskCost[benefit.id],
                adjustedForCorrelation: v1LifeResponse.benefitPriceBreakdown.b_adjustedForCorrelation[benefit.id],
                adjustedForExcessDiscounts: v1LifeResponse.benefitPriceBreakdown.c_adjustedForExcessDiscounts[benefit.id],
                adjustedForFinalDiscounts: v1LifeResponse.benefitPriceBreakdown.d_adjustedForFinalDiscounts[benefit.id],
                adjustedForContingency: v1LifeResponse.benefitPriceBreakdown.e_adjustedForContingency[benefit.id],
                adjustedForLossRatio: v1LifeResponse.benefitPriceBreakdown.f_adjustedForLossRatio[benefit.id],
                adjustedForIpt: v1LifeResponse.benefitPriceBreakdown.g_adjustedForIpt[benefit.id],
                adjustedForPriceResolution: v1LifeResponse.benefitPriceBreakdown.h_adjustedForPriceResolution[benefit.id],
                total: v1LifeResponse.benefitPriceBreakdown.h_adjustedForPriceResolution[benefit.id]
            }
        })
    })
    return v2QuoteResponse
}
