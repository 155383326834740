import styles from './TransparentPricing.module.css'

export default function TransparentPricing() {

    return (
        <section className={styles.TransparentPricing}>
            <h2>Transparent Pricing</h2>
            <p>You'll always know what's covered under your custom plan and we're always here to help.</p>
            <Plans/>
        </section>
    )

}


function Plans() {
    return (
        <ol className={styles.Plans}>
            <li>
                <ul>
                    <li>Remote Care</li>
                    <li>Mental Health</li>
                    <li>Therapies</li>
                </ul>
            </li>
            <li>
                <ul>
                    <li>Remote Care</li>
                    <li>Mental Health</li>
                    <li>Therapies</li>
                    <li>Consultations & Diagnostics</li>
                </ul>
            </li>
            <li>
                <ul>
                    <li>Remote Care</li>
                    <li>Mental Health</li>
                    <li>Therapies</li>
                    <li>Consultations & Diagnostics</li>
                    <li>Hospital Care</li>
                </ul>
            </li>
        </ol>
    )
}
