import 'reflect-metadata'
import {Type} from 'class-transformer'

import IsUUID from './validate/validators/IsUUID'
import IsNotEmpty from './validate/validators/IsNotEmpty'
import Required from './validate/validators/Required'
import Optional from './validate/validators/Optional'
import IsInProperty from './validate/validators/IsInProperty'
import ValidateNested from './validate/validators/ValidateNested'
import {enumerate} from '@peachy/utility-kit-pure'

export const BenefitTypes = enumerate([
    'HOSPITAL_CARE',
    'CONSULTATIONS_DIAGNOSTICS',
    'MENTAL_HEALTH',
    'THERAPIES',
    'DENTAL_OPTICAL',
    'REMOTE_CARE',
] as const)

export type BenefitType = keyof typeof BenefitTypes;

export class Plan {
    @IsUUID()
    id?: string = undefined

    @ValidateNested()
    @IsNotEmpty()
    @Type(() => PlanBenefit)
    benefits: PlanBenefit[] = []

    hasBenefit(name: string) {
        return this.benefits.some((b) => b.name === name)
    }

    findBenefit(name: string): PlanBenefit {
        return this.benefits.find((b) => b.name === name)
    }
}

export class Benefit {
    @Required()
    displayName: string = undefined

    @Required()
    name: BenefitType = undefined

    @Optional()
    availableLimits: number[] = []
}

export class PlanBenefit extends Benefit {
    @IsInProperty(
        'availableLimits',
        'Benefit limit must be chosen from those available'
    )
    @Optional()
    limit?: number = undefined
}
