import styles from './Expander.module.css'
import {ReactNode, useLayoutEffect, useRef} from 'react'

export type ExpanderProps = {
    expanded: boolean
    goop?: boolean
    tag: string
    className?: string
    children: ReactNode
}

export default function Expander({children, className = '', expanded = false, tag = 'div', goop = false} : ExpanderProps) {

    const Tag = tag as keyof JSX.IntrinsicElements

    const ref = useRef(null)
    const firstRenderRef = useRef(true)

    useLayoutEffect(() => {
        const panel = ref.current
        panel.isExpanded = expanded

        if (firstRenderRef.current) {
            firstRenderRef.current = false
        } else {
            panel.style.transition = 'max-height 0.2s';
        }

        if (expanded) {
            panel.style.maxHeight = panel.scrollHeight + 'px'
            if (goop) {
                setTimeout(() => {
                    panel.style.maxHeight = 'none'
                    panel.style.transition = 'max-height 0.2s';

                }, 250)
            }

        } else {

            if (goop) {
                panel.style.transition = 'max-height 0.2s';
                panel.style.maxHeight = panel.scrollHeight + 'px'
                requestAnimationFrame(() => {
                    panel.style.maxHeight = null
                })

            } else {
                panel.style.maxHeight = null
            }
        }

    }, [expanded, children])

    const expanderProps = {
        ref,
        className: `${styles.Expander} ${className}`
    }

    return (
        // @ts-ignore
        <Tag {...expanderProps}>
            {children}
        </Tag>
    )
}
