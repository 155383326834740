import styles from './ReachOutForm.module.css'
import TextInput from '../../../elements/TextInput/TextInput'
import {useState} from 'react'
import TextArea from '../../../elements/TextInput/TextArea'
import library from '../../../global-style/library.module.css'
import {isValidEmail} from '../../../util/validation-util'
import {classList} from '@peachy/utility-kit-pure'
import PhoneNumberInput from '../../../elements/PhoneNumberInput/PhoneNumberInput'
import {isValidPhoneNumber} from 'libphonenumber-js'


const isValidString = (name: string): boolean => {
    return name.trim().length > 0
}


export default function ReachOutForm() {

    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [question, setQuestion] = useState('')
    const [feedback, setFeedback] = useState('')

    const statusClass = (value: string, isValid: (s:string) => boolean) => {
        return value ? (isValid(value) ? styles.valid : styles.invalid) : null
    }


    const phoneClasses = classList(
        statusClass(phone, (p) => isValidPhoneNumber(p, 'GB')),
        showPrefix(phone) && styles.phonePrefix
    )

    const validEmail = isValidEmail(email)
    const validFirstname = isValidString(firstname)
    const validLastname= isValidString(lastname)
    const validPhone= isValidPhoneNumber(phone, 'GB')


    const onSubmit = (e) => {
        e.preventDefault()
        let error = ''
        if (!validFirstname) {error = 'Please enter your first name'}
        else if (!validLastname) {error = 'Please enter your last name'}
        else if (!validEmail) {error = 'Please check you have entered a valid email address'}
        else if (!validPhone) {error = 'Please check you have entered a valid UK phone number'}

        if (error) {
            setFeedback(error)
        } else {
            setFeedback('Sweet! We got your message and will be in touch soon.')
            // do the job
        }
    }


    return (
        <section className={styles.ReachOutForm}>
            <form>
                <TextInput label={'First name'} value={firstname} setValue={setFirstname} className={statusClass(firstname, isValidString)}/>
                <TextInput label={'Last name'} value={lastname} setValue={setLastname} className={statusClass(lastname, isValidString)}/>
                <TextInput label={'Email address'} value={email} setValue={setEmail} className={statusClass(email, isValidEmail)}/>
                <PhoneNumberInput phone={phone} setPhone={setPhone} className={phoneClasses}/>
                <TextArea label={'How can we help?'} value={question} setValue={setQuestion} className={statusClass(question, isValidString)}/>
                <button onClick={onSubmit} className={library.button}>Send enquiry</button>
                <span>{feedback}</span>
            </form>
        </section>
    )
}

function showPrefix(phone) {
    console.log(phone)
    return phone.trim() && phone.startsWith('+44') && !phone.startsWith('+440')
}
