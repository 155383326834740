import {last, sum} from './array-kit'
import {newUUID} from './uuid-kit'

export type DisburseResponse = {
    fundType?: string
    fundId: string
    requestedAmount: number
    dateOfDisbursment: Date,
    balanceBefore: number
    disbursed: number
    shortfall: number
    balanceAfter: number
}

export class Fund {

    private readonly _history: DisburseResponse[] = []
    readonly id: string
    readonly type?: string

    constructor(private _balance: number, config?: {id?: string, type?: string}) {
        this.id = config?.id ?? newUUID()
        this.type = config?.type
    }

    public disburse(requestedAmount: number, dateOfDisbursment = new Date()): DisburseResponse {
        const disbursed = Math.min(requestedAmount, this.balance)
        const shortfall = requestedAmount - disbursed
        const balanceBefore = this.balance
        this._balance -= disbursed
        const disbursment = {
            fundId: this.id,
            fundType: this.type,
            requestedAmount,
            dateOfDisbursment,
            disbursed,
            shortfall,
            balanceBefore,
            balanceAfter: this._balance
        }
        this._history.push(disbursment)
        return disbursment
    }

    get balance() {
        return this._balance
    }

    get history() {
        return this._history
    }

    get totalRequested() {
        return sum(this._history, it => it.requestedAmount)
    }

    get totalDisbursed() {
        return sum(this._history, it => it.disbursed)
    }

    get totalShortfall() {
        return sum(this._history, it => it.shortfall)
    }
}

export class Funds {

    private constructor(readonly funds: Fund[]) {
    }

    static wrapExisting(funds: Fund[]): Funds {
        return new Funds(funds)
    }

    static fromBalances(balances: number[]): Funds {
        return new Funds(balances.map(it => new Fund(it)))
    }

    public disburseChainingTheShortfall(amount: number, dateOfDisbursment = new Date()): DisburseResponse[] {
        const disbursments = this.funds.slice(0, 1).map(fund => fund.disburse(amount, dateOfDisbursment))

        this.funds.slice(1).forEach(fund => disbursments.push(
            fund.disburse(last(disbursments).shortfall, dateOfDisbursment)
        ))

        return disbursments
    }

    public disburseAmountFromEvery(amount: number, dateOfDisbursment = new Date()): DisburseResponse[] {
        return this.funds.map(fund => fund.disburse(amount, dateOfDisbursment))
    }

    findById(id: string) {
        return this.funds.find(it => it.id === id)
    }

    filterByType(type: string) {
        return this.funds.filter(it => it.type === type)
    }

    findFirstByType(type: string) {
        return this.filterByType(type)?.[0]
    }
}
