import React from 'react'
import Faq from '../../../shared/FaqPanel/Faq'
import {PeachyMailDpo} from '../../../../elements/branded-elements/PeachyMail'

export default function HowSecure() {
    return (

        <Faq question={'How do we keep your data secure'}>
            <p>
                We use technical and organisational measures to keep your information secure,
                including storing your information on a secure server and controlling access to your account by password and username unique to you.
            </p>
            <p>
                We use all reasonable efforts to keep your information secure, however the use of the internet is not entirely secure
                so we cannot guarantee the security of any information transferred from you or to you via the internet.
                If you have any particular concerns regarding this, please contact us at <PeachyMailDpo/>
            </p>
        </Faq>

    )
}
