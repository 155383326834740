import {defineValidator, MessageProducer, ValidateIf} from '../validation-core'

export default function IsTrue<T>(
    message?: string | MessageProducer<T>,
    validateIf?: ValidateIf<T>
) {
    return defineValidator<T>({
        name: 'IsTrue',
        validateIf,
        message:
            message ??
            (({property, bannedValue}) => `Property ${property.toString()} must be true`),
        validate({value}) {
            return value === true
        },
    })
}
