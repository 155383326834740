import {AnEvent} from '../../event-kit'
import {Props} from '@peachy/utility-kit-pure'
import {SanctionsCheckStatus} from '../../../domain/models/memorepo/statuses'

export class SanctionsCheckEvent extends AnEvent {
    constructor(from: Props<SanctionsCheckEvent>) {
        super()
        Object.assign(this, from)
    }

    complyAdvEventType: string
    complyAdvMatchStatus: string

    email: string
    cognitoId: string
    status: SanctionsCheckStatus
    s3Url: string
    complyAdvJobId: string
    complyAdvPortalUrl: string
}
