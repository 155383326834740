import {defineValidator, MessageProducer, ValidateIf} from '../validate-core'
import {dateFromIso, isIsoDate, yearsSince} from '@peachy/utility-kit-pure'

export default function MaxAge<T>(
    maxAge: number,
    message?: string | MessageProducer<T>,
    validateIf?: ValidateIf<T>
) {
    return defineValidator<T>({
        name: 'MaxAge',
        message:
            message ??
            (({property}) =>
                `Property ${property} must be less than ${maxAge} years ago`),
        messageParams: {maxAge},
        validateIf,
        validate({value}) {
            return isIsoDate(value) && yearsSince(dateFromIso(value)) <= maxAge
        },
    })
}
