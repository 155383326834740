import React from 'react'
import Faq from '../../../shared/FaqPanel/Faq'
import FaqLink from '../../../shared/FaqPanel/FaqLink'

export default function WhoShare() {

    return (

        <Faq question={'Who we share your information with'}>
            <p>
                In order to carry out some activities outlined in
                <FaqLink link={'What we use your information for'}/>,
                we may need to share your information with third parties. This includes:
            </p>
            <ul>
                <li>Providing products and services</li>
                <li>Any accredited health-care providers so that they can provide you treatment</li>
                <li>Third-party suppliers who provide products or services on our behalf</li>
                <li>The police and other law-enforcement agencies in order to help them perform their duties, or if we
                    have to share your information by law or under a court order
                </li>
                <li>People or organisations we have to or are allowed to share your information with by law, including
                    fraud-prevention companies and for safeguarding purposes
                </li>
                <li>Our insurance partners, including actuaries, auditors, solicitors, tax advisers, debt-collection
                    agencies, credit-reference agencies, fraud-detection agencies, regulators and data-protection
                    supervisory authorities
                </li>
                <li>Third-parties in relation to what marketing you have opted into (further details under <FaqLink
                    link={'Marketing'}/>)
                </li>
            </ul>
        </Faq>

    )
}
