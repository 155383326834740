import type {Class} from 'type-fest'
import 'reflect-metadata'
import _ from 'lodash-es'
import {Draft, enumerate, isArray, isObject, today, Valid} from '@peachy/utility-kit-pure'
import {instanceToInstance, instanceToPlain, plainToInstance} from 'class-transformer'

export function toClass<C>(instance: Draft<C>, classType: Class<C>): C {

    try {
        return plainToInstance(classType, instance)
    } catch (e) {
        return plainToInstance(classType, JSON.parse(JSON.stringify(instance)))
    }
}

export function toBlueprint<C>(instance: C): Draft<C> {
    return instanceToPlain(instance) as Draft<C>
}

export function clone<C>(instance: C): C {
    return instanceToInstance(instance) as C
}


export const ValidationDirectives = enumerate([
    'APPROVE_IMMEDIATELY',
    'VALIDATE_NESTED'
] as const)

export type ValidationDirective = keyof typeof ValidationDirectives


export function isValid<T extends object>(instance: T, date: Date = today(), classType?: Class<T>, context?: any): instance is Valid<T> {
    return !validate(instance, date, classType, context)
}


export function validate<T>(instance: T, date: Date = today(), classType?: Class<T>, context?: any): ValidationErrorMap<T> {
    let hasErrors = false
    const errorMap: { [_ in keyof T]?: ValidationError<T>[] } = {}

    classType = classType ?? instance.constructor as Class<T>

    knownPropertiesOf(classType).forEach((property) => {
        const validationErrors = validateProperty(instance, property, date, classType, context)
        if (validationErrors) {
            hasErrors = true
            errorMap[property] = validationErrors
        }
    })
    return hasErrors ? errorMap : null
}


export function isValidProperty<T>(instance: T, property: keyof T, date: Date, classType?: Class<T>, context?: any): boolean {
    return !validateProperty(instance, property, date, classType, context)
}

export function validateProperty<T>(instance: T, property: keyof T, date: Date, classType?: Class<T>, context?: any): ValidationError<T>[] {

    classType = classType ?? instance.constructor as Class<T>

    const value = instance[property]

    const validationErrors: ValidationError<T>[] = []

    const allValidators: Validator<T>[] = []
    Object.values(deepPropertyValidatorMap(property, classType)).forEach((validators) => {
        allValidators.push(...validators)
    })

    const contextTemplate: ValidationContext<T> = {instance, property, value, date, context}

    const errors = runValidatorsFor(allValidators, contextTemplate, context)
    if (errors) {
        validationErrors.push(...errors)
    }

    return validationErrors.length ? validationErrors : null
}


function runValidatorsFor<T>(validators: Validator<T>[], contextTemplate: ValidationContext<T>, context?: any): ValidationError<T>[] {

    const validationErrors: ValidationError<T>[] = []
    for (let validatorIndex = 0; validatorIndex < validators.length; validatorIndex++) {
        const validator: Validator<T> = validators[validatorIndex]

        const validationContext: ValidationContext<T> = {...validator.messageParams, ...contextTemplate, context}

        if (validator.validateIf && !validator.validateIf(validationContext)) {
            continue
        }

        const result = validator.validate(validationContext)

        if (!result) {
            const error: ValidationError<T> = {
                value: validationContext.value,
                validatorName: validator.name,
                validationParams: validator.messageParams,
                message: generateValidationMessage(validator, validationContext)
            }
            if (validator.messageParams) {
                error.validationParams = validator.messageParams
            }
            validationErrors.push(error)

        } else if (result === ValidationDirectives.VALIDATE_NESTED) {

            const remainingValidators = validators.splice(validatorIndex + 1)

            if (isArray(validationContext.value)) {
                const arrayValidationErrors: IndexedValidationError<any>[] = []

                // first, iterate values and run remaining validators on each
                validationContext.value.forEach((elementValue: any, i: number) => {

                    const valueValidationContext: ValidationContext<T> = {...contextTemplate, value: elementValue}
                    const elementValidationErrors = runValidatorsFor(remainingValidators, valueValidationContext, context)

                    const elementValidationError: IndexedValidationError<any> = {
                        elementIndex: i,
                        value: elementValue,
                        validatorName: validator.name,
                        message: generateValidationMessage(validator, validationContext),
                        arrayErrors: elementValidationErrors,
                    }
                    if (validator.messageParams) {
                        elementValidationError.validationParams = validator.messageParams
                    }
                    if (isObject(elementValue)) {
                        delete elementValidationError.value

                        const nestedErrorMap = validate(elementValue, contextTemplate.date, undefined, context)
                        if (nestedErrorMap) {
                            elementValidationError.nestedObjectErrors = nestedErrorMap
                        }
                    } else if (isArray(elementValue)) {
                        delete elementValidationError.value

                    }
                    if (elementValidationErrors || elementValidationError.nestedObjectErrors) {
                        arrayValidationErrors.push(elementValidationError)
                    }

                })

                if (arrayValidationErrors.length) {
                    const error: ValidationError<any> = {
                        validatorName: validator.name,
                        validationParams: validator.messageParams,
                        message: generateValidationMessage(validator, validationContext),
                        arrayErrors: arrayValidationErrors
                    }
                    if (validator.messageParams) {
                        error.validationParams = validator.messageParams
                    }
                    validationErrors.push(error)
                }

            } else if (isObject(validationContext.value)) {

                const nestedErrorMap = validate(validationContext.value, contextTemplate.date, undefined, context)
                if (nestedErrorMap) {

                    const error: ValidationError<any> = {
                        validatorName: validator.name,
                        validationParams: validator.messageParams,
                        message: generateValidationMessage(validator, validationContext),
                        nestedObjectErrors: nestedErrorMap
                    }
                    if (validator.messageParams) {
                        error.validationParams = validator.messageParams
                    }
                    validationErrors.push(error)
                }
            }

        } else if (result === ValidationDirectives.APPROVE_IMMEDIATELY) {
            break
        }
    }
    return validationErrors.length ? validationErrors : null
}


// ------------------------

export function defineValidator<T>(validator: Validator<T>) {
    // return actual decorator function
    return (prototype: Object, propertyName: string) => {
        // who's job it is to register the validator on the Class
        const classType: Class<T> = prototype.constructor as Class<T>
        validatorsFor<T>(validator.name, propertyName as keyof T, classType).push(validator)
    }
}


// ------------------------


function deepPropertyValidatorMap<T>(property: keyof T, classType: Class<T>): PropertyValidatorMap<T> {

    let aClass = classType

    let mergedValidators: PropertyValidatorMap<T> = {}

    while (aClass) {
        const levelValidators = _.omit(propertyValidatorMap(property, aClass), Object.keys(mergedValidators))

        mergedValidators = {...mergedValidators, ...levelValidators}
        aClass = superClassOf(aClass)
    }
    return mergedValidators
}


function validatorsFor<T>(validatorName: string, property: keyof T, classType: Class<T>): Validator<T>[] {
    const propertyValidators = propertyValidatorMap(property, classType)
    let validators = propertyValidators[validatorName]
    if (!validators) {
        validators = []
        propertyValidators[validatorName] = validators
    }
    return validators
}


function propertyValidatorMap<T>(property: keyof T, classType: Class<T>): PropertyValidatorMap<T> {
    const classValidators = classValidatorMapFor(classType)
    let propertyValidators = classValidators[property]
    if (!propertyValidators) {
        propertyValidators = {}
        classValidators[property] = propertyValidators
    }
    return propertyValidators
}


function classValidatorMapFor<T>(classType: Class<T>): ClassValidatorMap<T> {
    let classValidatorMap =
        Reflect.getOwnMetadata(ClassValidatorMapKey, classType)

    if (!classValidatorMap) {
        classValidatorMap = {}
        Reflect.defineMetadata(ClassValidatorMapKey, classValidatorMap, classType)
    }
    return classValidatorMap
}


function knownPropertiesOf<T>(classType: Class<T>): (keyof T)[] {
    const uniqueProperties = new Set<keyof T>()
    const properties: (keyof T)[] = []

    let aClass = classType

    while (aClass) {
        Object.keys(classValidatorMapFor(aClass)).forEach(p => {
            if (!uniqueProperties.has(p as (keyof T))) {
                uniqueProperties.add(p as (keyof T))
                properties.push(p as (keyof T))
            }
        })
        aClass = superClassOf(aClass)
    }
    return properties
}


export function superClassOf(classType: any) {
    const superClass = Object.getPrototypeOf(classType)
    return superClass === classType ? undefined : superClass
}

export function classOf<C>(instance: C): Class<C> {
    return instance.constructor as Class<C>
}


export const isString = (x: unknown): x is string => typeof x === 'string'


function generateValidationMessage<T>(validator: Validator<T>, validationContext: ValidationContext<T>): string {
    const validatorMessage = validator.message
    if (isString(validatorMessage)) {
        return validatorMessage
    } else {
        return validatorMessage(validationContext)
    }
}

// --------------------------


export type ClassValidatorMap<T> = {
    [_ in keyof T]: PropertyValidatorMap<T>
}


export type PropertyValidatorMap<T> = {
    [ValidatorName: string]: Validator<T>[]
}

export type Validator<T> = {
    name: string,
    validateIf?: ValidateIf<T>
    messageParams?: { [p: string]: any }
    message: string | MessageProducer<T>,
    validate: ValidatorFunction<T>
}


export type ValidatorFunction<T> = (context: ValidationContext<T>) => boolean | ValidationDirective
export type ValidateIf<T> = (context: ValidationContext<T>) => boolean


export type ValidationErrorMap<T> = {
    [_ in keyof T]?: ValidationError<T>[]
}

export type ValidationError<_T> = {
    value?: any
    validatorName: string
    message: string
    validationParams?: object


    arrayErrors?: ValidationError<any>[]
    nestedObjectErrors?: ValidationErrorMap<any>
}

export type IndexedValidationError<T> = ValidationError<T> & {
    elementIndex: number
}


export type MessageProducer<T> = (context: ValidationContext<T>) => string

export type ValidationContext<T> = {
    instance: T,
    property: keyof T,
    value: any
    date?: Date
    context?: any
    [Key: string]: any
}


const ClassValidatorMapKey = Symbol.for('class-validators')


