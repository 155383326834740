import {MrSubscriptionQuoteRequest} from './MrSubscriptionQuoteRequest'

export class MrSubscriptionQuoteResponse extends MrSubscriptionQuoteRequest {

    quoteResponseId: string
    quoteResponseTimestamp: number

    totalMonthlyPremium: number
    totalAnnualPremium: number

    constructor(from: MrSubscriptionQuoteResponse) {
        super(from)
        Object.assign(this, from)
    }
}
