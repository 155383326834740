import {defineValidator, MessageProducer} from '../validate-core'
import isEmail from 'validator/es/lib/isEmail'

export default function IsEmail<T>(message?: string | MessageProducer<T>) {
    return defineValidator<T>({
        name: 'IsEmail',
        message:
            message ??
            (({property}) => `Property ${property} must be a valid email address`),
        validate({value}) {
            return value && isEmail(value)
        },
    })
}
