import React, {useState} from 'react'
import styles from './AnyQuestions.module.css'
import {classList} from '@peachy/utility-kit-pure'

import library from '../../../global-style/library.module.css'

export type AnyQuestionsProps = {
    className?: string
}

export default function AnyQuestions({className}: AnyQuestionsProps) {

    const rootClasses = classList(styles.AnyQuestions, className)

    const [showModal, setShowModal] = useState(false)

    return (
        <section className={rootClasses}>
            <h2>Any Questions?</h2>
            <p>We're happy to share more details! Learn everything about your cover and how Peachy works.</p>
            <nav>
                {/*{*/}
                {/*    showModal &&*/}
                {/*    <Modal onDismiss={() => setShowModal(false)}>*/}
                {/*        <section className={styles.modalContent}>*/}
                {/*            <p>I AM A SHAPE!</p>*/}
                {/*            <button>Do not press</button>*/}
                {/*        </section>*/}
                {/*    </Modal>*/}
                {/*}*/}

                {/*<button onClick={() => setShowModal(true)} className={library.buttonFrame}>My Button</button>*/}
                <a onClick={() => {
                    // @ts-ignore
                    Intercom('show')
                }} className={library.buttonFrame}>Help</a>
                <a className={library.buttonFrame} href={`https://intercom-help.eu/peachy/en`} target={'_blank'}>FAQs</a>
            </nav>
        </section>
    )
}
