import {useCurrentQuote} from '../../QuoteController'
import {mapOldQuoteToNewDomain, Quote, toClass} from '@peachy/legacy-domain'
import styles from './SamplePlanLink.module.css'
import {useState} from 'react'
import {dateFromIso, formatIsoDateTimeOnly} from '@peachy/utility-kit-pure'
import {endOfToday, isBefore} from 'date-fns'
import {useAsyncEffect} from 'use-async-effect'
import {useWebShopServices} from '../../../service/WebShopServiceController'
import {DocGenService} from '../../../service/individual-services/DocGenService'
import {DocGenTicket, PolicyDocData} from '@peachy/core-domain-pure'

export default function SamplePlanLink() {

    const webShopServices = useWebShopServices()

    const quote = useCurrentQuote()
    const quoteClass = toClass(quote.get(), Quote)

    const [samplePlanLink, setSamplePlanLink] = useState<string>()

    useAsyncEffect(async isActive => {
        if (quoteClass.response?.quoteResponseId) {
            const link = await getSamplePlanLink(quoteClass, webShopServices.docGenService)
            if (isActive()) {

                setTimeout(() => {
                    setSamplePlanLink(link)

                }, 3000)

            }
        }

    }, [quoteClass.response?.quoteResponseId])


    return samplePlanLink
        ? <span>Download your <a href={samplePlanLink} className={styles.SamplePlanLink} target={'_blank'}>sample plan</a></span>
        : <span className={styles.disabled}>Preparing your sample plan</span>

}

async function getSamplePlanLink(quote: Quote, docGenService: DocGenService) {

    let startDate = dateFromIso(quote.request.startDate)

    if (isBefore(startDate, endOfToday())) {
        startDate = new Date()
        quote.request.startDate = formatIsoDateTimeOnly(startDate)
    }

    const [policy, plans] = mapOldQuoteToNewDomain(quote)

    const docTicket: DocGenTicket<PolicyDocData> = {
        templateName: 'PolicyDoc',
        docData: {
            policy,
            plans,
            domain: 'peachy.health',
            isSample: true
        }
    }

    const ticketId = await docGenService.preparePdfTicket(docTicket)

    const primaryLife = quote.request.getPrimaryLife()

    const policyDocFilename = `PeachyPlan-${primaryLife.firstname}${primaryLife.lastname}.pdf`

    return docGenService.getPdfLink(ticketId.ticketId, policyDocFilename)
}
