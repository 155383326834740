import React from 'react'
import {Link} from 'react-router-dom'
import library from '../../../global-style/library.module.css'
import ActionPanel from '../../shared/ActionPanel/ActionPanel'
import {classList} from '@peachy/utility-kit-pure'
import style from './JustGotPeachy.module.css'
import PageHeading from '../../shared/PageHeading/PageHeading'

export default function JustGotPeachy() {
    return (
        <ActionPanel className={style.JustGotPeachy}>
            <PageHeading className={style.heading}>
                <h1>Team health just got Peachy</h1>
                <p>Simple, digital health insurance you can trust. Get set up instantly and add teammates as you go.</p>
            </PageHeading>
            <nav>
                <Link className={classList(library.button, library.buttonBordered)} to={'/register-interest'}>Interested?</Link>
                <Link className={classList(library.button, library.buttonBordered)} to={'/how-it-works'}>How it works</Link>
            </nav>
        </ActionPanel>
    )
}


