import {API} from '@aws-amplify/api'
import {
    InviteIndividualUserErrorResponse,
    InviteIndividualUserRequest,
    InviteIndividualUserResponse
} from '@peachy/iam-pure'

export class IamUserService {
    constructor(readonly api: typeof API) { }

    async reInvite(email: string): Promise<InviteIndividualUserResponse | InviteIndividualUserErrorResponse> {
        const httpRequest = {
            headers: {},
            body: {
                email
            } as InviteIndividualUserRequest,
        }
        return this.api.post(apiName, `/reInvite-user`, httpRequest)
    }
}

const apiName = 'IamUnAuthApi'
