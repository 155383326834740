import React from 'react'
import Faq from '../../../shared/FaqPanel/Faq'
import FaqLink from '../../../shared/FaqPanel/FaqLink'

export default function Definition() {

    return (
        <Faq question={'Definition of a cookie'}>
            <p>
                A less tasty version of its namesake, a cookie is a text file that contains small amounts of information
                which will be downloaded to your device when you enter a website. Some cookies will be stored on your
                computer for the duration of the session, and some will be stored permanently, these are explained
                further under <FaqLink link={'What types of cookie we use'}/>
            </p>
            <p>
                Accepting our cookies is a condition of using the website, if you do not accept these cookies, we can't
                guarantee our website will be the best it can be.
            </p>
        </Faq>
    )
}
