import React from 'react'
import Faq from '../../../shared/FaqPanel/Faq'
import {PeachyMailHelp} from '../../../../elements/branded-elements/PeachyMail'

export default function Linking() {

    return (
        <Faq question={'Linking to our website'}>
            <p>
                You may link to our homepage, provided you do so in a way that is fair and legal, and does not damage
                our reputation or take advantage of it. Where none exists, you must not establish a link in such a way
                as to suggest any form of association, approval or endorsement on our part. You must not establish a
                link in any website that it not owned by you.
            </p>
            <p>
                We reserve the right to withdraw linking permission without notice. If you wish to establish a link to
                our website other than what is set out above, please contact us at <PeachyMailHelp/>
            </p>
        </Faq>
    )
}
