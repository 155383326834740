import {
    AdviceCounsellingIcon,
    ConsultationsDiagnosticsIcon,
    DentalOpticalIcon,
    HospitalCareIcon,
    MentalHealthIcon,
    TherapiesIcon,
    VirtualGpIcon
} from '@peachy/assets'
import {Component, Match, Switch} from 'solid-js'

export const BenefitIcon: Component<{ benefitId: string, class?: string }> = (props) => {
    const iconClass = props.class ?? ''

    return <Switch>
        <Match when={props.benefitId === 'ADVICE_COUNSELLING'}><AdviceCounsellingIcon class={iconClass}/></Match>
        <Match when={props.benefitId === 'CONSULTATIONS_DIAGNOSTICS'}><ConsultationsDiagnosticsIcon class={iconClass}/></Match>
        <Match when={props.benefitId === 'DENTAL_OPTICAL'}><DentalOpticalIcon class={iconClass}/></Match>
        <Match when={props.benefitId === 'HOSPITAL_CARE'}><HospitalCareIcon class={iconClass}/></Match>
        <Match when={props.benefitId === 'MENTAL_HEALTH'}><MentalHealthIcon class={iconClass}/></Match>
        <Match when={props.benefitId === 'THERAPIES'}><TherapiesIcon class={iconClass}/></Match>
        <Match when={props.benefitId === 'VIRTUAL_GP'}><VirtualGpIcon class={iconClass} /></Match>
    </Switch>
}
