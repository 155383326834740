import React, {useEffect, useRef, useState} from 'react'
import styles from './Markers.module.css'
import MapOverlay from '../../../../../Map/MapOverlay'
import {BOTTOM_CENTER} from '../../../../../Map/MapKit'
import useDataBuffer from '../../../../../hooks/useDataBuffer'
import {CareSearchResults} from '../../../../../service-stub/care-search-domain'
import {areSame} from '../../../../../util/util'
import {classList} from '@peachy/utility-kit-pure'
import {useHovered} from '../../../../../service-stub/CareSearchController'
import LocationPin from '../../../../../global-style/icons/icons-black/alt-location-pin.svg'
import {Practice} from '@peachy/legacy-domain'

export type MarkersProps = {
    searchResults: CareSearchResults

}

type AnimState = 'drop' | 'pop'

export default function Markers({searchResults}: MarkersProps) {

    const [bufferedResults, advanceBuffer, buffer] = useDataBuffer(searchResults?.profiles)
    const [animState, setAnimState] = useState<AnimState>('drop')

    const locationIds = new Set<string>()
    const locations: Practice[] = []

    const [hovered] = useHovered()

    searchResults?.profiles.forEach(c => {
        c.practices.forEach((p, i) => {
            const key = [p.location.lat, p.location.lng, p.name].join('|')
            if (locationIds.has(key)) {
                return
            }
            if (!p.location?.lat) {
                return
            }
            locationIds.add(key)
            locations.push(p)
        })
    })

    const hasPopped = (index: number) => {
        if (index === locations.length-1) {
            setAnimState('drop')
            advanceBuffer()
        }
    }

    useEffect(() => {
        if (buffer.length > 1) {
            if (searchResults?.profiles.length) {
                setAnimState('pop')
            } else {
                advanceBuffer()
            }
        } else {
            setAnimState('drop')
        }

    }, [buffer])

    let markers = []
    if (locations) {
        markers = locations.map((l, i) => {

            const isHovered = hovered?.practices.some(p =>
                (p.name === l.name) && areSame(p.location, l.location)
            )

            return (
                <MapOverlay key={i} location={l.location} targetPane={'overlayMouseTarget'} anchorPoint={BOTTOM_CENTER}>
                    <Marker count={locations.length} name={l.name} index={i} animState={animState} hasPopped={hasPopped} isHovered={isHovered}/>
                </MapOverlay>
            )
        })
    }
    return <>{markers}</>
}


export type MarkerProps = {
    index: number
    name: string
    count: number
    animState: 'drop' | 'pop',
    hasPopped(i: number): void
    isHovered: boolean
}

function Marker({index, name, count, animState, hasPopped, isHovered}: MarkerProps) {

    const markerRef = useRef<HTMLElement>()

    useEffect(() => {

        if (animState === 'drop') {
            dropAnim(markerRef.current, index, count)
        } else if (animState === 'pop') {
            popAnim(markerRef.current, index, count).finished.then(() => {
                hasPopped(index)
            })
        }

    }, [animState])

    const classes = classList(styles.Marker, isHovered && styles.hovered)

    return (
        <i ref={markerRef} className={classes}>
            <LocationPin/>
            <span>{name}</span>
        </i>
    );
}


function dropAnim(marker, index, count) {

    const delay = (500 / count)

    return marker.animate([
        {transform: 'translateY(-1000%)', opacity: 0},
        {transform: 'translateY(0)', opacity: 1}

    ], {
        duration: 200,
        easing: 'cubic-bezier(0.5, 0, 0.8, 1.3)',
        delay: 500 + (delay * index),
        fill: 'forwards'
    })
}


function popAnim(marker, index, count) {

    const delay = (500 / count)

    return marker.animate([
        {transform: 'scale(1.5)', opacity: 0}
    ], {
        duration: 200,
        easing: 'cubic-bezier(0.5,-0.5 ,0.8, 0)',
        delay: (delay * index),
        fill: 'forwards',
        // composite: 'add'
    })
}


