import {useLayoutEffect, useState} from 'react'


export type Breakpoint = [name: string, point: number]


export default function useScreenMetrics(breakpoints?: Breakpoint[]) {

    const [metrics, setMetrics] = useState(getScreenMetrics(breakpoints))


    useLayoutEffect(() => {
        const handleResize = () => setMetrics(getScreenMetrics(breakpoints))
        window.addEventListener('resize', handleResize, {passive:true})
        return () => window.removeEventListener('resize', handleResize)

    }, [])

    return metrics
}

const getScreenMetrics = (breakpoints: Breakpoint[]) => {
    const width = window.innerWidth
    const height = window.innerHeight
    const aspectRatio = width / height
    const isPortrait = aspectRatio < 1
    const screenMetrics = {width, height, aspectRatio, isPortrait, isLandscape: !isPortrait, breakpoint: undefined}

    if (breakpoints) {
        for (const breakpoint of breakpoints) {
            const [name, point] = breakpoint
            if (point > width) {
                screenMetrics.breakpoint = name
                break
            }
        }
    }
    return screenMetrics
}


