import React from 'react'
import styles from './BenefitCard.module.css'
import BenefitCard from './BenefitCard'
import {TherapiesBenefit} from '@peachy/legacy-domain'


const benefitTemplate = TherapiesBenefit()


export default function TherapiesCard() {

    return (
        <BenefitCard header={'Therapies'} className={styles.therapies} benefitTemplate={benefitTemplate}>
            <p>We all need a little help sometimes – whether it's a pulled hamstring from our daily run to advice about our nutrition.</p>
            <p>Cover for treatment (benefit limit: £2,500 per plan year) from</p>
            <ul>
                <li>A physiotherapist</li>
                <li>An osteopath</li>
                <li>A chiropractor</li>
                <li>An acupuncturist</li>
                <li>A chiropodist / podiatrist; or</li>
                <li>A dietitian (up to 2 sessions per plan year)</li>
            </ul>
        </BenefitCard>
    )
}
