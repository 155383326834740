import React from 'react'
import styles from './Toggle.module.css'
import {classList} from '@peachy/utility-kit-pure'

export type ToggleProps = {
    className?: string
    state: boolean
    setState(f:(b:boolean) => boolean): void
}

export default function Toggle({className, state, setState}: ToggleProps) {

    const rootClasses = classList(styles.Toggle, className)

    const onKeyDown = (e) => {

        if (['ArrowDown', 'ArrowRight'].includes(e.key)) {
            setState(s => true)
        } else if (['ArrowUp', 'ArrowLeft'].includes(e.key)) {
            setState(s => false)
        }
    }

    return (
        <input onKeyDown={onKeyDown} onChange={() => setState(s => !s)} checked={state} type={'checkbox'} className={rootClasses}/>
    )
}

