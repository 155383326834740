import {Link} from 'react-router-dom'
import React from 'react'
import Faq from '../shared/FaqPanel/Faq'
import FaqPanel from '../shared/FaqPanel/FaqPanel'

export default function AboutOurPlansFaqs() {
    return (
        <FaqPanel heading={'About our plans'}>
            <Faq question={'How do I join Peachy?'}>
                <p>You can craft and buy the perfect plan <Link to={'#'}>here</Link> - in minutes!</p>
            </Faq>
            <Faq question={'Can anyone join Peachy?'}>
                <p>
                    To join Peachy you must be a UK resident aged 18-55 when cover starts. You must also be
                    registered continuously with a UK GP for at least 6 months or be able to provide your full
                    medical records in English.
                </p>
            </Faq>
            <Faq question={'How much does it cost?'}>
                <p>
                    Creating your very own Peachy plan puts you in control of the price. The price is determined by
                    the level of cover and additional extras you choose, as well as factors such as your age and
                    where you live. To find out how much you would pay, you can <Link to={'#'}>get a quote</Link> in
                    a few clicks!
                </p>
            </Faq>
            <Faq question={'Can I tailor my cover?'}>
                <p>
                    Yes. Our range of healthcare options puts you in control of creating the right plan for you.
                </p>
            </Faq>
            <Faq question={'Do I need to fill in a medical questionnaire to join Peachy?'}>
                <p>
                    No, we've made it easy to get a plan! We have a simple exclusion of pre-existing conditions in
                    the 3 years before cover starts.
                </p>
            </Faq>
            <Faq question={'Do you offer advice?'}>
                <p>
                    No. We can help you decide - but not tell you what to buy.
                    We provide information about what is and isn't covered by Peachy plans and answer any questions
                    to help you decide what you want and need for a health insurance plan. As we sell our own
                    products, we don't review the whole of the market, so we can't give you a reccomendation or
                    advice!
                </p>
            </Faq>
            <Faq question={'How do I know what my plan covers?'}>
                <p>
                    You get to see a summary of your cover and a preview of your customized plan before you pay.
                    You'll be able to edit your cover, and other plan settings such as annual limits, start dates,
                    and more - all before you finalize your plan. After payment, we will instantly issue your final
                    plan and send it to you by email.
                </p>
            </Faq>
            <Faq question={'How do you calculate my premium?'}>
                <p>
                    Premiums are calculated individually for each member and are based on a number of different
                    factors including age, where you live and plan options you choose.
                </p>
            </Faq>
            <Faq question={'How do I pay for my plan?'}>
                <p>
                    After reviewing your plan summary and having an opportunity to look over the plan documents,
                    you'll be able to pay using your credit or debit card. We will charge your first payment after
                    we've issued your plan, regardless of the plan's effective date. Thereafter, you'll be charged
                    each month on the day your plan became active.
                </p>
            </Faq>
            <Faq question={'What if I miss a premium payment?'}>
                <p>
                    If your payment method is declined, we'll ask you to provide us with a different one. We will
                    keep reminding you to sort it, but eventually we'll have to cancel your plan - we'll warn you
                    first. Please note: if you miss your very first premium payment, your plan will retroactively be
                    considered void (no cover).
                </p>
            </Faq>
            <Faq question={'Can I add my spouse, partner or children to my plan?'}>
                <p>
                    Yes. You can add them at anytime. They will need to be UK residents, registered continuously
                    with a UK GP for at least 6 months or be able to provide full medical records in English and
                    live at the same address as you. Children can join the plan if they are aged 25 or under at
                    their cover start date.
                </p>
            </Faq>
            <Faq question={'When does my plan renew?'}>
                <p>
                    Our plans auto-renew every month unless you choose to cancel.
                </p>
            </Faq>
            <Faq question={'What are annual limits?'}>
                <p>
                    Depending on how you craft your plan, some benefits may come with annual limits. An annual limit
                    lasts for 12 months from your cover start date and refreshes every 12 months thereafter – assuming you
                    remain on continuous cover from the start of your plan.
                </p>
            </Faq>
            <Faq question={'Can I change my cover after I have joined?'}>
                <p>
                    Yes. If you'd like to change your level of cover, just drop us a note at and we'll get back to
                    you. If you make a change, claims paid in the current plan year under your old level of cover
                    will count towards the annual limit for your new level of cover. Any increase in cover may be
                    subject to new terms. Changes may not be available, for example, if you are in the middle of a
                    claim or are about to start a claim.
                </p>
            </Faq>
            <Faq question={'How can I cancel my plan?'}>
                <p>
                    You can cancel your plan at any time. You plan will end on the last day covered by your last
                    premium payment to us. And if you ever want to come back? No problem! You can create a new plan.
                    Know that our prices change from time to time, so your new plan may come with a different price
                </p>
            </Faq>
        </FaqPanel>
    )
}
