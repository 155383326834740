import React, {ReactNode, useContext, useState} from 'react'
import {useState as useHookState} from '@hookstate/core'
import {Auth} from '@aws-amplify/auth'
import {createIamService, IamService, IamStageNames} from '@peachy/iam-client'

const IamContext = React.createContext<IamService | null>(null)


type IamControllerProps = {
    children: ReactNode,
}

export default function IamController({children}: IamControllerProps) {

    const [iamService, _] = useState(() => {
        const iamService = createIamService(Auth)
        iamService.getCurrentIamStage().set({stage: IamStageNames.Anon})
        return iamService
    })

    return (
        <IamContext.Provider value={iamService}>
            {children}
        </IamContext.Provider>
    )
}

export function useIamStage() {
    const iamService = useContext(IamContext)
    return useHookState(iamService!.getCurrentIamStage())
}

export function useIamService() {
    return useContext(IamContext)!
}

export function useCheckedUsernames() {
    const iamService = useContext(IamContext)
    return useHookState(iamService.getCheckedUsernames())
}
