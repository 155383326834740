

export type LifecycleStatus =
    | 'ACTIVE'
    | 'CANCELLED'


export type HasLifecycleStatus = {
    id: string
    status: LifecycleStatus
    startDate?: number
    endDate?: number
    transfer?: TransferDetails
    cancellationReason?: string
    nextRenewalDate?:number
    lastRenewalDate?:number
}


export type TransferDetails = {
    in?: {
        date: number,
        from: string,
        reason: string,
    }
    out?: {
        date: number,
        to: string,
        reason: string,
    }
}


export function isCancelled(item: HasLifecycleStatus) {
    return item.status === 'CANCELLED'
}
export function isActive(item: HasLifecycleStatus) {
    return item.status === 'ACTIVE'
}
