export type Point = { x: number, y: number }
export type Dimensions = { width: number, height: number }
export type Bounds = Point & Dimensions
export type Frame = Bounds

type Vector = Point

type CenteredFrame = {
    cy: number
    cx: number
    width: number
    height: number
}

// TODO move out of here
function xor(a: any, b: any) {
    const l = !!a
    const r = !!b
    return (l && !r) || (!l && r)
}

export function mapPoint(point: Point, fromDims: Dimensions, toDims: Dimensions, coverOrContain: 'COVER' | 'CONTAIN') {
    return mapPoints([point], fromDims, toDims, coverOrContain)[0]
}

function mapPoints(points: Point[], fromDims: Dimensions, toDims: Dimensions, coverOrContain: 'COVER' | 'CONTAIN'): Point[] {

    const fromAspectRatio = aspectRatio(fromDims)
    const toAspectRatio = aspectRatio(toDims)

    const fromCenter: Vector = {x: fromDims.width / 2, y: fromDims.height / 2}
    const toCenter: Vector = {x: toDims.width / 2, y: toDims.height / 2}

    let scale: number

    const scaleHeight = fromAspectRatio > toAspectRatio

    if (xor(scaleHeight, coverOrContain === 'COVER')) {
        scale = toDims.height / fromDims.height
    } else {
        scale = toDims.width / fromDims.width
    }

    return points.map(fromPoint => {
        const fromPointCenterVector = subtractVectors(fromPoint, fromCenter)

        const scaled = scaleVector(fromPointCenterVector, scale)
        const offset = addVectors(scaled, toCenter)
        return offset
    })
}

export function isPointInBounds(point: Point, bounds: Bounds): boolean {
    return bounds.x <= point.x && point.x <= (bounds.x + bounds.width)
            && bounds.y <= point.y && point.y <= (bounds.y + bounds.height)
}


export function arePointsInBounds(points: Point[], bounds: Bounds): boolean {
    return isFrameInBounds(boundingBoxFor(points), bounds)
}

function isFrameInBounds(frame: Frame, bounds: Bounds): boolean {
    if (frame) {
        const boundsTopLeft = {x: bounds.x, y: bounds.y}
        const boundsBottomRight = {
            x: bounds.x + bounds.width,
            y: bounds.y + bounds.height,
        }
        const frameTopLeft = {x: frame.x, y: frame.y}
        const frameBottomRight = {
            x: frame.x + frame.width,
            y: frame.y + frame.height,
        }
        return (
            boundsTopLeft.x < frameTopLeft.x &&
            frameBottomRight.x < boundsBottomRight.x &&
            boundsTopLeft.y < frameTopLeft.y &&
            frameBottomRight.y < boundsBottomRight.y
        )
    } else {
        return false
    }
}

export function boundingBoxFor(points: Point[]): Bounds {
    if (points?.length) {
        const xs = points.map((p) => p.x)
        const ys = points.map((p) => p.y)

        const x = Math.min(...xs)
        const y = Math.min(...ys)
        const width = Math.max(...xs) - x
        const height = Math.max(...ys) - y
        return {x, y, width, height}
    }
}

export function scaleFrame(frame: Frame, factor: number): Frame {
    return {
        x: (frame?.x ?? 0) * factor,
        y: (frame?.y ?? 0) * factor,
        width: (frame?.width ?? 0) * factor,
        height: (frame?.height ?? 0) * factor,
    }
}

export function scaleFrameFromCenter(frame: Frame, factor: number): Frame {
    const center = frameCenter(frame)
    let {x, y, width, height} = frame
    width *= factor
    height *= factor
    const scaledPosition = addVectors(
        scaleVector(subtractVectors({x, y}, center), factor),
        center,
    )
    return {...scaledPosition, width, height}
}

function frameCenter(frame: Frame): Point {
    return {
        x: frame.x + frame.width / 2,
        y: frame.y + frame.height / 2,
    }
}

export function aspectRatio(frame: Dimensions): number {
    return frame.width / frame.height
}

export const origin = (): Point => zeroVector()
export const zeroVector = (): Vector => ({x: 0, y: 0})

export function centerBoxToCornerBox(centerBox: CenteredFrame) {
    if (centerBox) {
        const {cx, cy, width, height} = centerBox
        return {x: cx - width / 2, y: cy - height / 2, width, height}
    }
}

export function lengthOfVector(v: Vector): number {
    const x2 = v.x * v.x
    const y2 = v.y * v.y
    return Math.sqrt(x2 + y2)
}

export const addVectors = (a: Vector, b: Vector): Vector => {
    return {
        x: a.x + b.x,
        y: a.y + b.y,
    }
}

export const subtractVectors = (a: Vector, b: Vector): Vector => {
    return {
        x: a.x - b.x,
        y: a.y - b.y,
    }
}

export function scaleVector(v: Vector, scale: number): Vector {
    return {
        x: v.x * scale,
        y: v.y * scale,
    }
}

export function distanceBetweenPoints(a: Point, b: Point): number {
    const x = b.x - a.x
    const y = b.y - a.y
    const x2 = x * x
    const y2 = y * y
    return Math.sqrt(x2 + y2)
}

export function travelVector(fromPointCloud: Point[], toPointCloud: Point[]): Vector {
    if (fromPointCloud?.length === toPointCloud.length) {
        return addMultipleVectors(
            fromPointCloud.map((a, i) => {
                return subtractVectors(toPointCloud[i], a)
            }),
        )
    } else {
        return zeroVector()
    }
}

function addMultipleVectors(vectors: Vector[]): Vector {
    return vectors.reduce((sum, v) => addVectors(sum, v), zeroVector())
}
