const originalNow = Date.now
const actualNow = Date.now.bind(originalNow)


export type TimeMachine = {
    setNow(timestamp: number): void
    resetNow(): void
}

export function timeMachine(): TimeMachine {
    return {
        setNow(timestamp: number) {
            const nowNow = actualNow()
            const offset = nowNow - timestamp
            Date.now = () => {
                return actualNow() - offset
            }
        },

        resetNow() {
            Date.now = originalNow
        }
    }
}
