import React, {useEffect} from 'react'
import styles from './CaptureOtherLife.module.css'
import {useHookstate} from '@hookstate/core'
import {Life, LifeType, Quote, toBlueprint, toClass, validate} from '@peachy/legacy-domain'
import {classList, Draft, Fields} from '@peachy/utility-kit-pure'
import {CaptureFieldsProps} from '../../DraftLife/CaptureForm'
import useToggles from '../../../hooks/useToggles'
import {useCurrentQuote, useQuoteService} from '../../QuoteController'
import SecondaryLife from './SecondaryLife'

export type CaptureOtherLifeProps = {
    lifeType: LifeType
    editLifeId?: string
    onDismiss(): void
    className?: string
}
const otherLifeFields: Fields<Life>[] = ['firstname', 'lastname', 'gender', 'birthDate', 'email', 'ukResidentConfirmed']

export default function CaptureOtherLife({className, lifeType, onDismiss, editLifeId}: CaptureOtherLifeProps) {

    const quoteService = useQuoteService()
    const quote = useCurrentQuote()
    const quoteClass = toClass(quote.value, Quote)

    const address = quoteClass.request.getPrimaryLife().address

    const rootClasses = classList(styles.CaptureOtherLife, className)

    const draftLife = useHookstate<Draft<Life>>({
        type: lifeType,
        address
    })

    const visitedFields = useToggles<Fields<Life>>(otherLifeFields)
    const validationErrors = validate(toClass(draftLife.value, Life))

    const captureProps: CaptureFieldsProps<Life> = {
        instance: draftLife,
        visitedFields,
        validationErrors,
        onSubmit() {
            if (draftLife.id.value) {
                if (quoteService.updateLife(draftLife.value)) {
                    onDismiss()
                }
            } else {
                if (quoteService.addLife(draftLife.value)) {
                    onDismiss()
                }
            }
        }
    }

    useEffect(() => {
        if (editLifeId) {
            const editLife = quoteClass.request.findLife(editLifeId)
            if (editLife) {
                draftLife.set(toBlueprint(editLife))
            }
        }

    }, [editLifeId])

    return (
        <section className={rootClasses}>
            <Cancel onDismiss={onDismiss}/>
            <SecondaryLife {...captureProps}/>
        </section>
    )
}


const Cancel = ({onDismiss}) => <button className={classList('fal fa-times', styles.cancel)} onClick={(e) => {
    console.log('Dismiss button')
    onDismiss()
} }>X</button>
