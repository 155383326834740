import {ROOT_DOMAIN} from '../App/App'
import PeachyLogo from '../Logo/PeachyLogo'
import library from '../global-style/library.module.css'
import React from 'react'
import styles from './CrapNav.module.css'
import {classList} from '@peachy/utility-kit-pure'

export function CrapNav() {

    const buttonClass = classList(library.button, styles.signUpButton)

    return (
        <nav className={styles.CrapNav}>
            <ul>
                <li><a href={`https://www.${ROOT_DOMAIN}/how-it-works`}>How it works</a></li>
                <li><a href={`https://www.${ROOT_DOMAIN}/for-business`}>For business</a></li>
                <li><a href={'https://intercom-help.eu/peachy/en'}>FAQs</a></li>
                <li><a href={`https://www.${ROOT_DOMAIN}/about`}>About</a></li>

            </ul>
            <ul>
                <li>
                    <a href={`https://www.${ROOT_DOMAIN}`}>
                        <PeachyLogo/>
                    </a>
                </li>
            </ul>
            <ul>
                <li><a href={'get-care'}>Get care</a></li>
                <li><a className={buttonClass} href={'quote'}>Get a quote</a></li>
            </ul>
        </nav>
    )
}