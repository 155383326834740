import React from 'react'
import styles from './WhatsNotCovered.module.css'
import {classList} from '@peachy/utility-kit-pure'
import Exclusion from './Exclusion/Exclusion'

export type WhatsNotCoveredProps = {
    className?: string
}

export default function WhatsNotCovered({className}: WhatsNotCoveredProps) {

    const rootClasses = classList(styles.WhatsNotCovered, className)

    return (
        <section className={rootClasses}>
            <h2>What's not covered</h2>
            <ul>
                <Exclusion name={'Pre-existing conditions'}>
                    <ul>
                        <li>
                            A pre-existing condition is any medical condition (whether diagnosed or not) that
                            you’ve received medication, advice or treatment for, have experienced symptoms of
                            or were aware existed in the three years before the cover start date
                            (or where applicable, the date you increased your benefit level or added a benefit to your plan).
                        </li>
                        <li>
                            We don't cover pre-existing conditions that you’ve had unless you've been treatment
                            and symptom free for two continuous years while with Peachy
                            (or where applicable, the date you increased your benefit level or added a benefit to your plan).
                        </li>
                        <li>
                            This exclusion does not apply to Virtual GP and Dental & Optical (if you add this benefit).
                        </li>
                    </ul>
                </Exclusion>
                <Exclusion name={'Chronic conditions'}>

                    <span>A chronic condition is a disease, illness or injury that has one or more of the following characteristics:</span>
                    <ul>
                        <li>It needs on-going or long-term monitoring through consultations, examinations, check-ups
                            and/or tests
                        </li>
                        <li>It needs on-going or long-term control or relief of symptoms</li>
                        <li>It requires your rehabilitation or for you to be specially trained to cope with it</li>
                        <li>It continues indefinitely</li>
                        <li>It has no known cure</li>
                        <li>It comes back or is likely to come back</li>
                    </ul>

                    <span>Examples of chronic conditions include diabetes, asthma and multiple sclerosis.</span>
                    <span>We don’t cover treatment for chronic conditions including investigations, regular
                        monitoring or consultations under any benefit except for Virtual GP and Dental & Optical.</span>

                    <span>We will cover treatment of an acute flare-up of a chronic condition providing this
                        is not part of the normal recurring nature of the condition & is required to
                        return you to your state of health immediately before the acute flare-up</span>

                </Exclusion>
                <Exclusion name={'Other'}>
                    <span>Here are some of the other types of things we don't cover. Full details of all the things we don't cover can be found in your plan.</span>
                    <ul>
                        <li>Benefits you haven't chosen as part of your plan</li>
                        <li>Birth control, conception, sexual problems & gender re-assignment</li>
                        <li>Cosmetic surgery</li>
                        <li>Deafness</li>
                        <li>Dialysis</li>
                        <li>Learning disabilities, behavioural & developmental problems</li>
                        <li>Pregnancy & childbirth</li>
                        <li>Sleep problems & disorders</li>
                    </ul>
                </Exclusion>
            </ul>
        </section>
)
}
