import React from 'react'
import Faq from '../../../shared/FaqPanel/Faq'

export default function Content() {

    return (
        <Faq question={'Content on our website'}>
            <p>
                This website contains material that is owned or licenced by us.
                This material includes but is not limited to; design, layout, content, graphics and images.
                These materials are protected by copyright laws and treaties around the world. All such rights are reserved.
            </p>
            <p>
                Our website is for information only, it is not intended to provide advice on which you should rely.
                You must obtain professional or specialist advice before acting on any content on our website.
                We make reasonable efforts to update information on our website, however we make no guarantees
                that the information on our website is accurate, complete or up to date.
            </p>
            <p>
                We reserve the right to change any of the content on our website from time to time, with appropriate reasoning.
            </p>
        </Faq>
    )
}
