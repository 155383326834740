import React from 'react'
import Faq from '../../../shared/FaqPanel/Faq'

export default function Fraud() {
    return (

        <Faq question={'Fraud prevention and detection'}>
            <p>
                In circumstances where we suspect fraudulent behaviour,
                we will carry out checks with fraud prevention agencies and databases and conduct searches with publicly
                available sources of information.
            </p>
            <p>
                If we suspect fraudulent behaviour, we reserve the right not to offer you insurance, not to accept your
                claim and to void your Peachy plan.
            </p>
            <p>
                We will appoint fraud investigation and surveillance suppliers to investigate potentially fraudulent
                claims and use surveillance to assist investigations.
                We keep a record of individuals and associated investigations to prevent and detect future fraud or
                money laundering.
            </p>
            <p>
                When we check information against fraud protection agencies and databases,
                we use a range of databases and agencies, which includes other insurers' databases.
                If fraud is identified, your details will be passed to fraud prevention agencies, fraud databases and
                other insurers.
                Law enforcement agencies may access and use this information.
            </p>
        </Faq>
    )
}

