import React from 'react'
import styles from './Orchard.module.css'
import {classList} from '@peachy/utility-kit-pure'
import PageHeading from '../../shared/PageHeading/PageHeading'

export type OrchardProps = {
    className?: string
}

export default function Orchard({className}: OrchardProps) {

    const rootClasses = classList(styles.Orchard, className)

    return (
        <PageHeading className={rootClasses}>
            <h2>Who's in the orchard</h2>
            <p>With backgrounds in surgery, healthcare, insurance, venture, and tech, we're in a perfect place to build the best insurance platform the UK has to offer.</p>
            <RollCall/>
            <h2>Find your perfect fit</h2>
            <p>Our team is looking for bright minds and brilliant people to help build out the future of the most innovative UK private health insurance platform.</p>
            <ul className={styles.jobs}>
                <li>Senior Software Engineer, London/Remote</li>
                <li>Marketing Assistant, London/Remote</li>
                <li>Account Manager, London/Remote</li>
                <li>Open application</li>
            </ul>
        </PageHeading>
    )
}



function RollCall() {
    return (
        <ul className={styles.RollCall}>
            <li>
                <h3>Amit Patel</h3>
                <span>Founder</span>
            </li>
            <li>
                <h3>Darren Bex</h3>
                <span>CTO</span>
            </li>
        </ul>

    )
}
