import {useEffect, useState} from 'react'
import {last} from '@peachy/utility-kit-pure'

export default function useDataBuffer<T>(data: T, size: number = 3): DataBuffer<T> {

    const [buffer, setBuffer] = useState([data])

    useEffect(() => {

        setBuffer((currentBuffer) => {

            const newBuffer = [...currentBuffer]
            if (last(newBuffer) === data) {
                return currentBuffer
            }
            if (newBuffer.length == size) {
                newBuffer.pop()
            }
            if (last(newBuffer) !== data) {
                newBuffer.push(data)
            }
            return newBuffer
        })

    }, [data])


    const advanceBuffer = () => {
        setBuffer((currentBuffer) => {
            if (currentBuffer.length > 1) {
                return currentBuffer.slice(1)
            }
            return currentBuffer
        })
    }

    return [buffer[0], advanceBuffer, buffer]
}

export type DataBuffer<T> = [T, () => void, T[]]


