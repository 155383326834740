import {API} from '@aws-amplify/api'

import {PolicyRequest, PolicyResponse,} from '@peachy/legacy-domain'
import {dump} from '@peachy/utility-kit-pure'


export type PolicyApi = {
    createPolicy(policyRequest: PolicyRequest): Promise<PolicyResponse>
}

export type GraphApi = typeof API
export type RestApi = typeof API


export function createPolicyApi(api: RestApi = API): PolicyApi {
    return {
        createPolicy(policyRequest: PolicyRequest) {
            return createPolicy(policyRequest, api)
        }
    }
}

async function createPolicy(policyRequest: PolicyRequest, api:RestApi): Promise<PolicyResponse> {

    const request = {
        body: policyRequest,
        headers: {}
    }

    dump(policyRequest)

    const response = await api.post(PolicyApiName, createPolicyApiPath, request)
    return response.data
}



const PolicyApiName = 'SubscriptionApi'
const createPolicyApiPath = '/individual-policy'
