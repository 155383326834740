import {AnEvent} from '../../../event-kit'
import {Props} from '@peachy/utility-kit-pure'

export class OwnerSanctionsCheckRequest extends AnEvent {
    constructor(from: Props<OwnerSanctionsCheckRequest>) {
        super()
        Object.assign(this, from)
    }
    name: string
    birthYear: string
    companyId: string
}
