import React from 'react'
import styles from './SimpleTerms.module.css'
import PicturePanel from '../../../shared/PicturePanel/PicturePanel'
import Photo from '../../../shared/Photo/Photo'


export default function SimpleTerms() {

    return (

        <PicturePanel className={styles.SimpleTerms}>
            <article>
                <h3>Simple terms</h3>
                <p>
                <span>
                    Our insurance policies are clear and comfortable. That's it. No jargon, ifs, ands, or buts.
                    Know exactly what's covered and what's not, how your plan works, and who you can contact for help.
                </span>
                </p>
                <h3>Easy claims</h3>
                <p>
                <span>
                    Check your cover, get care, snap, & claim—all in just a few quick taps.
                    If you need hospital treatment, let us know. We'll pay you as soon as you're treated so you can take care of the bill from your provider.
                </span>
                </p>
            </article>
            <Photo caption={'Simple as blowing bubbles'}/>
        </PicturePanel>
    )
}
