import {isEmpty} from '@peachy/utility-kit-pure'
import {defineValidator, MessageProducer, ValidateIf, ValidationDirectives,} from '../validation-core'

export default function Optional<T>(
    isOptional: boolean = true,
    message?: string | MessageProducer<T>,
    validateIf?: ValidateIf<T>
) {
    return defineValidator<T>({
        name: 'Optional',
        validateIf,
        message: message ?? (({property}) => `Property ${property.toString()} is required`),
        validate({value}) {
            if (isOptional) {
                if (isEmpty(value)) {
                    return ValidationDirectives.APPROVE_IMMEDIATELY
                } else {
                    return true
                }
            } else {
                return !isEmpty(value)
            }
        },
    })
}
