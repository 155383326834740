import React, {ReactNode, useState} from 'react'
import YearSelect from './YearSelect'
import MonthSelect from './MonthSelect'
import {
    DateArray,
    dateFrom,
    daysInMonth,
    isWithinBounds,
    monthName,
    monthYearAfter,
    MonthYearArray,
    monthYearBefore
} from '@peachy/utility-kit-pure'


export type CalendarHeaderProps = {
    displayMonthYear: MonthYearArray
    onSelect(d: MonthYearArray): void,
    header: ReactNode,
    upperBound?: Date,
    lowerBound?: Date,
    outOfBoundsClassName: string,
    monthYearSelectorClassName: string,
    monthYearSelectorListClassName: string,
    monthYearSelectorSelectedClassName: string,
    leftArrowClassName?: string,
    rightArrowClassName?: string,
    disableYearSelect?: boolean
}


export default function CalendarHeader(props: CalendarHeaderProps) {

    const {
        onSelect,
        header,
        displayMonthYear,
        lowerBound,
        upperBound,
        outOfBoundsClassName,
        monthYearSelectorClassName,
        monthYearSelectorListClassName,
        monthYearSelectorSelectedClassName,
        leftArrowClassName,
        rightArrowClassName,
        disableYearSelect
    } = props

    const [showYearSelect, setShowYearSelect] = useState(false)
    const [showMonthSelect, setShowMonthSelect] = useState(false)

    const [month, year] = displayMonthYear


    const canNudgeMonth = (newMonthYear) => {
        return (
            isWithinBounds(dateFrom([1, ...newMonthYear] as DateArray), lowerBound, upperBound)
            || isWithinBounds(dateFrom([daysInMonth(newMonthYear), ...newMonthYear] as DateArray), lowerBound, upperBound)
        )
    }

    const canNudgeYear = (newYear) => {
        return (
            isWithinBounds(dateFrom([1, 1, newYear]), lowerBound, upperBound)
            || isWithinBounds(dateFrom([31, 12, newYear]), lowerBound, upperBound)
        )
    }

    const nudgeYear = (newYear) => {
        let newMonth = month
        if (lowerBound && newYear === lowerBound.getFullYear()) {
            newMonth = Math.max(newMonth, lowerBound.getMonth() + 1)
        }
        if (upperBound && newYear === upperBound.getFullYear()) {
            newMonth = Math.min(newMonth, upperBound.getMonth() + 1)
        }
        onSelect([newMonth, newYear])
    }

    const monthYearBeforeDisplayed = monthYearBefore(displayMonthYear)
    const monthYearAfterDisplayed = monthYearAfter(displayMonthYear)

    const yearBefore = year - 1
    const yearAfter = year + 1


    const Left = () => <i className={leftArrowClassName}>&larr;</i>
    const Right = () => <i className={rightArrowClassName}>&rarr;</i>

    return (
        <header>
            {header &&
            <hgroup>{header}</hgroup>
            }

            {!disableYearSelect &&

            <nav>
                {canNudgeYear(yearBefore)
                    ? <span onClick={() => nudgeYear(yearBefore)}><Left/></span>
                    : <span className={outOfBoundsClassName}><Left/></span>
                }

                <span style={{display: 'inline-block'}}>
                        <span onClick={() => setShowYearSelect(true)}>{year}</span>
                        <YearSelect isShown={showYearSelect}
                                    currentYear={year} lowerBoundYear={lowerBound.getFullYear()}
                                    upperBoundYear={upperBound.getFullYear()}
                                    onDismiss={(e) => {
                                        setShowYearSelect(false)
                                    }}
                                    onSelect={(aYear) => canNudgeYear(aYear) && nudgeYear(aYear)}
                                    className={monthYearSelectorClassName}
                                    listClassName={monthYearSelectorListClassName}

                        />
                </span>

                {canNudgeYear(yearAfter)
                    ? <span onClick={() => nudgeYear(yearAfter)}><Right/></span>
                    : <span className={outOfBoundsClassName}><Right/></span>
                }
            </nav>

            }


            <nav>

                {canNudgeMonth(monthYearBeforeDisplayed)
                    ? <span onClick={() => onSelect(monthYearBeforeDisplayed)}><Left/></span>
                    : <span className={outOfBoundsClassName}><Left/></span>
                }
                <span style={{display: 'inline-block'}}>
                    <span onClick={() => setShowMonthSelect(true)}>{monthName(month)}</span>
                    <MonthSelect
                        isShown={showMonthSelect}
                        currentMonthYear={displayMonthYear}
                        canNudgeMonth={canNudgeMonth}
                        onDismiss={() => setShowMonthSelect(false)}
                        onSelect={(aMonthYear) => onSelect(aMonthYear)}
                        className={monthYearSelectorClassName}
                        selectedMonthClassName={monthYearSelectorSelectedClassName}
                        listClassName={monthYearSelectorListClassName}
                    />
                </span>

                {canNudgeMonth(monthYearAfterDisplayed)
                    ? <span onClick={() => onSelect(monthYearAfterDisplayed)}><Right/></span>
                    : <span className={outOfBoundsClassName}><Right/></span>
                }


            </nav>


        </header>
    )
}
