import React, {ReactNode, useEffect, useRef} from 'react'
import styles from './Modal.module.css'
import {classList} from '@peachy/utility-kit-pure'
import {appRootId} from '../../App/App'
import ReactDOM from 'react-dom'
import FocusTrap from 'focus-trap-react'
import {RouteStageProps} from '../../Quote/StageRouter/StageRouter'

export type ModalProps = RouteStageProps<any> & {
    onDismiss(): void
    trapFocus?: boolean
    children: ReactNode
    className?: string
}

export default function Modal({onDismiss, trapFocus = true, children, className}: ModalProps) {


    const modalClasses = classList(styles.Modal, className)

    const appRoot = document.getElementById(appRootId)

    const modalRef = useRef<HTMLElement>()

    const onBackgroundClick = (e) => {
        if (e.target === modalRef.current) {
            onDismiss()
        }
    }

    let modal = (
        <aside ref={modalRef} onClick={onBackgroundClick} className={modalClasses}>
            {children}
        </aside>
    )

    if (trapFocus) {
        modal = <FocusTrap>{modal}</FocusTrap>
    }

    useEffect(() => {
        const keyListener = (e) => {
            if (e.key === 'Escape') {
                onDismiss()
            }
        }

        const scrollListener = (e) => {
        }

        document.addEventListener('keydown', keyListener)
        document.addEventListener('scroll', scrollListener)
        return () => {
            document.removeEventListener('keydown', keyListener)
            document.removeEventListener('scroll', scrollListener)
        }
    }, [])


    return ReactDOM.createPortal(modal, appRoot)
}
