import React, {ReactNode, ReactNodeArray, useEffect, useRef, useState} from 'react'

import useDataBuffer from '../../hooks/useDataBuffer'
import {isArray, isFunction, isNumber} from '@peachy/utility-kit-pure'


type SwitchOn = string | number

type SwitchChildren = {
    [k: string]: ReactNode
} | ReactNodeArray


export type Style = { [key: string]: unknown }

export type SwitcherProps<T extends SwitchOn> = {
    on: T,
    duration?: number,
    onStage: Keyframe,
    offStage?: Keyframe,
    offStageLeft?: Keyframe,
    offStageRight?: Keyframe,
    flip?: Flipper<T>
    children: SwitchChildren,
}

export default function Switcher<T extends SwitchOn>(
    {
        on,
        duration = 200,
        flip,
        onStage,
        offStage,
        offStageLeft = offStage,
        offStageRight = offStage,
        children
    }: SwitcherProps<T>) {

    const [_, advanceBuffer, buffer] = useDataBuffer(on)

    const [departingOn, arrivingOn] = buffer

    const departingRef = useRef<HTMLDivElement>()
    const arrivingRef = useRef<HTMLDivElement>()

    const [isRunning, setIsRunning] = useState(false)

    const isFlipped = shouldFlip(flip, arrivingOn, departingOn)

    const arrivingStyle = isFlipped ? offStageRight : offStageLeft
    const departingStyle = isFlipped ? offStageLeft : offStageRight

    useEffect(() => {
        if (buffer.length > 1) {
            if (!isRunning) {

                const arrive = arrivingRef.current.animate([arrivingStyle, onStage], {
                    duration
                })

                const depart = departingRef.current.animate([onStage, departingStyle], {
                    duration
                })
                setIsRunning(true)

                depart.finished.then(() => {
                    setIsRunning(false)
                    advanceBuffer()
                })

                arrive.play()
                depart.play()

            }

        } else {
            setIsRunning(false)
        }

    }, [buffer])

    const departingChild = pickChild(departingOn, children)
    const arrivingChild = pickChild(arrivingOn, children)

    return <>
        {departingChild && <div ref={departingRef}>{departingChild}</div>}
        {arrivingChild && <div ref={arrivingRef}>{arrivingChild}</div>}
    </>
}


function shouldFlip<T extends SwitchOn>(flip: Flipper<T> = false, nowOn: T, pendingOn: T) {
    if (isFunction(flip)) {
        return flip(nowOn, pendingOn)
    } else if(isNumber(nowOn) && isNumber(pendingOn)) {
        return nowOn > pendingOn
    } else {
        return flip
    }
}

const flipTransition = (now: number, pending: number) => {
    return now > pending
}


type Flipper<T = void> = boolean | ((nowOn: T, pendingOn: T) => boolean)


function pickChild<T extends SwitchOn>(on: T, children: SwitchChildren) {
    return isArray(children) ? children[on as number] : children[on as string]
}
