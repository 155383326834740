import React from 'react'
import ListSelector from '../../../elements/ListSelector/ListSelector'
import Expander from '../../../elements/Expander/Expander'

export default function YearSelect(props) {
    const {
        isShown,
        currentYear,
        lowerBoundYear,
        upperBoundYear,
        onSelect,
        onDismiss,
        className,
        listClassName,

    } = props

    const years = []

    for (let newYear = lowerBoundYear; newYear <= upperBoundYear; newYear++) {
        years.push(newYear.toString())
    }


    return (
        <Expander className={className} expanded={isShown} tag={'aside'}>
            {
                years.length &&
                <ListSelector list={years.map(y => y.toString())} className={listClassName} onDismiss={onDismiss} selection={currentYear.toString()} onSelect={(year) => {
                    onSelect(+year)
                    onDismiss()
                    }
                }/>
            }
        </Expander>
    )
}

export type YearSelectProps = {
    isShown: boolean
    currentYear: number,
    lowerBoundYear: number,
    upperBoundYear: number,
    onSelect: (year: number) => void,
    onDismiss: () => void
    className: string
}
