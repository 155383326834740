import React from 'react'
import styles from './BenefitCard.module.css'
import BenefitCard from './BenefitCard'
import {MentalHealthBenefit} from '@peachy/legacy-domain'


const benefitTemplate = MentalHealthBenefit()

export default function MentalHealthCard() {

    return (
        <BenefitCard header={'Mental health'} canDeselect={false} className={styles.mental} benefitTemplate={benefitTemplate}>
            <p>We’ve got your back when you need someone to talk to and if you need it, we bring more intensive support &amp; treatment</p>
            <p>Cover for mental health treatment as an in-patient or day-patient (£20,000 benefit limit per plan year) and out-patient (£1,500 benefit limit per plan year)</p>
        </BenefitCard>
    )
}
