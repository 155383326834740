import React from 'react'
import styles from './OurPlans.module.css'
import library from '../../../global-style/library.module.css'
import {Link} from 'react-router-dom'
import {classList} from '@peachy/utility-kit-pure'

export default function OurPlans() {

    const toggle = (e) => {
        e.target.classList.toggle(styles.benefitOff)
    }


    return (
        <section className={styles.OurPlans}>
            <figure onTouchStart={toggle} onMouseOver={toggle} className={classList(styles.consultations, styles.benefit)}>
                <figcaption>Consultations & Diagnostics</figcaption>
            </figure>
            <figure onTouchStart={toggle} onMouseOver={toggle} className={classList(styles.hospital, styles.benefit)}>
                <figcaption>Hospital Care</figcaption>
            </figure>
            <figure onTouchStart={toggle} onMouseOver={toggle} className={classList(styles.remote, styles.benefit)}>
                <figcaption>Remote Care</figcaption>
            </figure>
            <article className={styles.ourPlansArticle}>
                <h2>Our plans get personal</h2>
                <p>
                    No one's healthcare needs are the same. Peachy allows you to craft insurance plans personalised
                    to
                    your needs & wallet.
                </p>
                <Link className={library.button} to={'/how-it-works'}>Learn more</Link>
            </article>
            <figure onTouchStart={toggle} onMouseOver={toggle} className={classList(styles.mental, styles.benefit)}>
                <figcaption>Mental Health</figcaption>
            </figure>
            <figure onTouchStart={toggle} onMouseOver={toggle} className={classList(styles.therapies, styles.benefit)}>
                <figcaption>Therapies</figcaption>
            </figure>
            <figure onTouchStart={toggle} onMouseOver={toggle} className={classList(styles.optical, styles.benefit)}>
                <figcaption>Dental & Optical</figcaption>
            </figure>

        </section>
    )
}
