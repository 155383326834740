import {AnEvent} from '../../event-kit'
import {KeyMapped, Properties} from '@peachy/utility-kit-pure'
import {AccountType} from '../../../domain/models/Account'
import {MrPolicy} from '../../../domain/models/memorepo/MrPolicy'
import {LifeEventData} from '../interfaces'
import {MrPlan} from '../../../domain/models/memorepo/MrPlan'

export class LifeRepoUpsertRequest extends AnEvent implements LifeEventData {

    accountId: string
    accountType: AccountType
    subscriptionId: string
    policyId: string
    lifeId: string
    plans: KeyMapped<MrPlan>
    cognitoId: string

    policy: MrPolicy
    subscriptionStartDate: number

    constructor(from: Properties<LifeRepoUpsertRequest>) {
        super()
        Object.assign(this, from)
    }
}
