

export class Premium {
    baseRiskCost: number = 0
    adjustedForCorrelation: number = 0
    adjustedForExcessDiscounts: number = 0
    adjustedForContingency: number = 0
    adjustedForFinalDiscounts: number = 0
    adjustedForLossRatio: number = 0
    adjustedForIpt: number = 0
    adjustedForPriceResolution: number = 0
    total: number = 0
}
