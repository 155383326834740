import {createDocGenService, DocGenService} from './individual-services/DocGenService'
import {useServicePatch} from './WebShopServiceController'

export type WebShopServices = {
    docGenService: DocGenService
}

export async function createWebShopServices(): Promise<WebShopServices> {

    console.log('Creating Services')

    return  {
        docGenService: await createDocGenService(useServicePatch())
    }
}



