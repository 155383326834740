import {IdCheckEvent} from './IdCheckEvent'
import {Props} from '@peachy/utility-kit-pure'

export class IdCheckFailed extends IdCheckEvent {

    constructor(from: Props<IdCheckFailed>) {
        super(from)
        this.status = 'FAILED'
        this.type = this.constructor.name
    }
}


